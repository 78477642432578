import React, { FC, useEffect, useMemo } from "react";
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from "react-router-dom";
import { NotFoundPage } from "../ui_components/Pages/NotFoundPage";
import { LoginPage } from "./auth/LoginPage";
import { ActivarSesionPage } from "./auth/ActivarSesionPage";
import { EmptyLayout } from "../ui_components/Layouts/EmptyLayout";
import { SesionContainer } from "./Sesion";
import { AppLayoutWrapper } from "./AppLayoutWrapper";
import { HomePage } from "./home/HomePage";
import { obtenerDatosSesion } from "./api/SesionApi";
import { getTituloRuta, rutas } from "./rutas";
import { PedidosListPage } from "./pedidos/PedidosListPage";
import { NuevoPedidoPage } from "./nuevos-pedidos/NuevoPedidoPage";
import { ConfirmarPedidoPage } from "./nuevos-pedidos/ConfirmarPedidoPage";
import { RestablecerPasswordPage } from "./auth/RestablecerPasswordPage";
// import { PruebaPage } from "./PruebaPage";
import { CuentaCorrientePage } from "./cuenta-corriente/CuentaCorrientePage";
import { RemitosListPage } from "./remitos/RemitosListPage";
import { FacturasListPage } from "./facturas/FacturasListPage";
import { CertificadosListPage } from "./certificados/CertificadosListPage";
import { ContactoPage } from "./contacto/ContactoPage";
import { DescargasPage } from "./descargas/DescargasPage";
import { ActivacionUsuarioPage } from "./auth/ActivacionUsuarioPage";
import { UsuariosListPage } from "./usuarios/UsuariosListPage";
import { BienvenidoPage } from "./auth/BienvenidoPage";
import { PruebaPage } from "./PruebaPage";
import { CambiarPasswordPage } from "./auth/CambiarPasswordPage";

interface AppRouteProps extends RouteProps {
    layout: FC<RouteComponentProps>;
    component: FC<RouteComponentProps>;
}

const AppRoute: FC<AppRouteProps> = ({ component: Component, layout: Layout, ...rest }) => {
    const tituloRuta = getTituloRuta(rest.path as string);
    useEffect(() => {
        if (tituloRuta && tituloRuta.titulo) {
            document.title = `${tituloRuta.titulo}`;
        } else {
            document.title = "Tubos Arg. - eCommerce";
        }
    }, [tituloRuta]);

    return (
        <Route
            {...rest}
            render={props => (
                <Layout {...props}>
                    <Component {...{ ...props }} />
                </Layout>
            )}
        />
    );
};

export function Router() {
    const { sesion, setFetchedData, clearSesion } = SesionContainer.useContainer();
    const authToken = useMemo(() => sesion.authToken, [sesion]);
    const isLoggedIn = useMemo(() => !!authToken, [authToken]);
    const sesionFetched = useMemo(() => sesion.fetched, [sesion]);

    useEffect(() => {
        const makeRequest = async () => {
            const result = await obtenerDatosSesion();
            if (result.ok && result.output) {
                setFetchedData(result.output);
                return;
            }

            // Si hay algún error al obtener los datos de la sesión (401, server error, etc)
            // borrar la sesión del cliente y deslogearse.
            clearSesion();
        };

        if (!sesionFetched && authToken) {
            makeRequest();
        }
    }, [sesionFetched, authToken, setFetchedData, clearSesion]);

    if (!isLoggedIn) {
        return (
            <Switch>
                <AppRoute exact={true} path={rutas.login} layout={EmptyLayout} component={LoginPage} />>
                <AppRoute exact={true} path={rutas.ingresar} layout={EmptyLayout} component={ActivarSesionPage} />
                <AppRoute
                    exact={true}
                    path={rutas.activacionUsuario}
                    layout={EmptyLayout}
                    component={ActivacionUsuarioPage}
                />
                <AppRoute
                    exact={true}
                    path={rutas.restablecerPassword}
                    layout={EmptyLayout}
                    component={RestablecerPasswordPage}
                />
                <AppRoute
                    exact={true}
                    path={rutas.cambiarPassword}
                    layout={EmptyLayout}
                    component={CambiarPasswordPage}
                />
                <Redirect exact={true} to={rutas.login} />
                <AppRoute layout={EmptyLayout} component={NotFoundPage} />
            </Switch>
        );
    }

    if (!sesionFetched) {
        return <></>; // Obteniendo datos de la sesión...
    }

    return (
        <Switch>
            <AppRoute exact={true} path={rutas.home} layout={AppLayoutWrapper} component={HomePage} />
            <AppRoute exact={true} path={rutas.pedidos} layout={AppLayoutWrapper} component={PedidosListPage} />
            <AppRoute exact={true} path={rutas.nuevoPedido} layout={AppLayoutWrapper} component={NuevoPedidoPage} />
            <AppRoute
                exact={true}
                path={rutas.confirmarPedido}
                layout={AppLayoutWrapper}
                component={ConfirmarPedidoPage}
            />
            <AppRoute
                exact={true}
                path={rutas.cuentaCorriente}
                layout={AppLayoutWrapper}
                component={CuentaCorrientePage}
            />
            <AppRoute exact={true} path={rutas.remitos} layout={AppLayoutWrapper} component={RemitosListPage} />
            <AppRoute exact={true} path={rutas.facturas} layout={AppLayoutWrapper} component={FacturasListPage} />
            <AppRoute
                exact={true}
                path={rutas.certificados}
                layout={AppLayoutWrapper}
                component={CertificadosListPage}
            />
            <AppRoute exact={true} path={rutas.contactenos} layout={AppLayoutWrapper} component={ContactoPage} />
            <AppRoute exact={true} path={rutas.descargas} layout={AppLayoutWrapper} component={DescargasPage} />
            {sesion.usuario && sesion.usuario.esAdmin && (
                <AppRoute exact={true} path={rutas.usuarios} layout={AppLayoutWrapper} component={UsuariosListPage} />
            )}
            <AppRoute exact={true} path={rutas.bienvenido} layout={EmptyLayout} component={BienvenidoPage} />

            <AppRoute exact={true} path={rutas.pruebarete} layout={AppLayoutWrapper} component={PruebaPage} />
            <Redirect exact={true} from={rutas.ingresar} to={rutas.bienvenido} />
            <Redirect exact={true} from={rutas.login} to={rutas.bienvenido} />
            <Route component={NotFoundPage} />
        </Switch>
    );
}
