import { IDomicilio } from "../types";
import { makeHttpRequest, POST, ResultBase } from "./api";

interface RequestData {
    idFactura: number;
}

export enum ErrorCodes {}

interface ICliente {
    nombre: string;
    cuit: string;
    domicilio: IDomicilio;
}

export interface IFactura {
    id: number;
    nroFactura: string;
    fecha: string;
    tipoDoc: string;
    cliente: ICliente;
    nroPedido: number;
    tipoPedido: string;
    anticipoOriginal: string | null;
    nroRemito: string | null;
    condVenta: string | null;
    nroOCCliente: string | null;
    vendedor: string | null;
    moneda: string;
    detalles: IFacturaDetalle[];
    totales: ITotales;
}

export interface IFacturaDetalle {
    codArticulo: string;
    descripcion: string;
    umPrecio: string;
    cantidad: number;
    precioLista: number;
    bonif1: number;
    bonif2: number;
    bonif3: number;
    bonif4: number;
    precioUnitario: number;
    precioFinal: number;
}

export interface ITotales {
    subTotal: number;
    percepcionesIIBB: Array<IPercepcionIIBB>;
    percIVA: number;
    iva: number;
    total: number;
}

interface IPercepcionIIBB {
    jurisdiccion: string;
    porcentaje: number;
    importe: number;
}

export interface IImpuesto {
    descripcion: string;
    porcentaje: number;
    importe: number;
}

interface ResponseOutput {
    factura: IFactura;
}
export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const obtenerFactura = async (reqData): Promise<Result> =>
    makeHttpRequest<RequestData, ResponseOutput, ErrorCodes>(POST, "factura-doc", reqData);
