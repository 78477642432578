import React, { FC, useEffect, useState } from "react";
import { Documento, ICategoriaHeaderDatos, IDocumento, ITotal } from "../Documento";
import { Box, Flex, Text } from "rebass/styled-components";
import Alert from "@kiwicom/orbit-components/lib/Alert";
import Loading from "@kiwicom/orbit-components/lib/Loading";
import Table, { TableHead, TableBody, TableRow, TableCell } from "@kiwicom/orbit-components/lib/Table";
import getTokens from "@kiwicom/orbit-components/lib/getTokens";
import { ThemeProvider } from "styled-components";
import { IPedido, obtenerPedido } from "../api/PedidoDocApi";
import { formatCant, formatImporte } from "../../lib/format";

const pedidoToDocProps = (pedido: IPedido): IDocumento => {
    const {
        nroPedido,
        fecha,
        cliente,
        condVenta,
        nroOCCliente,
        anticipoOriginal,
        vendedor,
        lugarEntrega,
        moneda
    } = pedido;

    const obtenerDatosPedido = (): Array<ICategoriaHeaderDatos> => {
        const datosPedido: Array<ICategoriaHeaderDatos> = [
            {
                nombre: "Nro. Pedido",
                valor: nroPedido
            }
        ];

        if (condVenta) {
            datosPedido.push({
                nombre: "Cond. Venta",
                valor: condVenta
            });
        }

        if (nroOCCliente) {
            datosPedido.push({
                nombre: "Nro. OC. Cliente",
                valor: nroOCCliente
            });
        }

        if (anticipoOriginal) {
            datosPedido.push({
                nombre: "Anticipo Original",
                valor: anticipoOriginal
            });
        }

        if (vendedor) {
            datosPedido.push({
                nombre: "Vendedor",
                valor: vendedor
            });
        }

        return datosPedido;
    };

    const obtenerTotales = (): Array<ITotal> => {
        const { subTotal, percepcionesIIBB, iva, percIVA, total } = pedido.totales;

        let totales = [
            {
                descripcion: "Sub Total",
                importe: subTotal
            },
            ...percepcionesIIBB.map(
                ({ jurisdiccion, porcentaje, importe }): ITotal => {
                    return {
                        descripcion: `IIBB ${jurisdiccion} ${porcentaje}%`,
                        importe
                    };
                }
            )
        ];

        if (iva) {
            totales.push({ descripcion: "IVA", importe: iva });
        }

        if (percIVA) {
            totales.push({ descripcion: "Perc. IVA", importe: percIVA });
        }

        totales.push({ descripcion: "Total", importe: total });

        return totales;
    };

    const categoriasHeader = [
        {
            titulo: "Pedido",
            datos: obtenerDatosPedido()
        }
    ];

    if (lugarEntrega) {
        const {
            descripcion,
            domicilio: { direccion, localidad, provincia }
        } = lugarEntrega;
        categoriasHeader.push({
            titulo: "Entrega",
            datos: [
                {
                    nombre: "Descripción",
                    valor: descripcion
                },
                {
                    nombre: "Dirección",
                    valor: direccion
                },
                {
                    nombre: "Localidad",
                    valor: localidad
                },
                {
                    nombre: "Provincia",
                    valor: provincia
                }
            ]
        });
    }
    return {
        tipoDoc: "Pedido",
        nroDoc: nroPedido,
        fechaDoc: fecha,
        cliente,
        categoriasHeader,
        moneda,
        totales: obtenerTotales()
    };
};

export const PedidoDoc: FC<{ idPedido: number }> = ({ idPedido }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [msgError, setMsgError] = useState<string | null>(null);
    const [pedido, setPedido] = useState<IPedido | null>(null);

    useEffect(() => {
        const fetchPedido = async () => {
            const resp = await obtenerPedido({ idPedido });
            if (resp.ok && resp.output) {
                const pedido = resp.output.pedido;
                setPedido(pedido);
                setLoading(false);
                return;
            }

            setMsgError("No se puedieron cargar los datos de la pedido");
            setLoading(false);
        };

        setLoading(true);
        fetchPedido();
    }, [idPedido]);

    if (loading) {
        return <Loading loading={true} type="boxLoader" text="Cargando..." />;
    }

    if (msgError) {
        return (
            <Box mb={4} mx={"auto"} my={3} width={"40em"}>
                <Alert type="critical" title={null} icon={true}>
                    {msgError}
                </Alert>
            </Box>
        );
    }

    if (pedido) {
        return <Documento doc={pedidoToDocProps(pedido)} Body={<DocBody pedido={pedido} />} />;
    }

    return <></>;
};

const orbitTheme = getTokens();
orbitTheme.colorTextTable = "#252A31";
const DocBody: FC<{ pedido: IPedido }> = ({ pedido: { detalles, moneda } }) => {
    let tieneBonif1 = true;
    let tieneBonif2 = true;
    let tieneBonif3 = true;
    let tieneBonif4 = true;
    detalles.forEach(({ bonif1, bonif2, bonif3, bonif4 }) => {
        tieneBonif1 = tieneBonif1 && bonif1 > 0;
        tieneBonif2 = tieneBonif2 && bonif2 > 0;
        tieneBonif3 = tieneBonif3 && bonif3 > 0;
        tieneBonif4 = tieneBonif4 && bonif4 > 0;
    });

    return (
        <ThemeProvider theme={{ orbit: orbitTheme }}>
            <Table compact={true}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Artículo</TableCell>
                        <TableCell align="right">Cantidad</TableCell>
                        <TableCell align="right">Precio Lista</TableCell>
                        {tieneBonif1 && <TableCell align="right">Bonif. 1</TableCell>}
                        {tieneBonif2 && <TableCell align="right">Bonif. 2</TableCell>}
                        {tieneBonif3 && <TableCell align="right">Bonif. 3</TableCell>}
                        {tieneBonif4 && <TableCell align="right">Bonif. 4</TableCell>}
                        <TableCell align="right">Precio Uni.</TableCell>
                        <TableCell align="right">Precio Final</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {detalles.map((detalle, index) => {
                        const {
                            codArticulo,
                            descripcion,
                            umPrecio,
                            cantidad,
                            precioLista,
                            bonif1,
                            bonif2,
                            bonif3,
                            bonif4,
                            precioUnitario,
                            precioFinal
                        } = detalle;
                        return (
                            <TableRow key={index}>
                                <TableCell align="left">
                                    <Flex flexDirection={"column"}>
                                        <Text>{codArticulo}</Text>
                                        <Text color={"datagridLightText"} fontSize={"0.8em"}>
                                            {descripcion}
                                        </Text>
                                    </Flex>
                                </TableCell>
                                <TableCell align="right">
                                    {formatCant(cantidad)} {umPrecio}
                                </TableCell>
                                <TableCell align="right">
                                    {moneda} {formatImporte(precioLista)}
                                </TableCell>
                                {tieneBonif1 && <TableCell align="right">{formatImporte(bonif1)}%</TableCell>}
                                {tieneBonif2 && <TableCell align="right">{formatImporte(bonif2)}%</TableCell>}
                                {tieneBonif3 && <TableCell align="right">{formatImporte(bonif3)}%</TableCell>}
                                {tieneBonif4 && <TableCell align="right">{formatImporte(bonif4)}%</TableCell>}
                                <TableCell align="right">
                                    {moneda} {formatImporte(precioUnitario)}
                                </TableCell>
                                <TableCell align="right">
                                    {moneda} {formatImporte(precioFinal)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </ThemeProvider>
    );
};
