import React, { FC, useEffect, useMemo, useState } from "react";
import { Documento, ICategoriaHeader, IDocumento } from "../Documento";
import { Box, Flex, Text } from "rebass/styled-components";
import Alert from "@kiwicom/orbit-components/lib/Alert";
import Loading from "@kiwicom/orbit-components/lib/Loading";
import Table, { TableHead, TableBody, TableRow, TableCell } from "@kiwicom/orbit-components/lib/Table";
import getTokens from "@kiwicom/orbit-components/lib/getTokens";
import { ThemeProvider } from "styled-components";
import { IRecibo, obtenerRecibo } from "../api/ReciboDocApi";
import { formatImporte } from "../../lib/format";
import { descargasURLBase } from "../api/api";
import { getAuthToken } from "../LocalStorage";

const reciboToDocProps = (recibo: IRecibo): IDocumento => {
    const { nroRecibo, fecha, cliente, nroReciboProvisorio, fechaReciboProvisorio, moneda, totalAPlicar } = recibo;

    let categoriasHeader: ICategoriaHeader[] = [];
    if (nroReciboProvisorio) {
        categoriasHeader.push({
            titulo: "Datos Recibo Provisorio/Banco",
            datos: [
                {
                    nombre: "Número",
                    valor: nroReciboProvisorio
                },
                {
                    nombre: "Fecha",
                    valor: fechaReciboProvisorio
                }
            ]
        });
    }

    return {
        tipoDoc: "Recibo",
        nroDoc: nroRecibo,
        fechaDoc: fecha,
        cliente,
        categoriasHeader,
        moneda,
        totales: [
            {
                descripcion: "Total",
                importe: totalAPlicar
            }
        ]
    };
};

export const ReciboDoc: FC<{ idRecibo: number }> = ({ idRecibo }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [msgError, setMsgError] = useState<string | null>(null);
    const [recibo, setRecibo] = useState<IRecibo | null>(null);

    useEffect(() => {
        const fetchRecibo = async () => {
            const resp = await obtenerRecibo({ idRecibo });
            if (resp.ok && resp.output) {
                const recibo = resp.output.recibo;
                setRecibo(recibo);
                setLoading(false);
                return;
            }

            setMsgError("No se puedieron cargar los datos del recibo");
            setLoading(false);
        };

        setLoading(true);
        fetchRecibo();
    }, [idRecibo]);

    const bajarPDFUrl = useMemo(() => {
        const authToken = getAuthToken();
        return `${descargasURLBase}/recibo-doc-bajar-PDF?token=${authToken}&idRecibo=${idRecibo}`;
    }, [idRecibo]);

    if (loading) {
        return <Loading loading={true} type="boxLoader" text="Cargando..." />;
    }

    if (msgError) {
        return (
            <Box mb={4} mx={"auto"} my={3} width={"40em"}>
                <Alert type="critical" title={null} icon={true}>
                    {msgError}
                </Alert>
            </Box>
        );
    }

    if (recibo) {
        return (
            <Documento bajarPDFUrl={bajarPDFUrl} doc={reciboToDocProps(recibo)} Body={<DocBody recibo={recibo} />} />
        );
    }

    return <></>;
};

const orbitTheme = getTokens();
orbitTheme.colorTextTable = "#252A31";
const DocBody: FC<{ recibo: IRecibo }> = ({ recibo }) => {
    return (
        <ThemeProvider theme={{ orbit: orbitTheme }}>
            <Flex flexDirection={"column"}>
                <Flex flexDirection={"column"}>
                    <Text fontSize={"1.4em"} fontWeight={300} mb={2}>
                        Formas de Cobro
                    </Text>
                    <FormasCobroTable recibo={recibo} />
                </Flex>
                <Flex width={1} mt={2}>
                    <Flex flexDirection={"column"} width={1 / 2}>
                        <Text fontSize={"1.4em"} fontWeight={300} mb={2}>
                            Retenciones
                        </Text>
                        <RetencionesTable recibo={recibo} />
                    </Flex>
                    <Flex flexDirection={"column"} ml={2} width={1 / 2}>
                        <Text fontSize={"1.4em"} fontWeight={300} mb={2}>
                            Comprobantes
                        </Text>
                        <ComprobantesTable recibo={recibo} />
                    </Flex>
                </Flex>
            </Flex>
        </ThemeProvider>
    );
};

const FormasCobroTable: FC<{ recibo: IRecibo }> = ({ recibo: { formasCobro, moneda, totalFormasCobro } }) => {
    return (
        <Table compact={true}>
            <TableHead>
                <TableRow>
                    <TableCell align="left">Tipo</TableCell>
                    <TableCell align="left">Descripción</TableCell>
                    <TableCell align="right" />
                    <TableCell align="right">Importe</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {formasCobro.map((formaCobro, index) => {
                    const {
                        tipoFormaCobro,
                        descripcion,
                        moneda: monedaFormaCobro,
                        importeFormaCobro,
                        importe
                    } = formaCobro;

                    return (
                        <TableRow key={index}>
                            <TableCell align="left">{tipoFormaCobro}</TableCell>
                            <TableCell align="left">{descripcion}</TableCell>
                            <TableCell align="right">
                                {monedaFormaCobro !== moneda && (
                                    <Text>
                                        {monedaFormaCobro} {formatImporte(importeFormaCobro)}
                                    </Text>
                                )}
                            </TableCell>
                            <TableCell align="right">
                                {moneda} {formatImporte(importe)}
                            </TableCell>
                        </TableRow>
                    );
                })}

                <TableRow key={"total"}>
                    <TableCell align="left" />
                    <TableCell align="left" />
                    <TableCell align="right" />
                    <TableCell align="right">
                        <Text fontWeight={600}>
                            {moneda} {formatImporte(totalFormasCobro)}
                        </Text>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

const RetencionesTable: FC<{ recibo: IRecibo }> = ({ recibo: { retenciones, moneda, totalRetenciones } }) => {
    return (
        <Table compact={true}>
            <TableHead>
                <TableRow>
                    <TableCell align="left">Tipo</TableCell>
                    <TableCell align="left">Nro. Certificado</TableCell>
                    <TableCell align="right">Importe</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {retenciones.map((retencion, index) => {
                    const { tipoRetencion, nroCertificado, importe } = retencion;

                    return (
                        <TableRow key={index}>
                            <TableCell align="left">{tipoRetencion}</TableCell>
                            <TableCell align="left">{nroCertificado}</TableCell>
                            <TableCell align="right">
                                {moneda} {formatImporte(importe)}
                            </TableCell>
                        </TableRow>
                    );
                })}

                <TableRow key={"total"}>
                    <TableCell align="left" />
                    <TableCell align="left" />
                    <TableCell align="right">
                        <Text fontWeight={600}>
                            {moneda} {formatImporte(totalRetenciones)}
                        </Text>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

const ComprobantesTable: FC<{ recibo: IRecibo }> = ({
    recibo: { comprobantes, moneda, totalComprobantes, totalPendienteAplicacion }
}) => {
    return (
        <Table compact={true}>
            <TableHead>
                <TableRow>
                    <TableCell align="left">Tipo</TableCell>
                    <TableCell align="left">Número</TableCell>
                    <TableCell align="left">Fecha</TableCell>
                    <TableCell align="right">Importe</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {comprobantes.map((comprobante, index) => {
                    const { tipoComprobante, nroComprobante, fecha, importe } = comprobante;

                    return (
                        <TableRow key={index}>
                            <TableCell align="left">{tipoComprobante}</TableCell>
                            <TableCell align="left">{nroComprobante}</TableCell>
                            <TableCell align="left">{fecha}</TableCell>
                            <TableCell align="right">
                                {moneda} {formatImporte(importe)}
                            </TableCell>
                        </TableRow>
                    );
                })}

                <TableRow key={"totalAplicado"}>
                    <TableCell align="left" />
                    <TableCell align="left" />
                    <TableCell align="left">Total Aplicado:</TableCell>
                    <TableCell align="right">
                        <Text fontWeight={600}>
                            {moneda} {formatImporte(totalComprobantes)}
                        </Text>
                    </TableCell>
                </TableRow>
                <TableRow key={"totalACuenta"}>
                    <TableCell align="left" />
                    <TableCell align="left" />
                    <TableCell align="left">Total a Cuenta:</TableCell>
                    <TableCell align="right">
                        <Text fontWeight={600}>
                            {moneda} {formatImporte(totalPendienteAplicacion)}
                        </Text>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};
