import React, { FC } from "react";
import { IPedido } from "../api/CarritoApi";
import Table, { TableHead, TableBody, TableRow, TableCell } from "@kiwicom/orbit-components/lib/Table";
import Modal, { ModalHeader, ModalSection, ModalFooter } from "@kiwicom/orbit-components/lib/Modal";
import { Button, Portal } from "@kiwicom/orbit-components";
import CloseIcon from "@kiwicom/orbit-components/lib/icons/Close";
import { formatImporte } from "../../lib/format";

export const CarritoImpuestosModal: FC<{ cerrarCallback: () => void; pedido: IPedido }> = ({
    cerrarCallback,
    pedido
}) => {
    return (
        <Portal>
            <Modal fixedFooter={false} isMobileFullPage={false} onClose={cerrarCallback} size={"small"}>
                <ModalHeader title={null} suppressed={false} />
                <ModalSection suppressed={false}>
                    <CarritoImpuestos pedido={pedido} />
                </ModalSection>
                <ModalFooter fixed={true} flex={["0 0 auto", "1 1 100%"]} suppressed={true}>
                    <Button iconLeft={<CloseIcon />} onClick={cerrarCallback} type="secondary">
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </Portal>
    );
};

const CarritoImpuestos: FC<{ pedido: IPedido }> = ({ pedido }) => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align={"left"}>Impuesto</TableCell>
                    <TableCell align={"center"}>Porcentaje</TableCell>
                    <TableCell align={"right"}>Importe</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {pedido.impuestos.map(({ descripcion, porcentaje, importe }) => {
                    return (
                        <TableRow key={descripcion}>
                            <TableCell align={"left"}>{descripcion}</TableCell>
                            <TableCell align={"center"}>{formatImporte(porcentaje)}%</TableCell>
                            <TableCell align={"right"}>
                                {pedido.moneda} {formatImporte(importe)}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};
