import React, { FC, ReactNode, useEffect, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { Alert, Button, Loading, Separator } from "@kiwicom/orbit-components";
import AccountCircle from "@kiwicom/orbit-components/lib/icons/AccountCircle";
import ContactEmail from "@kiwicom/orbit-components/lib/icons/ContactEmail";
import Email from "@kiwicom/orbit-components/lib/icons/Email";
import Phone from "@kiwicom/orbit-components/lib/icons/Phone";
import { ReactComponent as WhatsappIcon } from "../../assets/whatsapp.svg";
import { IAnalista, IVendedor, obtenerInformacionContacto } from "../api/ContactoApi";
import { ContactoEnviarEmailModal } from "./ContactoEnviarEmailModal";
import { TipoEmail } from "../api/ContactoEnviarEmailApi";

export const ContactoPage: FC = () => {
    const [loading, setLoading] = useState(true);
    const [msgError, setMsgError] = useState<string | null>(null);
    const [vendedor, setVendedor] = useState<IVendedor | null>(null);
    const [analista, setAnalista] = useState<IAnalista | null>(null);
    const [emailReclamos, setEmailReclamos] = useState<string>("");
    const [enviarEmailProps, setEnviarEmailProps] = useState<{ tipoEmail: TipoEmail; titulo: string } | null>(null);

    useEffect(() => {
        const fetchInfoContacto = async () => {
            const resp = await obtenerInformacionContacto();
            if (resp.ok && resp.output) {
                setVendedor(resp.output.vendedor);
                setAnalista(resp.output.analista);
                setEmailReclamos(resp.output.emailReclamos);
                setLoading(false);

                return;
            }

            setMsgError("Hubo un error al obtener los datos de contacto. Por favor, reintente más tarde.");
            setLoading(false);
        };

        fetchInfoContacto();
    }, []);

    if (loading) {
        return <Loading />;
    }

    if (msgError) {
        return (
            <Alert type="critical" title={null} icon>
                {msgError}
            </Alert>
        );
    }

    if (!vendedor || !analista) {
        return <></>;
    }

    const enviarEmailVentas = () =>
        setEnviarEmailProps({ tipoEmail: TipoEmail.VENTAS, titulo: `Enviar e-mail a ${vendedor.nombreApellido}` });
    const enviarEmailReclamos = () =>
        setEnviarEmailProps({ tipoEmail: TipoEmail.RECLAMOS, titulo: `Enviar e-mail a Reclamos` });
    const enviarEmailAnalista = () =>
        setEnviarEmailProps({ tipoEmail: TipoEmail.ANALISTA, titulo: `Enviar e-mail a ${analista.nombreApellido}` });

    const nroWhatsAppVendedor = vendedor.telefono ? vendedor.telefono.replace(/\D/g, "") : "";
    const nroWhatsAppAnalista = analista.telefono ? analista.telefono.replace(/\D/g, "") : "";

    return (
        <>
            {enviarEmailProps && (
                <ContactoEnviarEmailModal
                    onClose={() => setEnviarEmailProps(null)}
                    titulo={enviarEmailProps.titulo}
                    tipoEmail={enviarEmailProps.tipoEmail}
                />
            )}
            <Flex
                sx={{
                    mx: "auto",
                    my: "1em",
                    p: 2,
                    flexDirection: "column"
                }}
            >
                <Text color={"titulo"} fontSize={"2em"} fontWeight={600} textAlign={"left"} mb={3}>
                    Contáctenos
                </Text>

                <Dato
                    Icono={<AccountCircle color={"secondary"} size={"large"} />}
                    titulo={"vendedor"}
                    descripcion={vendedor.nombreApellido}
                />

                <Flex alignItems={"center"} mt={2}>
                    <Dato
                        Icono={<ContactEmail color={"secondary"} size={"large"} />}
                        onClick={enviarEmailVentas}
                        titulo={"e-mail"}
                        descripcion={vendedor.email.toLowerCase()}
                    />

                    <Flex ml={2}>
                        <Dato
                            Icono={<Email color={"secondary"} size={"large"} />}
                            onClick={enviarEmailReclamos}
                            titulo={"reclamos"}
                            descripcion={emailReclamos}
                        />
                    </Flex>
                </Flex>

                {vendedor.telefono && (
                    <Flex alignItems={"center"} mt={2}>
                        <Dato
                            Icono={<Phone color={"secondary"} size={"large"} />}
                            titulo={"teléfono"}
                            descripcion={vendedor.telefono}
                        />

                        {esValidoNroWhatsApp(nroWhatsAppVendedor) && (
                            <Box ml={2}>
                                <Button
                                    bordered={true}
                                    circled={true}
                                    href={`https://web.whatsapp.com/send?phone=${nroWhatsAppVendedor}`}
                                    external={true}
                                    iconLeft={<WhatsappIcon color={"green"} />}
                                    type={"success"}
                                    size={"small"}
                                >
                                    Enviar WhatsApp
                                </Button>
                            </Box>
                        )}
                    </Flex>
                )}

                <Box my={3}>
                    <Separator />
                </Box>

                <Dato
                    Icono={<AccountCircle color={"secondary"} size={"large"} />}
                    titulo={"analista"}
                    descripcion={analista.nombreApellido}
                />

                {analista.email && (
                    <Box mt={2}>
                        <Dato
                            Icono={<ContactEmail color={"secondary"} size={"large"} />}
                            onClick={enviarEmailAnalista}
                            titulo={"e-mail"}
                            descripcion={analista.email.toLowerCase()}
                        />
                    </Box>
                )}

                {analista.telefono && (
                    <Flex alignItems={"center"} mt={2}>
                        <Dato
                            Icono={<Phone color={"secondary"} size={"large"} />}
                            titulo={"teléfono analista"}
                            descripcion={analista.telefono}
                        />

                        {esValidoNroWhatsApp(nroWhatsAppAnalista) && (
                            <Box ml={2}>
                                <Button
                                    bordered={true}
                                    circled={true}
                                    href={`https://web.whatsapp.com/send?phone=${nroWhatsAppAnalista}`}
                                    external={true}
                                    iconLeft={<WhatsappIcon color={"green"} />}
                                    type={"success"}
                                    size={"small"}
                                >
                                    Enviar WhatsApp
                                </Button>
                            </Box>
                        )}
                    </Flex>
                )}
            </Flex>
        </>
    );
};

const Dato: FC<{ Icono: ReactNode; onClick?: () => void; titulo: string; descripcion: string }> = ({
    Icono,
    onClick,
    titulo,
    descripcion
}) => {
    const sx = {
        alignItems: "center",
        borderRadius: "0.15em 0.15em 0.15em 0.15em",
        color: "#6c6c6c",
        bg: "white",
        boxShadow: "small",
        flexGrow: 1,
        p: 2
    };

    if (onClick) {
        sx["&:hover"] = {
            boxShadow: "large",
            cursor: "pointer"
        };
    }

    return (
        <Flex sx={sx} onClick={onClick}>
            <Box>{Icono}</Box>
            <Flex flexDirection={"column"} ml={1}>
                <Text color={"titulo"} fontSize={"0.9em"}>
                    {titulo}:
                </Text>
                <Text color={"titulo"} fontSize={"1.3em"} fontWeight={500}>
                    {descripcion}
                </Text>
            </Flex>
        </Flex>
    );
};

const esValidoNroWhatsApp = (nro: string): boolean => {
    return nro.length === 13;
};
