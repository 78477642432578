import React, { useReducer } from "react";
import * as ActivacionUsuarioApi from "../api/ActivacionUsuarioApi";
import { ErrorCodes } from "../api/ActivacionUsuarioApi";
import { Box, Flex, Image, Text } from "rebass/styled-components";
import { Alert, Button, InputField } from "@kiwicom/orbit-components";
// import PassengerIcon from "@kiwicom/orbit-components/lib/icons/Passenger";
import LockIcon from "@kiwicom/orbit-components/lib/icons/Lock";
import SendIcon from "@kiwicom/orbit-components/lib/icons/Send";
import logoEcommerce from "../../assets/logo-ecommerce.png";

interface State {
    usuarioActivadoSuccess: boolean;
    linkIngreso?: string;
    msgError: string | null;
    form: {
        submitting: boolean;
        /*codCliente: {
            error: string | null;
            value: string;
        };
        cuit: {
            error: string | null;
            value: string;
        };*/
        password: {
            error: string | null;
            value: string;
        };
        password2: {
            error: string | null;
            value: string;
        };
    };
}

enum ActionTypes {
    /*SET_COD_CLIENTE = "SET_COD_CLIENTE",
    SET_CUIT = "SET_CUIT",*/
    SET_PASSWORD = "SET_PASSWORD",
    SET_PASSWORD_2 = "SET_PASSWORD_2",
    SET_SUBMITTING = "SET_SUBMITTING",
    CLEAR_ERRORS = "CLEAR_ERRORS",
    SET_ERRORS = "SET_ERRORS",
    SET_MSG_ERROR = "SET_MSG_ERROR",
    SUBMIT_RESPONSE = "SUBMIT_RESPONSE"
}

interface Errors {
    //codCliente?: string | null;
    //cuit?: string | null;
    password?: string | null;
    password2?: string | null;
}

type Action =
    /*| { type: ActionTypes.SET_COD_CLIENTE; codCliente: string }
    | { type: ActionTypes.SET_CUIT; cuit: string }*/
    | { type: ActionTypes.SET_PASSWORD; password: string }
    | { type: ActionTypes.SET_PASSWORD_2; password: string }
    | { type: ActionTypes.CLEAR_ERRORS }
    | { type: ActionTypes.SET_MSG_ERROR; msg: string }
    | { type: ActionTypes.SET_ERRORS; errors: Errors }
    | { type: ActionTypes.SUBMIT_RESPONSE; result: ActivacionUsuarioApi.Response }
    | { type: ActionTypes.SET_SUBMITTING; submitting: boolean };

const reducer = (state: State, action: Action): State => {
    const newState = Object.assign({}, state);

    switch (action.type) {
        /*case ActionTypes.SET_COD_CLIENTE:
            newState.form.codCliente.value = action.codCliente;
            newState.form.codCliente.error = null;
            return newState;

        case ActionTypes.SET_CUIT:
            newState.form.cuit.value = action.cuit;
            newState.form.cuit.error = null;
            return newState;*/

        case ActionTypes.SET_PASSWORD:
            newState.form.password.value = action.password;
            newState.form.password.error = null;
            return newState;

        case ActionTypes.SET_PASSWORD_2:
            newState.form.password2.value = action.password;
            newState.form.password2.error = null;
            return newState;

        case ActionTypes.SET_MSG_ERROR:
            newState.msgError = action.msg;
            return newState;

        case ActionTypes.SET_ERRORS:
            const { errors } = action;
            /*newState.form.codCliente.error = errors.codCliente ? errors.codCliente : null;
            newState.form.cuit.error = errors.cuit ? errors.cuit : null;*/
            newState.form.password.error = errors.password ? errors.password : null;
            newState.form.password2.error = errors.password2 ? errors.password2 : null;
            return newState;

        case ActionTypes.CLEAR_ERRORS:
            newState.msgError = null;
            /*newState.form.codCliente.error = null;
            newState.form.cuit.error = null;*/
            newState.form.password.error = null;
            newState.form.password2.error = null;
            return newState;

        case ActionTypes.SET_SUBMITTING:
            newState.form.submitting = action.submitting;
            return newState;

        case ActionTypes.SUBMIT_RESPONSE:
            const { result } = action;
            newState.form.submitting = false;
            if (result.ok && result.output) {
                newState.usuarioActivadoSuccess = true;
                newState.linkIngreso = result.output.linkIngreso;
                return newState;
            }

            if (result.errorCode) {
                switch (result.errorCode) {
                    case ErrorCodes.COD_ACTIVACION_INCORRECTO:
                        newState.msgError = "El link de activación es incorrecto";
                        return newState;

                    case ErrorCodes.COD_ACTIVACION_EXPIRADO:
                        newState.msgError =
                            "El link de activación está expirado. Por favor, vuelva a solicitar la creación de su usuario.";
                        return newState;

                    /*case ErrorCodes.COD_CLIENTE_INCORRECTO:
                        const msgCodClienteIncorrecto = "El código de cliente ingresado es incorrecto";
                        newState.msgError = msgCodClienteIncorrecto;
                        newState.form.codCliente.error = msgCodClienteIncorrecto;
                        return newState;

                    case ErrorCodes.CUIT_INCORRECTO:
                        const msgCuitIncorrecto = "El cuit ingresado es incorrecto";
                        newState.msgError = msgCuitIncorrecto;
                        newState.form.cuit.error = msgCuitIncorrecto;
                        newState.form.cuit.value = "";
                        return newState;*/

                    case ErrorCodes.PASSWORD_DEBIL:
                        const msgPassDebil = seguridadPasswordText;
                        newState.msgError = msgPassDebil;
                        newState.form.password.error = msgPassDebil;
                        newState.form.password.value = "";
                        newState.form.password2.value = "";
                        return newState;
                    default:
                        break;
                }
            }

            newState.msgError = "Hubo un error desconocido";
            return newState;

        default:
            throw new Error();
    }
};

const validate = ({
    /*codCliente,
    cuit,*/
    password,
    password2
}: {
    /*codCliente: string;
    cuit: string;*/
    password: string;
    password2: string;
}): [boolean, Errors] => {
    const errors = {} as Errors;
    let hasErrors = false;

    /*if (!codCliente) {
        errors.codCliente = "El cod. de cliente es obligatorio";
        hasErrors = true;
    }

    if (!cuit) {
        errors.cuit = "El cuit es obligatorio";
        hasErrors = true;
    }*/

    if (!password) {
        errors.password = "La contraseña es obligatoria";
        errors.password2 = "La contraseña es obligatoria";
        hasErrors = true;
    }

    if (password !== password2) {
        errors.password2 = "Las contraseñas ingresadas no coinciden";
        hasErrors = true;
    }

    return [hasErrors, errors];
};

const initialState: State = {
    usuarioActivadoSuccess: false,
    msgError: null,
    form: {
        submitting: false,
        /*codCliente: {
            error: null,
            value: ""
        },
        cuit: {
            error: null,
            value: ""
        },*/
        password: {
            error: null,
            value: ""
        },
        password2: {
            error: null,
            value: ""
        }
    }
};

const seguridadPasswordText = "Debe contener números y letras con un mínimo 6 caracteres.";

export const ActivacionUsuarioPage: React.FC = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let params = new URLSearchParams(location.search); /* eslint no-restricted-globals: 0 */
        const codActivacion = params.get("codActivacion");
        console.log("codActivacion: " + codActivacion);

        if (!codActivacion) {
            dispatch({ type: ActionTypes.SET_MSG_ERROR, msg: "URL Inválida" });
            return null;
        }

        dispatch({ type: ActionTypes.CLEAR_ERRORS });

        const [hasErrors, errors] = validate({
            /*codCliente: state.form.codCliente.value,
            cuit: state.form.cuit.value,*/
            password: state.form.password.value,
            password2: state.form.password2.value
        });

        if (hasErrors) {
            dispatch({ type: ActionTypes.SET_ERRORS, errors });
            return;
        }

        dispatch({ type: ActionTypes.SET_SUBMITTING, submitting: true });
        const result = await ActivacionUsuarioApi.activarUsuario({
            codActivacion,
            /*codCliente: codCliente.value,
            cuit: cuit.value,*/
            password: password.value
        });
        dispatch({ type: ActionTypes.SUBMIT_RESPONSE, result });
    };

    const {
        usuarioActivadoSuccess,
        linkIngreso,
        msgError,
        form: { submitting, /*codCliente, cuit,*/ password, password2 }
    } = state;

    if (usuarioActivadoSuccess && linkIngreso) {
        window.location.replace(linkIngreso);
        return <></>;
    }

    return (
        <Flex flexDirection={"column"} width={"25em"}>
            <Box mx={"auto"} width={"19em"}>
                <Image src={logoEcommerce} />
            </Box>
            <Box mb={4} mt={4}>
                <Text color={"textColorGray"} fontSize={"2em"} textAlign={"center"}>
                    Activación de Usuario
                </Text>
            </Box>
            {/*<Box mb={4}>
                <Text color={"colorTextSecondary"} fontSize={"1em"}>
                    Por favor, ingrese su código de cliente, CUIT y una contraseña para que podamos activar su usuario.
                </Text>
            </Box>*/}
            <form onSubmit={handleSubmit}>
                {msgError && (
                    <Box mb={3}>
                        <Alert type="critical" title={null} icon={true}>
                            {msgError}
                        </Alert>
                    </Box>
                )}

                <Flex flexDirection={"column"}>
                    {/*<Box mb={codCliente.error ? 4 : 2}>
                        <InputField
                            autoComplete={"off"}
                            disabled={submitting}
                            error={codCliente.error}
                            help={null}
                            inlineLabel={true}
                            label="Cod. Cliente"
                            name="codCliente"
                            onChange={e =>
                                dispatch({
                                    type: ActionTypes.SET_COD_CLIENTE,
                                    codCliente: e.target.value
                                })
                            }
                            prefix={<PassengerIcon />}
                            placeholder="Tú código de cliente de TASA"
                            required={true}
                            size="normal"
                            suffix={null}
                            type="text"
                            value={codCliente.value}
                        />
                    </Box>

                    <Box mb={cuit.error ? 4 : 2}>
                        <InputField
                            disabled={submitting}
                            error={cuit.error}
                            help={null}
                            inlineLabel={true}
                            label="CUIT"
                            name="cuit"
                            onChange={e =>
                                dispatch({
                                    type: ActionTypes.SET_CUIT,
                                    cuit: e.target.value
                                })
                            }
                            prefix={<PassengerIcon />}
                            placeholder={null}
                            required={true}
                            size="normal"
                            suffix={null}
                            type="text"
                            value={cuit.value}
                        />
                    </Box>*/}

                    <Box mb={password.error ? 4 : 4}>
                        <InputField
                            disabled={submitting}
                            error={password.error}
                            help={seguridadPasswordText}
                            inlineLabel={true}
                            label="Contraseña"
                            name="password"
                            onChange={e =>
                                dispatch({
                                    type: ActionTypes.SET_PASSWORD,
                                    password: e.target.value
                                })
                            }
                            prefix={<LockIcon />}
                            placeholder={null}
                            required={true}
                            size="normal"
                            suffix={null}
                            type="password"
                            value={password.value}
                        />
                    </Box>

                    <Box mb={password2.error ? 4 : 2}>
                        <InputField
                            disabled={submitting}
                            error={password2.error}
                            help={null}
                            inlineLabel={true}
                            label="Repetir Contraseña"
                            name="password"
                            onChange={e =>
                                dispatch({
                                    type: ActionTypes.SET_PASSWORD_2,
                                    password: e.target.value
                                })
                            }
                            prefix={<LockIcon />}
                            placeholder={null}
                            required={true}
                            size="normal"
                            suffix={null}
                            type="password"
                            value={password2.value}
                        />
                    </Box>

                    <Box mt={2}>
                        <Button
                            fullWidth={true}
                            disabled={submitting}
                            iconLeft={<SendIcon />}
                            // onClick={guardar}
                            submit={true}
                            type={"primary"}
                        >
                            Activar Usuario
                        </Button>
                    </Box>
                </Flex>
            </form>
        </Flex>
    );
};
