import React, { FC, ReactInstance, Reducer, useCallback, useEffect, useReducer, useRef, useState } from "react";
import { CarritoContainer } from "./CarritoContainer";
import { Box, Flex, Text } from "rebass/styled-components";
import { Alert, Button, Loading } from "@kiwicom/orbit-components";
import { IPedido, IPedidoDetalle } from "../api/CarritoApi";
import { capitalizeFirstLetter } from "../../lib/capitalizeFirstLetter";
import { Cantidades } from "../pedidos/Cantidades";
import { formatImporte } from "../../lib/format";
import { Package as PackageIcon } from "react-feather";
import CheckCircle from "@kiwicom/orbit-components/lib/icons/CheckCircle";
import Location from "@kiwicom/orbit-components/lib/icons/Location";
import {
    CarritoEditarCantidadesArticulo,
    CarritoEditarCantidadesArticuloData
} from "./CarritoEditarCantidadesArticulo";
import { pedidoDetalleToEditarCantidadesHelper } from "./helpers";
import { SeparadorVertical } from "../../ui_components/SeparadorVertical";
import { Link } from "react-router-dom";
import { rutas } from "../rutas";
import { CarritoImpuestosModal } from "./CarritoImpuestos";
import { confirmarPedido } from "../api/CarritoConfirmarPedido";
import { CambiarLugarEntrega } from "./CambiarLugarEntrega";
import { ILugarEntrega } from "../types";
import styled from "styled-components";
import { Printer } from "react-feather";
import ReactToPrint from "react-to-print";
import { ConfirmarPedidoSuccess } from "./ConfirmarPedidoSuccess";
import { ConfirmarPedidoCarritoVacio } from "./ConfirmarPedidoCarritoVacio";
import { ConfirmarPedidoEditarComentarioModal } from "./ConfirmarPedidoEditarComentarioModal";
import { ConfirmarPedidoEditarOrdenCompraModal } from "./ConfirmarPedidoEditarOrdenCompraModal";

interface State {
    confirmando: boolean;
    msgError: string | null;
    confirmadoSuccess: boolean;
    nroPedidoGenerado?: number;
    idPedidoGenerado?: number;
    editarCantidades: CarritoEditarCantidadesArticuloData | null;
    verCambiarLugarEntrega: boolean;
}

enum ActionsTypes {
    CONFIRMAR,
    CONFIRMAR_SUCCESS,
    CONFIRMAR_ERROR,
    EDITAR_DETALLE,
    SET_MSG_ERROR,
    SET_VER_CAMBIAR_LUGAR_ENTREGA
}

type Action =
    | { type: ActionsTypes.CONFIRMAR }
    | { type: ActionsTypes.CONFIRMAR_SUCCESS; nroPedidoGenerado: number; idPedidoGenerado: number }
    | { type: ActionsTypes.CONFIRMAR_ERROR; msgError: string }
    | { type: ActionsTypes.EDITAR_DETALLE; data: CarritoEditarCantidadesArticuloData | null }
    | { type: ActionsTypes.SET_MSG_ERROR; msgError: string | null }
    | { type: ActionsTypes.SET_VER_CAMBIAR_LUGAR_ENTREGA; ver: boolean };

const reducer = (state: State, action: Action): State => {
    const newState = Object.assign({}, state);

    switch (action.type) {
        case ActionsTypes.CONFIRMAR:
            newState.confirmando = true;
            return newState;

        case ActionsTypes.CONFIRMAR_SUCCESS:
            newState.confirmando = false;
            newState.confirmadoSuccess = true;
            newState.nroPedidoGenerado = action.nroPedidoGenerado;
            newState.idPedidoGenerado = action.idPedidoGenerado;
            return newState;

        case ActionsTypes.CONFIRMAR_ERROR:
            newState.confirmando = false;
            newState.msgError = action.msgError;
            return newState;

        case ActionsTypes.EDITAR_DETALLE:
            newState.editarCantidades = action.data;
            return newState;

        case ActionsTypes.SET_MSG_ERROR:
            newState.msgError = action.msgError;
            return newState;

        case ActionsTypes.SET_VER_CAMBIAR_LUGAR_ENTREGA:
            newState.verCambiarLugarEntrega = action.ver;
            return newState;
    }
};

const initialState: State = {
    msgError: null,
    confirmando: false,
    confirmadoSuccess: false,
    editarCantidades: null,
    verCambiarLugarEntrega: false
};

export const ConfirmarPedidoPage: FC = () => {
    const printRef = useRef<ReactInstance>();
    const { pedidoCarrito, loading, error: msgErrorCargarCarrito, fetch } = CarritoContainer.useContainer();

    const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, initialState);
    const { msgError, confirmando, confirmadoSuccess, editarCantidades, verCambiarLugarEntrega } = state;

    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch({ type: ActionsTypes.SET_MSG_ERROR, msgError: msgErrorCargarCarrito });
    }, [msgErrorCargarCarrito]);

    const ocultarCambiarLugarEntrega = useCallback(() => {
        dispatch({ type: ActionsTypes.SET_VER_CAMBIAR_LUGAR_ENTREGA, ver: false });
    }, []);

    const mostrarCambiarLugarEntrega = useCallback(() => {
        dispatch({ type: ActionsTypes.SET_VER_CAMBIAR_LUGAR_ENTREGA, ver: true });
    }, []);

    const imprimirCallback = useCallback(() => {
        if (printRef.current) {
            return printRef.current;
        }

        return new EmptyComponent({});
    }, [printRef]);

    const onSeleccionarLugarEntrega = () => {
        ocultarCambiarLugarEntrega();
        fetch();
    };

    const confirmar = useCallback(async () => {
        if (state.confirmando) return;

        dispatch({ type: ActionsTypes.CONFIRMAR });

        const result = await confirmarPedido();
        if (result.ok && result.output) {
            const { idPedidoGenerado, nroPedidoGenerado } = result.output;
            dispatch({
                type: ActionsTypes.CONFIRMAR_SUCCESS,
                idPedidoGenerado,
                nroPedidoGenerado
            });

            // Se recarga los datos del carrito así se actualiza la app (se limpie la notificación, el home, el sidebar, etc)
            fetch();
            return;
        }

        return dispatch({
            type: ActionsTypes.CONFIRMAR_ERROR,
            msgError: "Hubo un error al confirmar el pedido. Por favor, reintente más tarde."
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.confirmando]);

    if (loading) {
        return <Loading loading={true} type="boxLoader" text="Cargando..." />;
    }

    if (msgError) {
        return (
            <Box mb={4} mx={"auto"} my={3} width={"40em"}>
                <Alert type="critical" title={null} icon={true}>
                    {msgError}
                </Alert>
            </Box>
        );
    }

    if (confirmadoSuccess) {
        return (
            <ConfirmarPedidoSuccess
                nroPedidoGenerado={state.nroPedidoGenerado as number}
                idPedidoGenerado={state.idPedidoGenerado as number}
            />
        );
    }

    if (!pedidoCarrito) {
        return <ConfirmarPedidoCarritoVacio />;
    }

    return (
        <>
            {editarCantidades && (
                <CarritoEditarCantidadesArticulo
                    cerrarCallback={() => {
                        dispatch({ type: ActionsTypes.EDITAR_DETALLE, data: null });
                    }}
                    successCallback={() => {
                        dispatch({ type: ActionsTypes.EDITAR_DETALLE, data: null });
                        fetch();
                    }}
                    {...editarCantidades}
                />
            )}
            {verCambiarLugarEntrega && (
                <CambiarLugarEntrega
                    onCerrar={() => {
                        ocultarCambiarLugarEntrega();
                    }}
                    onSeleccionar={onSeleccionarLugarEntrega}
                />
            )}
            <Flex
                sx={{
                    flexDirection: "column",
                    flexGrow: 1,
                    minWidth: "60em",
                    mx: "auto",
                    my: 3,
                    py: 1,
                    px: 4
                }}
                ref={printRef}
            >
                <Text color={"titulo"} fontSize={6} fontWeight={600} mb={1} textAlign={"center"}>
                    Confirmación del Pedido
                </Text>
                <Text color={"titulo"} fontSize={5} fontWeight={600}>
                    Lugar de Entrega
                </Text>
                <LugarEntrega
                    lugarEntrega={pedidoCarrito.lugarEntrega}
                    onCambiarLugarEntrega={() => {
                        mostrarCambiarLugarEntrega();
                    }}
                />

                <Text color={"titulo"} fontSize={5} fontWeight={600} my={3}>
                    Detalles del Pedido
                </Text>
                <DetallesDelPedido
                    detalles={pedidoCarrito.detalles}
                    moneda={pedidoCarrito.moneda}
                    onEditarDetalle={(detalle: IPedidoDetalle) =>
                        dispatch({
                            type: ActionsTypes.EDITAR_DETALLE,
                            data: pedidoDetalleToEditarCantidadesHelper(detalle, pedidoCarrito)
                        })
                    }
                />
                <Flex mt={1}>
                    <Box width={3 / 4}>
                        <Comentario comentario={pedidoCarrito.comentario || ""} onGuardar={() => fetch()} />
                    </Box>
                    <Box ml={2} width={1 / 4}>
                        <OrdenCompra ordenCompra={pedidoCarrito.ocCliente || ""} onGuardar={() => fetch()} />
                    </Box>
                </Flex>
                <Text color={"titulo"} fontSize={5} fontWeight={600} my={3}>
                    Totales
                </Text>
                <Footer
                    confirmando={confirmando}
                    pedido={pedidoCarrito}
                    onConfirmar={() => {
                        confirmar();
                    }}
                    imprimirContent={imprimirCallback}
                />
            </Flex>
        </>
    );
};

const LugarEntrega: FC<{
    lugarEntrega: ILugarEntrega;
    onCambiarLugarEntrega: (lugarEntrega: ILugarEntrega) => void;
}> = ({ lugarEntrega, onCambiarLugarEntrega }) => {
    return (
        <Flex
            sx={{
                alignItems: "center",
                borderRadius: "0.5em",
                color: "confirmarPedidoTextColor",
                bg: "#E5EAEF",
                my: 3,
                p: 3
            }}
        >
            <Box mr={2}>
                <Location />
            </Box>
            <Flex flexDirection={"column"} mr={5}>
                <Text fontSize={3} fontWeight={400}>
                    {lugarEntrega.descripcion}
                </Text>
                <Text fontSize={2}>{lugarEntrega.domicilio.direccion}</Text>
            </Flex>
            <Flex flexDirection={"column"} mr={5}>
                <Text fontSize={2} textAlign={"center"}>
                    {lugarEntrega.domicilio.localidad}
                </Text>
                <Text fontSize={2} textAlign={"center"}>
                    {lugarEntrega.domicilio.provincia}
                </Text>
            </Flex>
            <Flex ml={"auto"}>
                <Button
                    onClick={onCambiarLugarEntrega}
                    component="button"
                    circled={true}
                    block={false}
                    bordered={true}
                    type="secondary"
                    size="small"
                    iconLeft={<Location />}
                    title="Elegir Lugar de Entrega"
                >
                    Elegir Lugar de Entrega
                </Button>
            </Flex>
        </Flex>
    );
};

type EditarDetalleCallback = (detalle: IPedidoDetalle) => void;

const DetallesDelPedido: FC<{
    detalles: Array<IPedidoDetalle>;
    moneda: string;
    onEditarDetalle: EditarDetalleCallback;
}> = ({ detalles, moneda, onEditarDetalle }) => {
    return (
        <Flex
            sx={{
                color: "confirmarPedidoTextColor",
                flexDirection: "column",
                height: "20vh",
                overflowY: "auto",
                "::-webkit-scrollbar-track": {
                    "border-radius": "6px",
                    "background-color": "#F5F5F5"
                },
                "::-webkit-scrollbar": {
                    width: "8px",
                    "background-color": "#F5F5F5"
                },
                "::-webkit-scrollbar-thumb": {
                    "border-radius": "8px",
                    "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,.3)",
                    "background-color": "#BAC7D5"
                }
            }}
        >
            {detalles.map(detalle => {
                return (
                    <Flex
                        sx={{
                            alignItems: "center",
                            borderRadius: "0.1em",
                            justifyContent: "space-between",
                            bg: "white",
                            my: 1,
                            p: 2,
                            "&:hover": {
                                bg: "#f5f7f9",
                                cursor: "pointer",
                                boxShadow: "small"
                            }
                        }}
                        onClick={() => onEditarDetalle(detalle)}
                        key={detalle.id}
                    >
                        <ColArticulo pedidoDetalle={detalle} />

                        <Flex width={"20em"}>
                            <Flex alignItems={"center"} flexDirection={"column"} mr={"3em"}>
                                <PackageIcon />
                                <Text>
                                    {detalle.cantPaquetes} {detalle.cantPaquetes === 1 ? "paquete" : "paquetes"}
                                </Text>
                            </Flex>

                            <Box>
                                <Cantidades
                                    cant={detalle.cantidades}
                                    horizontal={false} /*color={"datagridResaltado"}*/
                                />
                            </Box>
                        </Flex>
                        <Box sx={{ textAlign: "right", width: "11em" }}>
                            <ImporteDetalle detalle={detalle} moneda={moneda} />
                        </Box>
                    </Flex>
                );
            })}
        </Flex>
    );
};

const ColArticulo: FC<{ pedidoDetalle: IPedidoDetalle }> = ({ pedidoDetalle }) => {
    const {
        articulo: { codigo, descripcion, forma, medida, diametro, extremo }
    } = pedidoDetalle;

    return (
        <Flex alignItems={"center"} width={"20em"}>
            <Flex alignItems={"start"} flexDirection={"column"}>
                <Text /*color={"datagridResaltado"}*/ fontSize={"0.8em"} mb={1}>
                    {codigo}
                </Text>
                <Text fontSize={3} fontWeight={600}>
                    {descripcion}
                </Text>
                <Flex justifyContent={"space-between"} fontSize={"0.8em"} flexWrap={"wrap"} mt={2} width={"100%"}>
                    <Categoria nombre={"Forma"} valor={forma} />
                    <Categoria nombre={"Medida"} valor={medida} />
                    <Categoria nombre={"Diámetro"} valor={diametro} />
                    <Categoria nombre={"Extremo"} valor={extremo} />
                </Flex>
            </Flex>
        </Flex>
    );
};

const Categoria: FC<{ nombre: string; valor: string | null }> = ({ nombre, valor }) => {
    if (!valor) {
        return <></>;
    }

    return (
        <Flex alignItems={"start"} flexDirection={"column"} mr={2} mb={2}>
            <Text>{nombre}:</Text>
            <Text fontWeight={300}>{capitalizeFirstLetter(valor)}</Text>
        </Flex>
    );
};

const ImporteDetalle: FC<{ detalle: IPedidoDetalle; moneda: string }> = ({ detalle, moneda }) => {
    const { porcDescuento4, cantPaquetes, precioPaqueteSinDescuento4, precioFinal } = detalle;
    if (porcDescuento4 > 0) {
        const precioFinalSinDescuento = cantPaquetes * precioPaqueteSinDescuento4;
        return (
            <Flex flexDirection={"column"} mr={1}>
                <Text
                    color={"paletteRedNormal"}
                    fontSize={3}
                    textAlign={"center"}
                    style={{ textDecoration: "line-through" }}
                >
                    {moneda} {formatImporte(precioFinalSinDescuento)}
                </Text>
                <Text fontSize={5}>
                    {moneda} {formatImporte(precioFinal)}
                </Text>
            </Flex>
        );
    }

    return (
        <Box>
            <Text fontSize={5}>
                {moneda} {formatImporte(detalle.precioFinal)}
            </Text>
        </Box>
    );
};

const StyledLink = styled(Link)`
    color: #0293cf;
    margin-top: 5px;
`;

const Comentario: FC<{ comentario: string; onGuardar: () => void }> = ({ comentario, onGuardar }) => {
    const [editarComentario, setEditarComentario] = useState<boolean>(false);

    if (editarComentario) {
        return (
            <ConfirmarPedidoEditarComentarioModal
                comentario={comentario}
                onGuardar={onGuardar}
                onClose={() => setEditarComentario(false)}
            />
        );
    }

    return (
        <Flex flexDirection={"column"}>
            <Text color={"titulo"} fontSize={4} fontWeight={600} mt={2}>
                Comentario:
            </Text>
            <Box
                sx={{
                    alignItems: "center",
                    borderRadius: "0.5em",
                    color: "confirmarPedidoTextColor",
                    bg: "#E5EAEF",
                    my: 0,
                    p: 3,
                    "&:hover": {
                        bg: "#D6DEE6",
                        cursor: "pointer"
                    }
                }}
                onClick={() => {
                    setEditarComentario(true);
                }}
            >
                <pre
                    style={{
                        fontFamily: "'Roboto', sans-serif",
                        margin: 0
                    }}
                >
                    {comentario ? comentario : "Dejar Comentario..."}
                </pre>
            </Box>
        </Flex>
    );
};

const OrdenCompra: FC<{ ordenCompra: string; onGuardar: () => void }> = ({ ordenCompra, onGuardar }) => {
    const [editarOrdenCompra, setEditarOrdenCompra] = useState<boolean>(false);

    if (editarOrdenCompra) {
        return (
            <ConfirmarPedidoEditarOrdenCompraModal
                ordenCompra={ordenCompra}
                onGuardar={onGuardar}
                onClose={() => setEditarOrdenCompra(false)}
            />
        );
    }

    return (
        <Flex flexDirection={"column"}>
            <Text color={"titulo"} fontSize={4} fontWeight={600} mt={2}>
                Orden Compra:
            </Text>
            <Box
                sx={{
                    alignItems: "center",
                    borderRadius: "0.5em",
                    color: "confirmarPedidoTextColor",
                    bg: "#E5EAEF",
                    my: 0,
                    p: 3,
                    "&:hover": {
                        bg: "#D6DEE6",
                        cursor: "pointer"
                    }
                }}
                onClick={() => {
                    setEditarOrdenCompra(true);
                }}
            >
                {ordenCompra ? ordenCompra : "Orden Compra Interna..."}
            </Box>
        </Flex>
    );
};

const Footer: FC<{
    confirmando: boolean;
    pedido: IPedido;
    onConfirmar: () => void;
    imprimirContent: () => ReactInstance;
}> = ({ confirmando, pedido, onConfirmar, imprimirContent }) => {
    const { moneda, subTotal, totalImpuestos, total } = pedido;
    const [verImpuestos, setVerImpuestos] = useState<boolean>(false);

    return (
        <>
            {verImpuestos && (
                <CarritoImpuestosModal
                    cerrarCallback={() => {
                        setVerImpuestos(false);
                    }}
                    pedido={pedido}
                />
            )}
            <Flex flexDirection={"column"}>
                <Flex py={2} px={1}>
                    <TotalesImporte descripcion={"SUB TOTAL"} moneda={"U$S"} importe={subTotal} />
                    <SeparadorVertical mx={3} />
                    <Flex flexDirection={"column"} ml={0}>
                        <Text color={"confirmarPedidoTextColor"} fontSize={"1.5em"}>
                            IMPUESTOS
                        </Text>
                        <Text
                            sx={{
                                "&:hover": {
                                    cursor: "pointer",
                                    textDecoration: "underline"
                                }
                            }}
                            color={"datagridResaltado"}
                            fontSize={"1.6em"}
                            onClick={() => {
                                setVerImpuestos(true);
                            }}
                        >
                            {moneda} {formatImporte(totalImpuestos)}
                        </Text>
                    </Flex>

                    <SeparadorVertical mx={3} />
                    <TotalesImporte descripcion={"TOTAL"} moneda={moneda} importe={total} />
                    <Flex alignItems={"center"} flexDirection={"column"} ml={"auto"}>
                        <Button
                            block={false}
                            circled={true}
                            disabled={confirmando}
                            iconLeft={<CheckCircle />}
                            onClick={onConfirmar}
                            size={"large"}
                            title={`Confirmar Pedido`}
                            type={"success"}
                        >
                            {confirmando ? "CONFIRMANDO PEDIDO..." : "CONFIRMAR PEDIDO"}
                        </Button>
                        {!confirmando && <StyledLink to={rutas.nuevoPedido}>Continuar Comprando</StyledLink>}
                    </Flex>
                    <Box>
                        <ReactToPrint
                            trigger={() => (
                                <Button
                                    block={false}
                                    circled={true}
                                    disabled={confirmando}
                                    iconLeft={<Printer />}
                                    onClick={() => {}}
                                    size={"large"}
                                    title={`Imprimir Pedido`}
                                    type={"white"}
                                />
                            )}
                            content={imprimirContent}
                        />
                    </Box>
                </Flex>
                <Box>
                    <Text sx={{ color: "datagridLightText", textAlign: "center", fontSize: "0.8em" }}>
                        Precios sujetos al momento de aprobación del pedido.
                    </Text>
                </Box>
            </Flex>
        </>
    );
};

interface TotalesImporteProps {
    descripcion: string;
    moneda: string;
    importe: number;
}

const TotalesImporte: FC<TotalesImporteProps> = ({ descripcion, moneda, importe }) => {
    return (
        <Flex flexDirection={"column"} ml={0}>
            <Text color={"confirmarPedidoTextColor"} fontSize={"1.5em"}>
                {descripcion}
            </Text>
            <Text color={"datagridResaltado"} fontSize={"1.6em"}>
                {moneda} {formatImporte(importe)}
            </Text>
        </Flex>
    );
};

class EmptyComponent extends React.Component {
    render() {
        return null;
    }
}
