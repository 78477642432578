import { IDomicilio } from "../types";
import { makeHttpRequest, POST, ResultBase } from "./api";

interface RequestData {
    idRemito: number;
}

export enum ErrorCodes {}

interface ICliente {
    nombre: string;
    cuit: string;
    domicilio: IDomicilio;
}

interface IEstado {
    codigo: string;
    nombre: string;
}

export interface IRemito {
    id: number;
    estado: IEstado;
    nroRemito: string;
    fechaRemito: string;
    fechaEntrega: string;
    pedido: {
        nroPedido: number;
        nroPedidoAnticipo: number | null;
        nroOCCliente: string | null;
    };
    cliente: ICliente;
    entrega: {
        transportista: string | null;
        cuitTransportista: string | null;
        chofer: string | null;
        dniChofer: string | null;
        vehiculo: string | null;
        domicilioEntrega: IDomicilio;
    };
    detalles: IRemitoDetalle[];
}

export interface IRemitoDetalle {
    estado: IEstado;
    codArticulo: string;
    descripcion: string;
    umCantidad1: string | null;
    umCantidad2: string | null;
    umCantidad3: string | null;
    totalCantidad1: number | null;
    totalCantidad2: number | null;
    totalCantidad3: number | null;
    paquetes: Array<string>;
}

interface ResponseOutput {
    remito: IRemito;
}
export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const obtenerRemito = async (reqData): Promise<Result> =>
    makeHttpRequest<RequestData, ResponseOutput, ErrorCodes>(POST, "remito-doc", reqData);
