import styled from "styled-components";
import { Box, Flex, Image, Text } from "rebass/styled-components";
import { Link } from "react-router-dom";
import React, { FC } from "react";
// import logo from "../../assets/logo-ecommerce.png";
import logo from "../../assets/logo_ecommerce_2.png";
// import logo from "../../assets/logo-tasa-con-slogan.png";
import { ItemMenu } from "./AppLayout";
import { WidthProps } from "styled-system";

/*interface SidebarItemProps extends FlexProps, BoxProps {
    estaActivo?: boolean;
}
const SidebarItem2: FC<SidebarItemProps> = props => {
    return <Flex {...props}>{props.children}</Flex>;
};

const StyledSidebarItem = styled(SidebarItem2)`
    align-items: center;
    border-left: ${props => props.estaActivo && props.theme.sidebar.item.selected.border};
    color: ${props => props.theme.sidebar.item.color};
    font-weight: 300;
    flex-direction: column;
    nombreUsuario-transform: uppercase;

    &:hover {
        transition: 0.25s ease-in;
        background-color: ${props => props.theme.sidebar.item.hover.bg};
    }
`;*/

const SidebarItem: FC<{ esElPrimerItem: boolean; estaActivo?: boolean }> = ({
    esElPrimerItem,
    estaActivo,
    children
}) => {
    return (
        <Flex
            alignItems={"center"}
            sx={{
                color: "sidebarItemColor",
                fontWeight: 300,
                flexDirection: "column",
                textTransform: "uppercase",
                borderColor: "sidebarItemBorder",
                borderLeftWidth: "0.3em",
                borderLeftStyle: estaActivo && "solid",
                borderTop: esElPrimerItem ? "1px solid #dee2e647" : null,
                borderBottom: "1px solid #dee2e647",
                // pl: estaActivo ? 3 : 2,
                py: "1.3em",
                "&:hover": {
                    bg: "sidebarItemHoverBg",
                    transition: "0.25s ease-in"
                }
            }}
        >
            {children}
        </Flex>
    );
};

const StyledLink = styled(Link)`
    text-decoration: none;
`;

interface Props extends WidthProps {
    items: Array<ItemMenu>;
}

const scrollBarCSS = {
    "::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
        "border-radius": "10px",
        "background-color": "#F5F5F5"
    },
    "::-webkit-scrollbar": {
        width: "6px",
        "background-color": "#F5F5F5"
    },
    "::-webkit-scrollbar-thumb": {
        "border-radius": "10px",
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,.3)",
        "background-color": "#0293cf"
    }
};

export const AppLayoutSidebar: FC<Props> = ({ items, width }) => {
    return (
        <Box
            sx={{
                bg: "sidebarBg",
                height: "100vh",
                overflowY: "auto",
                ...scrollBarCSS
            }}
            width={width}
        >
            <Flex flexDirection={"column"}>
                <Flex
                    sx={{
                        bg: "sidebarBg",
                        position: "sticky",
                        // maxHeight: "6rem",
                        top: 0,
                        zIndex: 4
                    }}
                >
                    <Image py={3} mb={3} px={2} src={logo} />
                </Flex>

                {items.map(({ ruta, titulo, Icono, isActive }, index) => {
                    return (
                        <StyledLink to={ruta} key={ruta}>
                            <SidebarItem esElPrimerItem={index === 0} estaActivo={isActive}>
                                <Box>
                                    <Icono size={24} />
                                </Box>
                                <Text fontSize={1} textAlign={"center"}>
                                    {titulo}
                                </Text>
                            </SidebarItem>
                        </StyledLink>
                    );
                })}
            </Flex>
        </Box>
    );
};
