import React, { FC, useState } from "react";
import { Button, Checkbox, InputField } from "@kiwicom/orbit-components";
import Modal, { ModalHeader, ModalSection, ModalFooter } from "@kiwicom/orbit-components/lib/Modal";
import CalendarIcon from "@kiwicom/orbit-components/lib/icons/Calendar";
import CloseIcon from "@kiwicom/orbit-components/lib/icons/Close";
import { Box, Flex } from "rebass/styled-components";
import DatePicker from "react-datepicker";
import { Filter } from "react-feather";
import { IFacturasListFiltros } from "../api/FacturasListApi";
import { IFiltroActivo } from "../../ui_components/ListView/FiltrosActivos";
import { formatFecha } from "../../lib/format";

interface Props {
    filtros?: IFacturasListFiltros;
    cerrarCallback: () => void;
    filtrarCallback: (filtros: IFacturasListFiltros) => void;
}

export const FacturasListFiltros: FC<Props> = ({ cerrarCallback, filtros, filtrarCallback }) => {
    const [fechaDesde, setFechaDesde] = useState<Date | undefined>(filtros && filtros.fechaDesde);
    const [fechaHasta, setFechaHasta] = useState<Date | undefined>(filtros && filtros.fechaHasta);
    const [verSoloFacturasConSaldo, setVerSoloPedidosActivos] = useState(
        filtros ? filtros.verSoloFacturasConSaldo : false
    );
    const [fechaVencimientoDesde, setFechaVencimientoDesde] = useState<Date | undefined>(
        filtros && filtros.fechaVencimientoDesde
    );
    const [fechaVencimientoHasta, setFechaVencimientoHasta] = useState<Date | undefined>(
        filtros && filtros.fechaVencimientoHasta
    );

    const getFiltros = (): IFacturasListFiltros => {
        return {
            fechaDesde,
            fechaHasta,
            verSoloFacturasConSaldo,
            fechaVencimientoDesde,
            fechaVencimientoHasta
        };
    };

    const mt = 3;
    const dateFormat = "dd/MM/yyyy";

    return (
        <Modal fixedFooter={false} isMobileFullPage={false} size={"small"}>
            <ModalHeader title={"Filtros de Facturas"} suppressed={true} />
            <ModalSection suppressed={false}>
                <Flex flexDirection={"column"} mx={"auto"} width={1 / 2}>
                    <Box>
                        <DatePicker
                            customInput={
                                <InputField
                                    inlineLabel={false}
                                    label={"Fecha Desde:"}
                                    prefix={<CalendarIcon />}
                                    size={"normal"}
                                />
                            }
                            dateFormat={dateFormat}
                            endDate={fechaHasta}
                            onChange={fecha => setFechaDesde(fecha)}
                            selected={fechaDesde}
                            selectsStart={true}
                        />
                    </Box>
                    <Box mt={mt}>
                        <DatePicker
                            customInput={
                                <InputField
                                    inlineLabel={false}
                                    label={"Fecha Hasta:"}
                                    prefix={<CalendarIcon />}
                                    size={"normal"}
                                />
                            }
                            dateFormat={dateFormat}
                            startDate={fechaDesde}
                            onChange={fecha => setFechaHasta(fecha)}
                            selected={fechaHasta}
                            selectsEnd={true}
                        />
                    </Box>

                    <Box mt={mt}>
                        <DatePicker
                            customInput={
                                <InputField
                                    inlineLabel={false}
                                    label={"Fecha de Vencimiento Desde:"}
                                    prefix={<CalendarIcon />}
                                    size={"normal"}
                                />
                            }
                            dateFormat={dateFormat}
                            endDate={fechaVencimientoHasta}
                            onChange={fechaVencimiento => setFechaVencimientoDesde(fechaVencimiento)}
                            selected={fechaVencimientoDesde}
                            selectsStart={true}
                        />
                    </Box>
                    <Box mt={mt}>
                        <DatePicker
                            customInput={
                                <InputField
                                    inlineLabel={false}
                                    label={"Fecha de Vencimiento Hasta:"}
                                    prefix={<CalendarIcon />}
                                    size={"normal"}
                                />
                            }
                            dateFormat={dateFormat}
                            startDate={fechaVencimientoDesde}
                            onChange={fechaVencimiento => setFechaVencimientoHasta(fechaVencimiento)}
                            selected={fechaVencimientoHasta}
                            selectsEnd={true}
                        />
                    </Box>

                    <Box mt={mt}>
                        <Checkbox
                            label="Ver Solo Facturas con Saldo"
                            checked={verSoloFacturasConSaldo}
                            onChange={() => {
                                setVerSoloPedidosActivos(!verSoloFacturasConSaldo);
                            }}
                        />
                    </Box>
                </Flex>
            </ModalSection>
            <ModalFooter fixed={true} flex={["0 0 auto", "1 1 100%"]} suppressed={true}>
                <Button iconLeft={<CloseIcon />} onClick={cerrarCallback} type="secondary">
                    Cerrar
                </Button>
                <Button block iconLeft={<Filter />} onClick={() => filtrarCallback(getFiltros())} type={"primary"}>
                    Filtrar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const eliminarFiltro = (filtros: IFacturasListFiltros, key: string): IFacturasListFiltros => {
    const nuevosFiltros = Object.assign({}, filtros);
    delete nuevosFiltros[key];
    return nuevosFiltros;
};

export const obtenerFiltrosActivos = (
    filtros: IFacturasListFiltros | undefined,
    filtrar: (IFacturasListFiltros) => void
): Array<IFiltroActivo> => {
    if (!filtros) {
        return [];
    }

    const { fechaDesde, fechaHasta, verSoloFacturasConSaldo, fechaVencimientoDesde, fechaVencimientoHasta } = filtros;
    let filtrosActivos: Array<IFiltroActivo> = [];

    if (fechaDesde) {
        filtrosActivos.push({
            descripcion: `Fecha Desde: ${formatFecha(fechaDesde)}`,
            eliminarFiltro: () => {
                filtrar(eliminarFiltro(filtros, "fechaDesde"));
            }
        });
    }

    if (fechaHasta) {
        filtrosActivos.push({
            descripcion: `Fecha Hasta: ${formatFecha(fechaHasta)}`,
            eliminarFiltro: () => {
                filtrar(eliminarFiltro(filtros, "fechaHasta"));
            }
        });
    }

    if (fechaVencimientoDesde) {
        filtrosActivos.push({
            descripcion: `Fecha Vencimiento Desde: ${formatFecha(fechaVencimientoDesde)}`,
            eliminarFiltro: () => {
                filtrar(eliminarFiltro(filtros, "fechaVencimientoDesde"));
            }
        });
    }

    if (fechaVencimientoHasta) {
        filtrosActivos.push({
            descripcion: `Fecha Vencimiento Hasta: ${formatFecha(fechaVencimientoHasta)}`,
            eliminarFiltro: () => {
                filtrar(eliminarFiltro(filtros, "fechaVencimientoHasta"));
            }
        });
    }

    if (verSoloFacturasConSaldo) {
        filtrosActivos.push({
            descripcion: `Ver Solo Facturas con Saldo`,
            eliminarFiltro: () => {
                filtrar(eliminarFiltro(filtros, "verSoloFacturasConSaldo"));
            }
        });
    }

    return filtrosActivos;
};
