import { makeHttpRequest, POST, ResultBase } from "./api";

export interface Request {
    idListaPreciosDetalle: number;
    cantPaquetes: number;
}

export interface QuitarArticuloCarritoRequest {
    idListaPreciosDetalle: number;
}

export enum ErrorCodes {
    CANT_PAQUETES_INCORRECTA = "CANT_PAQUETES_INCORRECTA"
}

interface ResponseOutput {}

export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const editarArticuloCarrito = async (reqData: Request): Promise<Result> =>
    makeHttpRequest<Request, ResponseOutput, ErrorCodes>(POST, "carrito-editar-detalle", reqData);
