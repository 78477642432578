import { ResultBase, makeHttpRequest, POST } from "./api";

export enum ErrorCodes {}

export interface RequestData {
    comentario: string;
}

export interface ResponseData {}
export interface Response extends ResultBase<ResponseData, ErrorCodes> {}

export const guardarComentario = async (reqData: RequestData): Promise<Response> =>
    makeHttpRequest<{}, ResponseData, ErrorCodes>(POST, "carrito-guardar-comentario", reqData);
