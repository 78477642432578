import { ResultBase, makeHttpRequest, POST } from "./api";

export enum ErrorCodes {}

export interface RequestData {
    ordenCompra: string;
}

export interface ResponseData {}
export interface Response extends ResultBase<ResponseData, ErrorCodes> {}

export const guardarOrdenCompra = async (reqData: RequestData): Promise<Response> =>
    makeHttpRequest<{}, ResponseData, ErrorCodes>(POST, "carrito-guardar-orden-compra", reqData);
