import { ResultBase, makeHttpRequest, POST } from "./api";

export enum ErrorCodes {
    EMAIL_YA_REGISTRADO = "EMAIL_YA_REGISTRADO"
}

export interface RequestData {
    email: string;
    admin: boolean;
}

export interface ResponseData {}
export interface Response extends ResultBase<ResponseData, ErrorCodes> {}

export const crearUsuario = async (reqData: RequestData): Promise<Response> =>
    makeHttpRequest<{}, ResponseData, ErrorCodes>(POST, "usuarios-crear-usuario", reqData);
