import { IDomicilio } from "../types";
import { makeHttpRequest, POST, ResultBase } from "./api";

interface RequestData {
    idRecibo: number;
}

export enum ErrorCodes {}

interface ICliente {
    nombre: string;
    cuit: string;
    domicilio: IDomicilio;
}

export interface IRecibo {
    id: number;
    nroRecibo: string;
    fecha: string;
    nroReciboProvisorio: string;
    fechaReciboProvisorio: string;
    cliente: ICliente;
    formasCobro: IReciboFormaCobro[];
    comprobantes: IReciboComprobante[];
    retenciones: IReciboRetencion[];
    moneda: string;
    totalFormasCobro: number;
    totalComprobantes: number;
    totalRetenciones: number;
    totalAPlicar: number;
    totalPendienteAplicacion: number;
    // descripcionMoneda: string;
    // totalAPlicarEnLetras: string;
}

export interface IReciboFormaCobro {
    tipoFormaCobro: string;
    descripcion: string;
    moneda: string;
    importeFormaCobro: number;
    importe: number;
}

export interface IReciboComprobante {
    tipoComprobante: string;
    nroComprobante: string;
    fecha: string;
    importe: number;
}

export interface IReciboRetencion {
    tipoRetencion: string;
    nroCertificado: string;
    importe: number;
}

interface ResponseOutput {
    recibo: IRecibo;
}
export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const obtenerRecibo = async (reqData): Promise<Result> =>
    makeHttpRequest<RequestData, ResponseOutput, ErrorCodes>(POST, "recibo-doc", reqData);
