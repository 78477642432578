import React, { FC, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { CCDoc, CCTiposDocs, ICCMovimiento, ICCPedido, ICCRemito, ICCSaldo } from "../api/CuentaCorrienteApi";
import Card, { CardSection } from "@kiwicom/orbit-components/lib/Card";
import Separator from "@kiwicom/orbit-components/lib/Separator";
import ChevronDown from "@kiwicom/orbit-components/lib/icons/ChevronDown";
import { Calendar, File, ShoppingCart } from "react-feather";
import { formatFecha, formatImporte } from "../../lib/format";
import { parse } from "date-fns";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { theme } from "../theme";
import { VerDocArgs } from "./CuentaCorrientePage";

export const CuentaCorrienteList: FC<{
    saldos: Array<ICCSaldo>;
    verDoc: (verDocArgs: VerDocArgs) => void;
}> = ({ saldos, verDoc }) => {
    const widthColImporte = "8em";
    const [idsSaldosExpandidos, setIdsSaldosExpandidos] = useState<number[]>([]);

    const verOOcultarMovimientos = (idSaldo: number) => {
        if (idsSaldosExpandidos.indexOf(idSaldo) >= 0) {
            setIdsSaldosExpandidos(idsSaldosExpandidos.filter(id => id !== idSaldo));
        } else {
            setIdsSaldosExpandidos([...idsSaldosExpandidos, idSaldo]);
        }
    };

    return (
        <Flex flexDirection={"column"} mb={4}>
            <Flex color={"#626262"} mb={2}>
                <Box ml={"1"}>
                    <Text fontWeight={600}>Documento</Text>
                </Box>
                <Flex ml={"auto"} mr={"3.5em"}>
                    <Text fontWeight={600} textAlign={"right"} width={widthColImporte}>
                        Importe Doc.
                    </Text>
                    <Text fontWeight={600} textAlign={"right"} width={widthColImporte}>
                        Saldo
                    </Text>
                </Flex>
            </Flex>

            <Box>
                {saldos.map((saldo, index) => {
                    const { doc, monedaDoc, importeDoc } = saldo;
                    const docColor = getDocColor(doc);
                    const remito = doc.tipo === CCTiposDocs.FACTURA ? doc.factura.remito : null;
                    const pedido = doc.tipo === CCTiposDocs.FACTURA ? doc.factura.pedido : null;
                    const verMovimientos = idsSaldosExpandidos.indexOf(saldo.id) >= 0;

                    return (
                        <Card key={index}>
                            <CardSection>
                                <Flex flexDirection={"column"}>
                                    <Flex alignItems={"center"}>
                                        <Flex width={"17em"}>
                                            <ColDocumento verDoc={verDoc} doc={doc} />
                                        </Flex>

                                        <Box color={"datagridLightText"} width={"2em"}>
                                            <Calendar />
                                        </Box>
                                        <Box fontSize={"0.9em"} width={"10em"}>
                                            <FechaDocumento doc={doc} />
                                        </Box>

                                        <Box fontSize={"0.9em"} width={"8em"}>
                                            {doc.tipo === CCTiposDocs.FACTURA && remito && (
                                                <Remito
                                                    remito={remito}
                                                    onClick={() => verDoc({ idRemito: remito.idRemito })}
                                                />
                                            )}
                                        </Box>

                                        <Box fontSize={"0.9em"} width={"6em"}>
                                            {doc.tipo === CCTiposDocs.FACTURA && remito && pedido && (
                                                <Pedido
                                                    pedido={pedido}
                                                    onClick={() => verDoc({ idPedido: pedido.id })}
                                                />
                                            )}
                                        </Box>

                                        <Flex width={"9em"}>
                                            <Text color={docColor} ml={"auto"}>
                                                {importeDoc < 0
                                                    ? `- ${monedaDoc} ${formatImporte(importeDoc * -1)}`
                                                    : `${monedaDoc} ${formatImporte(importeDoc)}`}
                                            </Text>
                                        </Flex>

                                        <Flex width={"9em"}>
                                            <ColSaldo
                                                docColor={docColor}
                                                saldo={saldo}
                                                onClick={() => verOOcultarMovimientos(saldo.id)}
                                            />
                                        </Flex>
                                    </Flex>

                                    {verMovimientos && (
                                        <>
                                            <Box>
                                                <Separator />
                                            </Box>
                                            <Movimientos
                                                movimientos={saldo.movimientos}
                                                monedaSaldo={saldo.monedaDeuda}
                                                verDoc={verDoc}
                                            />
                                        </>
                                    )}
                                </Flex>
                            </CardSection>
                        </Card>
                    );
                })}
            </Box>
        </Flex>
    );
};

const ColSaldo: FC<{ saldo: ICCSaldo; docColor: string; onClick: () => void }> = ({ docColor, saldo, onClick }) => {
    const { saldoDeuda, monedaDeuda } = saldo;
    const signo = saldoDeuda < 0 ? "-" : "";
    const saldoDeudaAbs = Math.abs(saldoDeuda);
    const importeTexto = !monedaDeuda ? "-" : `${signo} ${monedaDeuda} ${formatImporte(saldoDeudaAbs)}`;

    return (
        <Flex
            onClick={onClick}
            sx={{
                color: docColor,
                ml: "auto",
                "&:hover": {
                    cursor: "pointer",
                    textDecoration: "underline"
                }
            }}
        >
            <Text ml={"auto"}>{importeTexto}</Text>
            <Box>
                <ChevronDown size={"small"} />
            </Box>
        </Flex>
    );
};

const Movimientos: FC<{
    movimientos: Array<ICCMovimiento>;
    monedaSaldo: string;
    verDoc: (verDocArgs: VerDocArgs) => void;
}> = ({ movimientos, monedaSaldo, verDoc }) => {
    return (
        <Flex
            className={"animated fadeIn"}
            sx={{ flexDirection: "column", ml: "auto", mt: 1, mr: "1em", width: "27em" }}
        >
            <Text
                sx={{
                    color: "titulo",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    textAlign: "left",
                    ml: "2.75em"
                }}
            >
                Movimientos
            </Text>

            <Flex flexDirection={"column"}>
                <Timeline>
                    {movimientos.map(movimiento => {
                        const { id, fecha, tipoMovimiento, factura, recibo, aplicacion, ajuste, importe } = movimiento;
                        let tipoDoc = "";
                        let descDoc = "";
                        if (factura) {
                            tipoDoc = factura.tipoDocumento;
                            descDoc = factura.nroFactura;
                        } else if (recibo) {
                            tipoDoc = "Recibo";
                            descDoc = recibo.nroRecibo.toString();
                        } else if (aplicacion) {
                            tipoDoc = "Aplicación";
                            descDoc = aplicacion.id.toString();
                        } else if (ajuste) {
                            tipoDoc = "Ajuste";
                            descDoc = ajuste.id.toString();
                        }

                        const Documento = () => {
                            if (recibo) {
                                return (
                                    <Box>
                                        <ReciboButton
                                            nroRecibo={recibo.nroRecibo}
                                            nroReciboProvisorio={recibo.nroReciboProvisorio}
                                            onClick={() => verDoc({ idRecibo: recibo.id })}
                                        />
                                    </Box>
                                );
                            }

                            if (factura) {
                                return (
                                    <DocButton
                                        color={"documentosFacturas"}
                                        tipoDocumento={factura.tipoDocumento.toUpperCase()}
                                        nroDocumento={factura.nroFactura}
                                        onClick={() => verDoc({ idFactura: factura.id })}
                                    />
                                );
                            }

                            if (ajuste) {
                                return (
                                    <DocButton
                                        color={"documentosAjustes"}
                                        tipoDocumento={"AJUSTE"}
                                        nroDocumento={`Nro. ${ajuste.id}`}
                                        onClick={() => {}}
                                    />
                                );
                            }

                            if (aplicacion) {
                                return (
                                    <DocButton
                                        color={"documentosAplicaciones"}
                                        tipoDocumento={"APLICACIÓN"}
                                        nroDocumento={`Nro. ${aplicacion.id}`}
                                        onClick={() => verDoc({ idAplicacion: aplicacion.id })}
                                    />
                                );
                            }

                            return (
                                <Flex>
                                    <Text>{tipoDoc}</Text>
                                    <Text
                                        sx={{
                                            flexDirection: "column",
                                            fontWeight: 600,
                                            fontSize: "0.9em",
                                            "&:hover": {
                                                color: "tableLinkColor",
                                                cursor: "pointer",
                                                textDecoration: "underline"
                                            }
                                        }}
                                    >
                                        {descDoc}
                                    </Text>
                                </Flex>
                            );
                        };

                        const importeColor =
                            importe < 0
                                ? theme.colors.cuentaCorrienteSaldoNegativoColor
                                : theme.colors.cuentaCorrienteSaldoPositivoColor;

                        const getBubbleColor = () => {
                            if (factura) {
                                return theme.colors.documentosFacturas;
                            }

                            if (recibo) {
                                return theme.colors.documentosRecibos;
                            }

                            if (ajuste) {
                                return theme.colors.documentosAjustes;
                            }

                            if (aplicacion) {
                                return theme.colors.documentosAplicaciones;
                            }
                        };

                        const signo = importe < 0 ? "-" : "+";
                        const importeAbsoluto = Math.abs(importe);
                        const importeTexto = !monedaSaldo
                            ? "-"
                            : `${signo} ${monedaSaldo} ${formatImporte(importeAbsoluto)}`;

                        return (
                            <TimelineEvent
                                bubbleStyle={{ borderColor: getBubbleColor() }}
                                title={""}
                                createdAt={""}
                                icon={null}
                                key={id}
                            >
                                <Flex flexDirection={"column"}>
                                    <Text ml={2} color={"titulo"}>
                                        {fecha} - {tipoMovimiento}
                                    </Text>
                                    <Flex alignItems={"center"}>
                                        <Documento />

                                        <Box ml={"auto"}>
                                            <Text color={importeColor} fontSize={"1.2em"}>
                                                {importeTexto}
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Flex>
                            </TimelineEvent>
                        );
                    })}
                </Timeline>
            </Flex>
        </Flex>
    );
};

const getDocColor = (doc: CCDoc): string => {
    switch (doc.tipo) {
        case CCTiposDocs.AJUSTE:
            return "documentosAjustes";

        case CCTiposDocs.FACTURA:
            return "documentosFacturas";

        case CCTiposDocs.RECIBO:
            return "documentosRecibos";

        default:
            return "";
    }
};

const ColDocumento: FC<{ doc: CCDoc; verDoc: (verDocArgs: VerDocArgs) => void }> = ({ doc, verDoc }) => {
    if (doc.tipo === CCTiposDocs.FACTURA) {
        const { id, claseDocumento, nroFactura } = doc.factura;
        return (
            <DocButton
                color={"documentosFacturas"}
                tipoDocumento={claseDocumento.toUpperCase()}
                nroDocumento={nroFactura}
                onClick={() => verDoc({ idFactura: id })}
            />
        );
    }

    if (doc.tipo === CCTiposDocs.RECIBO) {
        return <ReciboButton {...doc.recibo} onClick={() => verDoc({ idRecibo: doc.recibo.id })} />;
    }

    if (doc.tipo === CCTiposDocs.AJUSTE) {
        const { id, motivoAjuste } = doc.ajuste;
        return (
            <DocButton
                color={"documentosFacturas"}
                tipoDocumento={"AJUSTE"}
                nroDocumento={`Nro. ${formatImporte(id)}`}
                descripcion={`Motivo: ${motivoAjuste}`}
            />
        );
    }

    return <></>;
};

const ReciboButton: FC<{ nroRecibo: number; nroReciboProvisorio; onClick: () => void }> = ({
    nroRecibo,
    nroReciboProvisorio,
    onClick
}) => {
    const descripcion = nroReciboProvisorio ? `Provisorio: ${nroReciboProvisorio}` : "";
    return (
        <DocButton
            color={"documentosRecibos"}
            tipoDocumento={"RECIBO"}
            nroDocumento={`Nro. ${nroRecibo}`}
            descripcion={descripcion}
            onClick={onClick}
        />
    );
};

const DocButton: FC<{
    color: string;
    tipoDocumento: string;
    nroDocumento: string;
    descripcion?: string;
    onClick?: () => void;
}> = ({ color, tipoDocumento, nroDocumento, descripcion, onClick }) => {
    const sx = {
        alignItems: "center",
        py: 2,
        px: 3
    };

    if (onClick) {
        sx["&:hover"] = {
            borderRadius: "4px",
            bg: "#f5f7f9",
            cursor: "pointer"
        };
    }

    return (
        <Flex sx={sx} onClick={onClick}>
            <Box color={color}>
                <File size={28} />
            </Box>
            <Flex color={color} flexDirection={"column"} ml={1}>
                <Text fontWeight={600}>{tipoDocumento}</Text>
                <Text>{nroDocumento}</Text>
                {descripcion && (
                    <Text color={"datagridLightText"} fontSize={"0.8em"}>
                        {descripcion}
                    </Text>
                )}
            </Flex>
        </Flex>
    );
};

const formatFechaDoc = (fecha: Date): string => formatFecha(fecha, "dd MMMM yyyy");

const FechaDocumento: FC<{ doc: CCDoc }> = ({ doc }) => {
    const color = getDocColor(doc);

    if (doc.tipo === CCTiposDocs.FACTURA) {
        const fechaFactura = parse(doc.factura.fechaFactura, "dd/MM/yyyy", new Date());
        const fechaVencimiento = parse(doc.factura.fechaVencimiento, "dd/MM/yyyy", new Date());

        return (
            <Flex flexDirection={"column"}>
                <Text color={"datagridLightText"} fontSize={"0.8em"}>
                    Fecha Factura / Vencimiento
                </Text>
                <Text color={color}>{formatFechaDoc(fechaFactura)}</Text>
                <Text color={"#EB5757"}>{formatFechaDoc(fechaVencimiento)}</Text>
            </Flex>
        );
    }

    if (doc.tipo === CCTiposDocs.RECIBO) {
        const { recibo } = doc;
        const fechaRecibo = parse(recibo.fechaRecibo, "dd/MM/yyyy", new Date());
        const fechaReciboProvisorio = recibo.fechaReciboProvisorio
            ? parse(recibo.fechaReciboProvisorio, "dd/MM/yyyy", new Date())
            : null;

        return (
            <Flex flexDirection={"column"}>
                <Text color={color}>{formatFechaDoc(fechaRecibo)}</Text>
                {fechaReciboProvisorio && fechaRecibo !== fechaReciboProvisorio && (
                    <Text color={"datagridLightText"} fontSize={"0.8em"}>
                        Provisorio: {formatFecha(fechaReciboProvisorio, "dd MMMM")}
                    </Text>
                )}
            </Flex>
        );
    }

    if (doc.tipo === CCTiposDocs.AJUSTE) {
        const fechaAjuste = parse(doc.ajuste.fechaAjuste, "dd/MM/yyyy", new Date());

        return (
            <Flex flexDirection={"column"}>
                <Text color={color}>{formatFechaDoc(fechaAjuste)}</Text>
            </Flex>
        );
    }

    return <></>;
};

const Remito: FC<{ remito: ICCRemito; onClick: () => void }> = ({ remito, onClick }) => {
    return (
        <Flex
            sx={{
                alignItems: "center",
                color: "documentosRemitos",
                flexDirection: "column",
                fontSize: "0.9em",
                p: 2,
                "&:hover": {
                    borderRadius: "4px",
                    bg: "#f5f7f9",
                    cursor: "pointer"
                }
            }}
            onClick={onClick}
        >
            <Box>
                <File size={28} />
            </Box>
            <Flex alignItems={"center"} flexDirection={"column"} mt={1}>
                <Text color={"datagridLightText"} fontSize={"0.8em"}>
                    Remito:
                </Text>
                <Text>{remito.nroRemito}</Text>
            </Flex>
        </Flex>
    );
};

const Pedido: FC<{ pedido: ICCPedido; onClick: () => void }> = ({ pedido, onClick }) => {
    return (
        <Flex
            onClick={onClick}
            sx={{
                alignItems: "center",
                color: "cuentaCorrientePedidosColor",
                flexDirection: "column",
                fontSize: "0.9em",
                p: 2,
                "&:hover": {
                    borderRadius: "4px",
                    bg: "#f5f7f9",
                    cursor: "pointer"
                }
            }}
        >
            <Box mr={1}>
                <ShoppingCart size={28} />
            </Box>
            <Flex alignItems={"center"} flexDirection={"column"} mt={1}>
                <Text color={"datagridLightText"} fontSize={"0.8em"}>
                    Pedido:
                </Text>
                <Text>{pedido.nroPedido}</Text>
                {pedido.nroPedidoAnticipo && <Text>Anticipo: {pedido.nroPedido}</Text>}
            </Flex>
        </Flex>
    );
};
