export interface ICantidades {
    cantPaquetes?: number;
    unidades: number;
    kilos: number;
    metros: number;
}

export interface Articulo {
    codigo: string;
    descripcion: string;
}

export interface ListRequestData<FiltrosType = {}> {
    filtros?: FiltrosType;
    busqueda?: string;
    ordenarPor?: string;
    paginador?: {
        nroPagina: number;
        cantPorPagina: number;
    };
}

export interface ListResponseOutputBase<Data, Totales = {}> {
    cantResultados: number;
    data: Array<Data>;
    totales?: Totales;
}

export interface IDomicilio {
    direccion: string;
    localidad: string;
    provincia: string;
    codigoPostal?: string;
    pais?: string;
}

export enum CodsEstadosPedidos {
    EN_REVISION = "EN_REVISION",
    APROBADO = "APROBADO",
    CERRADO = "CERRADO"
}

export interface ILugarEntrega {
    id: number;
    descripcion: string;
    domicilio: IDomicilio;
}
