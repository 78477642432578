import React, { FC } from "react";
import { Box, Flex } from "rebass/styled-components";
import { Icon } from "react-feather";
import { AppLayoutSidebar as Siderbar } from "./AppLayoutSidebar";
import { AppLayoutNavbar as Navbar } from "./AppLayoutNavbar";
import { TituloRuta } from "../../app/rutas";

export interface ItemMenu {
    titulo: string;
    ruta: string;
    Icono: Icon;
    isActive?: boolean;
}

export interface AppLayoutProps {
    nombreCliente: string;
    nombreUsuario: string;
    logout: LogoutCallback;
    itemsMenu: Array<ItemMenu>;
    tituloRuta?: TituloRuta;
}

export type LogoutCallback = () => void;

export const AppLayout: FC<AppLayoutProps> = props => {
    const { children, itemsMenu, logout, nombreCliente, nombreUsuario } = props;
    return (
        <Flex sx={{ height: "100vh" }} flexDirection={"row"}>
            <Siderbar items={itemsMenu} width={[0, 1 / 8, 1 / 10]} />
            <Box bg={"appLayoutBg"} width={[1, 7 / 8, 9 / 10]}>
                <Flex
                    sx={{
                        height: "100vh",
                        overflowY: "auto"
                    }}
                    flexDirection={"column"}
                >
                    <Navbar
                        logout={logout}
                        nombreCliente={nombreCliente}
                        nombreUsuario={nombreUsuario}
                        tituloRuta={props.tituloRuta}
                    />
                    {children}
                </Flex>
            </Box>
        </Flex>
    );
};
