import React, { FC } from "react";
import { AplicacionDoc } from "./AplicacionDoc";
import Modal, { ModalHeader, ModalSection } from "@kiwicom/orbit-components/lib/Modal";
import { Portal } from "@kiwicom/orbit-components";

export const AplicacionDocModal: FC<{ idAplicacion: number; onClose: () => void }> = ({ idAplicacion, onClose }) => {
    return (
        <Portal>
            <Modal fixedFooter={false} isMobileFullPage={true} onClose={onClose} size={"normal"}>
                <ModalHeader title={null} suppressed={false} />
                <ModalSection suppressed={false}>
                    <AplicacionDoc idAplicacion={idAplicacion} />
                </ModalSection>
            </Modal>
        </Portal>
    );
};
