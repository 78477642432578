import { makeHttpRequest, POST, ResultBase } from "./api";

export enum TiposFiltros {
    GRUPO,
    SUB_GRUPO,
    MEDIDA
}

export type TipoFetch =
    | { tipo: TiposFiltros.GRUPO }
    | { tipo: TiposFiltros.SUB_GRUPO; idGrupo: number }
    | { tipo: TiposFiltros.MEDIDA; idSubGrupo: number };

interface Request {
    idGrupo?: number;
    idSubGrupo?: number;
}

enum ErrorCodes {}

interface ResponseOutput {
    data: Array<{ id: number; descripcion: string }>;
}

export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

const getRequestData = (tipoFetch: TipoFetch): Request => {
    switch (tipoFetch.tipo) {
        case TiposFiltros.GRUPO:
            return {};
        case TiposFiltros.SUB_GRUPO:
            return { idGrupo: tipoFetch.idGrupo };
        case TiposFiltros.MEDIDA:
            return { idSubGrupo: tipoFetch.idSubGrupo };
        default:
            return {};
    }
};

export const obtenerFiltros = async (tipoFetch: TipoFetch): Promise<Result> =>
    makeHttpRequest<Request, ResponseOutput, ErrorCodes>(
        POST,
        "nuevo-pedido-articulos-filtros",
        getRequestData(tipoFetch)
    );
