import React, { FC, useEffect, useMemo, useState } from "react";
import { Box, Flex } from "rebass/styled-components";
import { Alert, Loading, Separator } from "@kiwicom/orbit-components";
import { obtenerPedidos, PedidoData, PedidosListFiltrosType } from "../api/PedidosListApi";
import { useDebounce } from "use-debounce";
import { Paginador } from "../../ui_components/Paginador";
import { FetchStatus, useListFetcher } from "../../lib/useListFetcher";
import { PedidosListFiltros } from "./PedidosListFiltros";
import { IFiltroActivo } from "../../ui_components/ListView/FiltrosActivos";
import { TopBar } from "../../ui_components/ListView/TopBar";
import { getFiltrosActivos } from "./getFiltrosActivos";
import { rutas } from "../rutas";
import { PedidosList } from "./PedidosList";
import { getAuthToken } from "../LocalStorage";
import { descargasURLBase } from "../api/api";

export const PedidosListPage: FC<any> = () => {
    const [fetcherState, { buscar, cambiarNroPagina, filtrar }] = useListFetcher<PedidoData, PedidosListFiltrosType>(
        obtenerPedidos
    );
    const [busqueda, setBusqueda] = useState("");
    const [busquedaDebounced] = useDebounce(busqueda, 1500);
    const [verFiltros, setVerFiltros] = useState(false);
    const authToken = getAuthToken();
    const excelURLBase = `${descargasURLBase}/pedidos-list-excel?token=${authToken}`;

    const pedidosListComponent = useMemo(
        () => <PedidosList pedidos={fetcherState.data && fetcherState.data.length > 0 ? fetcherState.data : []} />,
        [fetcherState.data]
    );

    const filtrosActivos = useMemo<Array<IFiltroActivo>>(() => getFiltrosActivos(fetcherState.filtros, filtrar), [
        fetcherState.filtros,
        filtrar
    ]);

    useEffect(() => {
        buscar(busquedaDebounced);
    }, [buscar, busquedaDebounced]);

    const filtrarCallback = (filtros: PedidosListFiltrosType) => {
        filtrar(filtros);
        setVerFiltros(false);
    };

    return (
        <Flex
            sx={{
                flexDirection: "column",
                flexGrow: 1,
                minWidth: "63em",
                mx: "auto",
                my: 3 /*,
                "> *": {
                    flexShrink: 0
                }*/
            }}
        >
            {verFiltros && (
                <PedidosListFiltros
                    cerrarCallback={() => setVerFiltros(false)}
                    filtros={fetcherState.filtros}
                    filtrarCallback={filtrarCallback}
                />
            )}
            {fetcherState.error && (
                <Box my={3}>
                    <Alert type="critical" title={null} icon>
                        {fetcherState.error}
                    </Alert>
                </Box>
            )}
            <Box sx={{ flexShrink: 0 }}>
                <TopBar
                    busqueda={busqueda}
                    crearLinkTo={rutas.nuevoPedido}
                    exportarExcelProps={{
                        urlBase: excelURLBase,
                        cantMaxRegistros: 500,
                        nombreObjeto: "pedidos",
                        fetcherState: {
                            busqueda: fetcherState.busqueda,
                            filtros: fetcherState.filtros,
                            paginador: fetcherState.paginador
                        }
                    }}
                    filtrosActivos={filtrosActivos}
                    mostrarFiltros={() => setVerFiltros(true)}
                    textoCrear={"Nuevo Pedido"}
                    setBusqueda={busqueda => setBusqueda(busqueda)}
                    verCrear={true}
                />
                <Box my={3}>
                    <Separator />
                </Box>
            </Box>
            <Box>
                {fetcherState.status === FetchStatus.NOT_FETCHED && <></>}
                {fetcherState.status === FetchStatus.FETCHING && (
                    <Loading loading={fetcherState.isLoading} type="boxLoader" text="Cargando Pedidos..." />
                )}
                {fetcherState.status === FetchStatus.SUCCESS && pedidosListComponent}
            </Box>
            <Paginador
                nroPagina={fetcherState.paginador.nroPagina}
                cantPorPagina={fetcherState.paginador.cantPorPagina}
                cantResultados={fetcherState.paginador.cantResultados}
                cambiarPaginaCallback={nroPagina => {
                    cambiarNroPagina(nroPagina);
                }}
                mt={3}
            />
        </Flex>
    );
};
