import { ResultBase, makeHttpRequest, POST } from "./api";

export enum ErrorCodes {}

export interface RequestData {
    idUsuario: number;
}

export interface ResponseData {}
export interface Response extends ResultBase<ResponseData, ErrorCodes> {}

export const activarUsuario = async (reqData: RequestData): Promise<Response> =>
    makeHttpRequest<{}, ResponseData, ErrorCodes>(POST, "usuarios-activar-usuario", reqData);
