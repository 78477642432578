import { ResultBase, makeHttpRequest, POST } from "./api";

export enum ErrorCodes {}

export interface IResumenCarritoData {
    cantPaquetes: number;
    cantArticulos: number;
    totalKilos: number;
    moneda: string;
    importeTotal: number;
}

interface ResponseOutput {
    resumenCarrito: IResumenCarritoData | null;
}

export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const obtenerResumenCarrito = async (): Promise<Result> =>
    makeHttpRequest<{}, ResponseOutput, ErrorCodes>(POST, "home-resumen-carrito", {});
