import React, { FC } from "react";
import { Flex } from "rebass/styled-components";
import { CertificadosList } from "./CertificadosList";

export const CertificadosListPage: FC = () => {
    return (
        <Flex
            sx={{
                flexDirection: "column",
                flexGrow: 1,
                minWidth: "55em",
                mx: "auto",
                my: 3
            }}
        >
            <CertificadosList />
        </Flex>
    );
};
