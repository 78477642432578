import { ResultBase, makeHttpRequest, POST } from "./api";

export interface Request {
    email: string;
}

export enum ErrorCodes {
    EMAIL_NO_REGISTRADO = "EMAIL_NO_REGISTRADO",
    USUARIO_INACTIVO = "USUARIO_INACTIVO"
}

export interface ResponseData {
    token: string;
}

export interface Response extends ResultBase<ResponseData, ErrorCodes> {}

export const enviarEmailRestablecerPassword = async (req: Request): Promise<Response> =>
    makeHttpRequest<Request, ResponseData, ErrorCodes>(POST, "enviar-email-restablecer-password", req);
