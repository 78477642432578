import React, { FC, useMemo, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { IRemitosListData } from "../api/RemitosListApi";
import Table, { TableBody, TableCell, TableHead, TableRow } from "@kiwicom/orbit-components/lib/Table";
import { formatCant } from "../../lib/format";
import { Package as PackageIcon } from "react-feather";
import { RemitoDocModal } from "./RemitoDocModal";

export const RemitosListTable: FC<{ remitos: Array<IRemitosListData> }> = ({ remitos }) => {
    const [verIdRemito, setVerIdRemito] = useState<number | null>(null);

    const remitosTableComponent = useMemo(
        () => (
            <Table compact={true}>
                <TableHead>
                    <TableRow>
                        <TableCell align={"left"}>Nro Remito</TableCell>
                        <TableCell align={"left"}>Fecha Remito</TableCell>
                        <TableCell align={"left"}>Estado</TableCell>
                        <TableCell align={"left"}>Lugar de Entrega</TableCell>
                        <TableCell align={"left"}>Total Despachado</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {remitos.map(remito => {
                        const { id, nroRemito, fechaRemito, estado, lugarEntrega, totalDespachado } = remito;

                        return (
                            <TableRow key={id}>
                                <TableCell align={"left"}>
                                    <Box
                                        onClick={() => {
                                            setVerIdRemito(remito.id);
                                        }}
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: "0.9em",
                                            "&:hover": {
                                                color: "tableLinkColor",
                                                cursor: "pointer",
                                                textDecoration: "underline"
                                            }
                                        }}
                                    >
                                        {nroRemito}
                                    </Box>
                                </TableCell>
                                <TableCell align={"left"}>{fechaRemito}</TableCell>
                                <TableCell align={"left"}>{estado}</TableCell>
                                <TableCell align={"left"}>
                                    <Flex flexDirection={"column"}>
                                        <Text>{lugarEntrega.descripcion}</Text>
                                        <Text>{lugarEntrega.domicilio.direccion}</Text>
                                        <Text>{lugarEntrega.domicilio.localidad}</Text>
                                    </Flex>
                                </TableCell>
                                <TableCell align={"left"}>
                                    <Flex flexDirection={"column"}>
                                        {totalDespachado.cantPaquetes && totalDespachado.cantPaquetes > 0 && (
                                            <Flex mb={2}>
                                                <PackageIcon size={16} />
                                                <Text fontWeight={600} ml={1}>
                                                    {totalDespachado.cantPaquetes}
                                                </Text>
                                                <Text ml={1}>
                                                    {" "}
                                                    {totalDespachado.cantPaquetes === 1 ? "paquete" : "paquetes"}
                                                </Text>
                                            </Flex>
                                        )}

                                        <Flex>
                                            {totalDespachado.unidades > 0 && (
                                                <Flex>
                                                    <Text fontWeight={600}>{formatCant(totalDespachado.unidades)}</Text>
                                                    <Text>uni.</Text>
                                                </Flex>
                                            )}

                                            {totalDespachado.kilos > 0 && (
                                                <Flex ml={1}>
                                                    <Text fontWeight={600}>{formatCant(totalDespachado.kilos)}</Text>
                                                    <Text>kgs.</Text>
                                                </Flex>
                                            )}

                                            {totalDespachado.metros > 0 && (
                                                <Flex ml={1}>
                                                    <Text fontWeight={600}>{formatCant(totalDespachado.metros)}</Text>
                                                    <Text>mts.</Text>
                                                </Flex>
                                            )}
                                        </Flex>
                                    </Flex>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        ),
        [remitos]
    );
    return (
        <>
            {verIdRemito && <RemitoDocModal idRemito={verIdRemito} onClose={() => setVerIdRemito(null)} />}
            {remitosTableComponent}
        </>
    );
};
