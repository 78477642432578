import React, { FC, useMemo, useState } from "react";
import { Box, Flex } from "rebass/styled-components";
import Table, { TableBody, TableCell, TableHead, TableRow } from "@kiwicom/orbit-components/lib/Table";
import { formatImporte } from "../../lib/format";
import { FacturaDocModal } from "./FacturaDocModal";
import { RemitoDocModal } from "../remitos/RemitoDocModal";
import { IFacturasListData } from "../api/FacturasListApi";

interface Props {
    facturas: Array<IFacturasListData>;
}

export const FacturasListTable: FC<Props> = ({ facturas }) => {
    const [verIdFactura, setVerIdFactura] = useState<number | null>(null);
    const [verIdRemito, setVerIdRemito] = useState<number | null>(null);

    const facturasListTableComponent = useMemo(
        () => (
            <Table compact={true}>
                <TableHead>
                    <TableRow>
                        <TableCell align={"left"}>Factura</TableCell>
                        <TableCell align={"left"}>Remito</TableCell>
                        <TableCell align={"left"}>Fecha Factura</TableCell>
                        <TableCell align={"left"}>Fecha Vencimiento</TableCell>
                        <TableCell align={"right"}>Total Factura</TableCell>
                        <TableCell align={"right"}>Total Saldo Factura</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {facturas.map(factura => {
                        const {
                            id,
                            tipoDocumento,
                            nroFactura,
                            nroRemito,
                            idRemito,
                            fecha,
                            fechaVencimiento,
                            monedaFactura,
                            totalFactura,
                            monedaSaldo,
                            saldo
                        } = factura;
                        return (
                            <TableRow key={id}>
                                <TableCell align={"left"}>
                                    <Flex flexDirection={"column"}>
                                        {tipoDocumento}
                                        <Box
                                            onClick={() => {
                                                setVerIdFactura(factura.id);
                                            }}
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: "0.9em",
                                                "&:hover": {
                                                    color: "tableLinkColor",
                                                    cursor: "pointer",
                                                    textDecoration: "underline"
                                                }
                                            }}
                                        >
                                            {nroFactura}
                                        </Box>
                                    </Flex>
                                </TableCell>
                                <TableCell align={"left"}>
                                    {nroRemito && idRemito && (
                                        <Box
                                            onClick={() => {
                                                setVerIdRemito(idRemito);
                                            }}
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: "0.9em",
                                                "&:hover": {
                                                    color: "tableLinkColor",
                                                    cursor: "pointer",
                                                    textDecoration: "underline"
                                                }
                                            }}
                                        >
                                            {nroRemito}
                                        </Box>
                                    )}
                                </TableCell>
                                <TableCell align={"left"}>{fecha}</TableCell>
                                <TableCell align={"left"}>{fechaVencimiento}</TableCell>
                                <TableCell align={"right"}>
                                    {monedaFactura} {formatImporte(totalFactura)}
                                </TableCell>
                                <TableCell align={"right"}>
                                    {monedaSaldo} {formatImporte(saldo)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        ),
        [facturas]
    );

    return (
        <>
            {verIdFactura && <FacturaDocModal idFactura={verIdFactura} onClose={() => setVerIdFactura(null)} />}
            {verIdRemito && <RemitoDocModal idRemito={verIdRemito} onClose={() => setVerIdRemito(null)} />}
            {facturasListTableComponent}
        </>
    );
};
