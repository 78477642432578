import React, { FC, Reducer, useEffect, useMemo, useReducer } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { Alert, Loading } from "@kiwicom/orbit-components";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import DatePicker from "react-datepicker";
import { parse } from "date-fns";
import {
    EstadosVencimiento,
    IFacturaVencFecha,
    IMonedaImporte,
    ITotales,
    obtenerVencimientoFacturas,
    VencimientosFacturasData
} from "../api/HomeVencimientosFacturasApi";
import { formatFecha, formatImporte } from "../../lib/format";
import { Link } from "react-router-dom";
import { rutas } from "../rutas";
import styled from "styled-components";
import { FacturasList } from "../facturas/FacturasList";

interface State {
    cargando: boolean;
    msgError?: string | null;
    data?: VencimientosFacturasData;
    tituloFacturasList?: string;
    idsFacturasParaMostrar?: number[];
}

enum ActionsTypes {
    FETCH_SUCCESS,
    FETCH_ERROR,
    MOSTRAR_FACTURAS_POR_FECHA_VTO,
    MOSTRAR_FACTURAS_POR_ESTADO
}

type Action =
    | { type: ActionsTypes.FETCH_SUCCESS; data: VencimientosFacturasData }
    | { type: ActionsTypes.FETCH_ERROR; msgError: string }
    | { type: ActionsTypes.MOSTRAR_FACTURAS_POR_FECHA_VTO; fechaVencimiento: Date }
    | { type: ActionsTypes.MOSTRAR_FACTURAS_POR_ESTADO; estadoVencimiento: EstadosVencimiento };

const reducer = (state: State, action: Action): State => {
    const newState = Object.assign({}, state);

    switch (action.type) {
        case ActionsTypes.FETCH_SUCCESS:
            newState.cargando = false;
            newState.msgError = null;
            newState.data = action.data;
            return newState;

        case ActionsTypes.FETCH_ERROR:
            newState.cargando = false;
            newState.msgError = action.msgError;
            return newState;

        case ActionsTypes.MOSTRAR_FACTURAS_POR_FECHA_VTO:
            if (!state.data || state.data.facturasConVencimientos.length === 0) {
                return state;
            }
            const facturasPorFecha = state.data.facturasConVencimientos.filter(factura => {
                const fechaVencimiento = parse(factura.fechaVencimiento, "dd/MM/yyyy", new Date());
                return fechaVencimiento.getTime() === action.fechaVencimiento.getTime();
            });

            const fechaFormatted = formatFecha(action.fechaVencimiento);
            newState.tituloFacturasList =
                facturasPorFecha.length > 0
                    ? `Facturas con Vencimiento ${fechaFormatted}`
                    : `No hay facturas que venzan el día ${fechaFormatted}`;
            newState.idsFacturasParaMostrar = facturasPorFecha.map(facturaVencFecha => facturaVencFecha.idFactura);
            return newState;

        case ActionsTypes.MOSTRAR_FACTURAS_POR_ESTADO:
            if (!state.data || state.data.facturasConVencimientos.length === 0) {
                return state;
            }
            const facturasPorEstadoVto = state.data.facturasConVencimientos.filter(factura => {
                return factura.estadoVencimiento === action.estadoVencimiento;
            });

            let descEstado = "";
            if (action.estadoVencimiento === EstadosVencimiento.VENCIDA) {
                descEstado = "Vencidas";
            } else if (action.estadoVencimiento === EstadosVencimiento.PROX_VENCER) {
                descEstado = "Próximas a Vencer";
            } else if (action.estadoVencimiento === EstadosVencimiento.SIN_VENCER) {
                descEstado = "Sin Vencer";
            }
            newState.tituloFacturasList =
                facturasPorEstadoVto.length > 0 ? `Facturas ${descEstado}` : `No hay facturas ${descEstado}`;
            newState.idsFacturasParaMostrar = facturasPorEstadoVto.map(facturaVencFecha => facturaVencFecha.idFactura);
            return newState;
    }
};

const initialState: State = {
    cargando: true
};

export const VencimientosFacturas: FC = () => {
    const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, initialState);

    useEffect(() => {
        const fetch = async () => {
            const resp = await obtenerVencimientoFacturas();
            if (resp.ok && resp.output) {
                dispatch({ type: ActionsTypes.FETCH_SUCCESS, data: resp.output });
                return;
            }

            dispatch({
                type: ActionsTypes.FETCH_ERROR,
                msgError: "Hubo un error al obtener el vencimiento de las facturas"
            });
        };

        fetch();
    }, []);

    const { cargando, msgError, data, tituloFacturasList, idsFacturasParaMostrar } = state;

    if (cargando) {
        return <Loading loading={true} type="boxLoader" text="Cargando..." />;
    }

    if (msgError) {
        return (
            <Box mb={4} mx={"auto"} my={3} width={"40em"}>
                <Alert type="critical" title={null} icon={true}>
                    {msgError}
                </Alert>
            </Box>
        );
    }

    const mostrarFacturasPorEstado = (estado: EstadosVencimiento) =>
        dispatch({ type: ActionsTypes.MOSTRAR_FACTURAS_POR_ESTADO, estadoVencimiento: estado });

    if (!data) {
        return <></>;
    }

    return (
        <Flex flexDirection={"column"}>
            <Flex>
                <CalendarioVencimientos
                    facturas={data.facturasConVencimientos}
                    onDayClick={(fecha: Date) => {
                        dispatch({ type: ActionsTypes.MOSTRAR_FACTURAS_POR_FECHA_VTO, fechaVencimiento: fecha });
                    }}
                />

                <Flex flexDirection={"column"} ml={"auto"}>
                    <GraficoVencimientosFacturas onClickEstado={mostrarFacturasPorEstado} totales={data.totales} />
                    <TotalesCtaCte onTituloClick={mostrarFacturasPorEstado} totales={data.totales} />
                </Flex>
            </Flex>

            {idsFacturasParaMostrar && tituloFacturasList && (
                <Box className={"animated fadeIn"} mt={2}>
                    <Flex sx={{ flexDirection: "column", mt: 1 }}>
                        <Text
                            sx={{
                                color: "titulo",
                                fontSize: "1.2em",
                                fontWeight: 600,
                                textAlign: "left",
                                mb: 1
                            }}
                        >
                            {tituloFacturasList}
                        </Text>
                        {idsFacturasParaMostrar.length > 0 && (
                            <FacturasList
                                filtros={{ idsFacturas: idsFacturasParaMostrar }}
                                cantPorPagina={10}
                                ocultarTopBar={true}
                            />
                        )}
                    </Flex>
                </Box>
            )}
        </Flex>
    );
};

interface CalendarioProps {
    facturas: Array<IFacturaVencFecha>;
    onDayClick: (fecha: Date) => void;
}

enum CalendarioClassNames {
    VENCIDAS = "vencidas",
    PROX_VENCER = "prox-vencer",
    SIN_VENCER = "sin-vencer"
}
const CalendarioVencimientos: FC<CalendarioProps> = ({ onDayClick, facturas }) => {
    const classNames = useMemo(() => {
        const classNames: Array<string> = [];

        facturas.forEach(factura => {
            const fechaVencimiento = parse(factura.fechaVencimiento, "dd/MM/yyyy", new Date());
            const key = fechaVencimiento.getTime();
            if (factura.estadoVencimiento === EstadosVencimiento.VENCIDA) {
                classNames[key] = CalendarioClassNames.VENCIDAS;
            } else if (
                factura.estadoVencimiento === EstadosVencimiento.PROX_VENCER &&
                classNames[key] !== CalendarioClassNames.VENCIDAS
            ) {
                classNames[key] = CalendarioClassNames.PROX_VENCER;
            } else if (factura.estadoVencimiento === EstadosVencimiento.SIN_VENCER && !classNames[key]) {
                classNames[key] = CalendarioClassNames.SIN_VENCER;
            }
        });

        return classNames;
    }, [facturas]);

    const getDayClassName = (fechaDiaCalendario: Date): string | undefined => {
        const className = classNames[fechaDiaCalendario.getTime()];
        const hayFacturas = !!className;
        if (hayFacturas) {
            return `hay-facturas ${className}`;
        }
    };

    return (
        <Box>
            <DatePicker dayClassName={getDayClassName} inline={true} onChange={onDayClick} monthsShown={2} />
        </Box>
    );
};

interface IGraficoVencimientosFacturasData {
    name: string;
    value: number;
    estado: EstadosVencimiento;
}
const GraficoVencimientosFacturas: FC<{ totales: ITotales; onClickEstado: (estado: EstadosVencimiento) => void }> = ({
    totales,
    onClickEstado
}) => {
    const data: Array<IGraficoVencimientosFacturasData> = [
        { name: "Vencidas", value: totales.vencidas.importePesos, estado: EstadosVencimiento.VENCIDA },
        { name: "Próximas a Vencer", value: totales.proxVencer.importePesos, estado: EstadosVencimiento.PROX_VENCER },
        { name: "Sin Vencer", value: totales.sinVencer.importePesos, estado: EstadosVencimiento.SIN_VENCER }
    ];
    const COLORS = ["#D21C1C", "#FF8042", "#00C49F"];

    return (
        <Flex>
            <PieChart width={350} height={160} onMouseEnter={() => {}}>
                <Legend verticalAlign={"middle"} align={"right"} layout={"vertical"} />
                <Pie
                    onClick={(data: IGraficoVencimientosFacturasData) => {
                        onClickEstado(data.estado);
                    }}
                    onMouseEnter={() => {}}
                    data={data}
                    innerRadius={45}
                    outerRadius={60}
                    fill={"#8884d8"}
                    paddingAngle={5}
                    dataKey={"value"}
                >
                    {data.map((entry, index) => (
                        <Cell cursor="pointer" key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
        </Flex>
    );
};

const StyledLink = styled(Link)`
    color: #0293cf;
    margin-top: 5px;
    font-size: 0.8em;
`;

const TotalesCtaCte: FC<{ totales: ITotales; onTituloClick: (estado: EstadosVencimiento) => void }> = ({
    totales,
    onTituloClick
}) => {
    return (
        <Flex sx={{ flexDirection: "column", fontSize: "0.9em", pl: 4, pr: 3, py: 3 }} width={1}>
            <Flex mb={2}>
                <Text>Vencido: </Text>
                <Total
                    onClick={() => {
                        onTituloClick(EstadosVencimiento.VENCIDA);
                    }}
                    totalPorMoneda={totales.vencidas.porMoneda}
                    color={"#D21C1C"}
                />
            </Flex>
            <Flex mb={2}>
                <Text>Próximo a Vencer: </Text>
                <Total
                    onClick={() => {
                        onTituloClick(EstadosVencimiento.PROX_VENCER);
                    }}
                    totalPorMoneda={totales.proxVencer.porMoneda}
                    color={"#FF8042"}
                />
            </Flex>
            <Flex mb={2}>
                <Text>Sin Vencer: </Text>
                <Total
                    onClick={() => {
                        onTituloClick(EstadosVencimiento.SIN_VENCER);
                    }}
                    totalPorMoneda={totales.sinVencer.porMoneda}
                    color={"#00C49F"}
                />
            </Flex>
            <Flex mb={2}>
                <Text>Créditos: </Text>
                <Total totalPorMoneda={totales.totalCredito} color={"#28A138"} />
            </Flex>
            <Flex
                sx={{
                    borderTop: "1px dashed",
                    fontSize: "1.2em",
                    fontWeight: 600,
                    mt: 2,
                    pt: 2
                }}
            >
                <Text>Saldo Cta Cte: </Text>
                <Total totalPorMoneda={totales.totalSaldoCC} />
            </Flex>
            <Box>
                <StyledLink to={rutas.cuentaCorriente}>(Ver Cuenta Corriente Completa)</StyledLink>
            </Box>
        </Flex>
    );
};

const Total: FC<{ totalPorMoneda: Array<IMonedaImporte>; color?: string; onClick?: () => void }> = ({
    totalPorMoneda,
    color,
    onClick
}) => {
    if (totalPorMoneda.length === 0) {
        return <Text ml={"auto"}>-</Text>;
    }

    const sx = { color, ml: "auto" };
    if (onClick) {
        sx["&:hover"] = {
            cursor: "pointer",
            textDecoration: "underline"
        };
    }

    return (
        <>
            {totalPorMoneda.map(({ moneda, importe }, index) => {
                const signo = importe < 0 ? "-" : "";
                const importeAbs = Math.abs(importe);

                return (
                    <Text onClick={onClick} sx={sx} key={index}>
                        {signo} {moneda} {formatImporte(importeAbs)}
                    </Text>
                );
            })}
        </>
    );
};
