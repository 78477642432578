import { Reducer, useCallback, useEffect, useReducer } from "react";
import { ListRequestData, ListResponseOutputBase } from "../app/types";
import { ResultBase } from "../app/api/api";

type Result<ResponseDataType, Totales = {}> = ResultBase<ListResponseOutputBase<ResponseDataType, Totales>, {}>;

export enum FetchStatus {
    NOT_FETCHED,
    FETCHING,
    ERROR,
    SUCCESS
}

interface State<Data, Filtros, Totales> {
    status: FetchStatus;
    isLoading: boolean;
    error?: string;
    data?: Array<Data>;
    filtros?: Filtros;
    ordenarPor?: string;
    busqueda: string;
    paginador: {
        nroPagina: number;
        cantPorPagina: number;
        cantResultados: number;
    };
    totales?: Totales;
}

enum ActionsTypes {
    FETCH_INIT,
    FETCH_ERROR,
    FETCH_SUCCESS,
    SET_BUSQUEDA,
    SET_NRO_PAGINA,
    SET_FILTROS,
    SET_ORDENAR_POR
}

type Action<Data, Filtros, Totales = {}> =
    | { type: ActionsTypes.FETCH_INIT }
    | { type: ActionsTypes.FETCH_ERROR; error: string }
    | { type: ActionsTypes.FETCH_SUCCESS; data: Array<Data>; cantResultados: number; totales?: Totales }
    | { type: ActionsTypes.SET_BUSQUEDA; busqueda: string }
    | { type: ActionsTypes.SET_NRO_PAGINA; nroPagina: number }
    | { type: ActionsTypes.SET_FILTROS; filtros: Filtros }
    | { type: ActionsTypes.SET_ORDENAR_POR; ordenarPor: string };

const reducer = function<Data, Filtros, Totales = {}>(
    state: State<Data, Filtros, Totales>,
    action: Action<Data, Filtros, Totales>
): State<Data, Filtros, Totales> {
    const newState = Object.assign({}, state);

    switch (action.type) {
        case ActionsTypes.FETCH_INIT:
            newState.status = FetchStatus.FETCHING;
            return newState;

        case ActionsTypes.FETCH_ERROR:
            newState.status = FetchStatus.ERROR;
            newState.error = action.error;
            return newState;

        case ActionsTypes.FETCH_SUCCESS:
            newState.status = FetchStatus.SUCCESS;
            newState.error = "";
            newState.data = action.data;
            newState.totales = action.totales;
            newState.paginador.cantResultados = action.cantResultados;
            return newState;

        case ActionsTypes.SET_BUSQUEDA:
            newState.busqueda = action.busqueda;
            newState.paginador.nroPagina = 1;
            return newState;

        case ActionsTypes.SET_FILTROS:
            newState.filtros = action.filtros;
            newState.paginador.nroPagina = 1;
            return newState;

        case ActionsTypes.SET_NRO_PAGINA:
            newState.paginador.nroPagina = action.nroPagina;
            return newState;
        case ActionsTypes.SET_ORDENAR_POR:
            newState.ordenarPor = action.ordenarPor;
            return newState;

        default:
            throw new Error();
    }
};

interface InitialStateProps<Filtros> {
    filtros?: Filtros;
    cantPorPagina?: number;
    ordenarPor?: string;
}

export const getInitialState = function<Data, Filtros, Totales = {}>(
    props?: InitialStateProps<Filtros>
): State<Data, Filtros, Totales> {
    return {
        busqueda: "",
        filtros: props && props.filtros ? props.filtros : undefined,
        status: FetchStatus.NOT_FETCHED,
        isLoading: false,
        paginador: {
            nroPagina: 1,
            cantPorPagina: props && props.cantPorPagina ? props.cantPorPagina : 15,
            cantResultados: 0
        },
        ordenarPor: props && props.ordenarPor ? props.ordenarPor : undefined
    };
};

export const useListFetcher = function<Data, Filtros, Totales = {}>(
    makeRequestFn: (req: ListRequestData<Filtros>) => Promise<Result<Data, Totales>>,
    initialState: State<Data, Filtros, Totales> = getInitialState()
): [
    State<Data, Filtros, Totales>,
    {
        buscar: (string) => void;
        cambiarNroPagina: (number) => void;
        filtrar: (Filtros) => void;
        refresh: () => void;
        ordenar: (orden: string) => void;
    }
] {
    const [state, dispatch] = useReducer<Reducer<State<Data, Filtros, Totales>, Action<Data, Filtros, Totales>>>(
        reducer,
        initialState
    );

    const {
        busqueda,
        ordenarPor,
        filtros,
        paginador: { nroPagina, cantPorPagina }
    } = state;

    const fetch = useCallback(async () => {
        dispatch({ type: ActionsTypes.FETCH_INIT });

        const reqData: ListRequestData<Filtros> = {
            busqueda,
            filtros,
            paginador: {
                nroPagina,
                cantPorPagina
            },
            ordenarPor
        };

        const result = await makeRequestFn(reqData);

        if (result.ok && result.output) {
            const { data, cantResultados, totales } = result.output;
            dispatch({ type: ActionsTypes.FETCH_SUCCESS, data, cantResultados, totales });
            return;
        }

        dispatch({ type: ActionsTypes.FETCH_ERROR, error: `Hubo un error al obtener los datos.` });
    }, [makeRequestFn, busqueda, filtros, nroPagina, cantPorPagina, ordenarPor]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const buscar = useCallback(
        (busqueda: string) => {
            dispatch({ type: ActionsTypes.SET_BUSQUEDA, busqueda });
        },
        [dispatch]
    );

    const cambiarNroPagina = useCallback(
        (nroPagina: number) => {
            dispatch({ type: ActionsTypes.SET_NRO_PAGINA, nroPagina });
        },
        [dispatch]
    );

    const filtrar = useCallback(
        (filtros: Filtros) => {
            dispatch({ type: ActionsTypes.SET_FILTROS, filtros });
        },
        [dispatch]
    );

    const refresh = useCallback(() => {
        fetch();
    }, [fetch]);

    const ordenar = useCallback(
        (ordenarPor: string) => {
            dispatch({ type: ActionsTypes.SET_ORDENAR_POR, ordenarPor });
        },
        [dispatch]
    );

    return [state, { buscar, cambiarNroPagina, filtrar, refresh, ordenar }];
};
