import React, { FC, useEffect, useRef, useState } from "react";
import { Box, Flex } from "rebass/styled-components";
import { Button, Checkbox, InputField, Portal, Separator } from "@kiwicom/orbit-components";
import Modal, { ModalHeader, ModalSection } from "@kiwicom/orbit-components/lib/Modal";
import CheckCircleIcon from "@kiwicom/orbit-components/lib/icons/CheckCircle";
import Alert from "@kiwicom/orbit-components/lib/Alert";
import EmailIcon from "@kiwicom/orbit-components/lib/icons/Email";
import { crearUsuario, ErrorCodes } from "../api/UsuariosCrearUsuarioApi";

interface Props {
    onClose: () => void;
}

export const UsuariosCrearUsuarioModal: FC<Props> = props => {
    return (
        <Portal>
            <Modal fixedFooter={false} isMobileFullPage={true} onClose={props.onClose} size={"small"}>
                <ModalHeader title={"Crear Usuario"} suppressed={true} />
                <ModalSection suppressed={false}>
                    <UsuariosCrearUsuario {...props} />
                </ModalSection>
            </Modal>
        </Portal>
    );
};

const UsuariosCrearUsuario: FC<Props> = ({ onClose }) => {
    const [msgSuccess, setMsgSuccess] = useState<string>("");
    const [msgError, setMsgError] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [admin, setAdmin] = useState<boolean>(false);
    const [guardando, setGuardando] = useState<boolean>(false);
    const inputEl = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setTimeout(() => {
            inputEl && inputEl.current && inputEl.current.select();
        }, 200);
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setGuardando(true);
        const resp = await crearUsuario({ email, admin });
        setGuardando(false);
        if (!resp.ok) {
            if (resp.errorCode === ErrorCodes.EMAIL_YA_REGISTRADO) {
                return setMsgError(`Ya existe un usuario con el email ${email}.`);
            }

            return setMsgError("Hubo un error al crear el usuario. Por favor, reintente más tarde.");
        }

        setMsgSuccess(`Se creó el usuario y se le envió un email a ${email} para que pueda activar la cuenta.`);
    };

    const cancelar = () => {
        onClose();
    };

    if (msgSuccess) {
        return (
            <Alert type="success" title={null} icon={true}>
                {msgSuccess}
            </Alert>
        );
    }

    return (
        <Flex flexDirection={"column"}>
            {msgError && (
                <Box mb={3} mx={"auto"}>
                    <Alert type="critical" title={null} icon={true}>
                        {msgError}
                    </Alert>
                </Box>
            )}

            <form onSubmit={handleSubmit}>
                <InputField
                    disabled={guardando}
                    label={null}
                    onChange={e => {
                        const email = e.target.value;
                        setEmail(email);
                    }}
                    placeholder={"Dirección de e-mail"}
                    prefix={<EmailIcon />}
                    ref={inputEl}
                    value={email}
                    maxLength={60}
                    type={"email"}
                    inputMode={"email"}
                />
                <Box mt={4}>
                    <Checkbox label={"¿Es Administrador?"} checked={admin} onChange={() => setAdmin(!admin)} />
                </Box>
                <Box my={3}>
                    <Separator />
                </Box>

                <Flex>
                    <Button disabled={guardando} iconLeft={<CheckCircleIcon />} submit={true} type={"primary"}>
                        {guardando ? "Creando..." : "Crear"}
                    </Button>
                    <Box ml={2}>
                        <Button disabled={guardando} onClick={cancelar} type={"secondary"}>
                            Cancelar
                        </Button>
                    </Box>
                </Flex>
            </form>
        </Flex>
    );
};
