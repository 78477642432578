import { makeHttpRequest, POST, ResultBase } from "./api";

export enum ErrorCodes {}

export interface VencimientosFacturasData {
    facturasConVencimientos: IFacturaVencFecha[];
    totales: ITotales;
}

export interface IFacturaVencFecha {
    idFactura: number;
    fechaVencimiento: string;
    estadoVencimiento: EstadosVencimiento;
}

export interface ITotales {
    vencidas: ITotal;
    proxVencer: ITotal;
    sinVencer: ITotal;
    totalCredito: IMonedaImporte[];
    totalSaldoCC: IMonedaImporte[];
}

export enum EstadosVencimiento {
    VENCIDA = "VENCIDA",
    PROX_VENCER = "PROX_VENCER",
    SIN_VENCER = "SIN_VENCER"
}

interface ITotal {
    importePesos: number;
    porMoneda: Array<IMonedaImporte>;
}

export interface IMonedaImporte {
    moneda: string;
    importe: number;
}

export interface Result extends ResultBase<VencimientosFacturasData, ErrorCodes> {}

export const obtenerVencimientoFacturas = async (): Promise<Result> =>
    makeHttpRequest<{}, VencimientosFacturasData, ErrorCodes>(POST, "home-vencimientos-facturas", {});
