import { ResultBase, makeHttpRequest, POST } from "./api";
import { ListRequestData, ListResponseOutputBase } from "../types";

export enum ErrorCodes {}

export interface ICertificadosListFiltros {
    fechaDesde?: Date;
    fechaHasta?: Date;
}

export interface ICertificadosListData {
    id: number;
    nroCertificado: number;
    fecha: string;
    idTipoCertificado: number;
    tipoCertificado: string;
    subGrupo: string;
    idRemito: number;
    nroRemito: string;
}

interface ResponseOutput extends ListResponseOutputBase<ICertificadosListData> {}

export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const obtenerCertificados = async (reqData: ListRequestData<ICertificadosListFiltros>): Promise<Result> =>
    makeHttpRequest<ListRequestData<ICertificadosListFiltros>, ResponseOutput, ErrorCodes>(
        POST,
        "certificados-list",
        reqData
    );
