import { IPedido, IPedidoDetalle } from "../api/CarritoApi";
import { CarritoEditarCantidadesArticuloData } from "./CarritoEditarCantidadesArticulo";

export function pedidoDetalleToEditarCantidadesHelper(
    pedidoDetalle: IPedidoDetalle,
    pedido: IPedido
): CarritoEditarCantidadesArticuloData {
    const {
        articulo: { codigo: codArticulo, descripcion, cantidadesEstandard },
        id: idPedidoDetalle,
        idListaPreciosDetalle,
        cantidades,
        cantPaquetes,
        precioListaPaquete,
        porcDescuento1,
        porcDescuento2,
        porcDescuento3,
        porcDescuento4,
        precioFinalPaquete,
        precioFinal
    } = pedidoDetalle;

    return {
        idListaPreciosDetalle,
        idPedidoDetalle,
        codArticulo,
        descripcion,
        cantidadesEstandard,
        cantPaquetes,
        cantidades,
        precioListaPaquete,
        porcDescuento1,
        porcDescuento2,
        porcDescuento3,
        porcDescuento4,
        precioFinalPaquete,
        moneda: pedido.moneda,
        importe: precioFinal
    };
}
