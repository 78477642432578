import axios from "axios";
import { getAuthToken } from "../LocalStorage";

export const GET = "get";
export const POST = "post";

export type Get = "get";
export type Post = "post";
export type Method = Get | Post;

export const apiURLBase = process.env.NODE_ENV === "development" ? "" : "api"; // Para Test: "../server/public/api_ecommerce.php";
export const descargasURLBase =
    process.env.NODE_ENV === "development" ? "http://javi:9000/server/public/api_ecommerce.php" : apiURLBase;

export interface ResultBase<Output, ErrorCodes> {
    ok: boolean;
    status?: number;
    output?: Output | undefined;
    errorCode?: ErrorCodes | undefined;
}

export const makeHttpRequest = async <ReqData, ResData, ErrCodes>(
    method: Method,
    url: string,
    reqData: ReqData
): Promise<ResultBase<ResData, ErrCodes>> => {
    try {
        const authToken = getAuthToken();
        const headers = authToken
            ? {
                  Authorization: `Bearer ${authToken}`
              }
            : null;

        const response = await axios({
            method,
            url: `${apiURLBase}/${url}`,
            data: reqData,
            headers
        });

        const { status, data } = response;
        return { ok: true, status, output: data };
    } catch (e) {
        const { response } = e;
        const { status, data } = response;

        if (response && data && data.errorCode) {
            return { ok: false, status, errorCode: data.errorCode };
        }
        console.error(e);
        return { ok: false, status };
    }
};
