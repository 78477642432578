import React, { FC } from "react";
import { PedidoDoc } from "./PedidoDoc";
import Modal, { ModalHeader, ModalSection } from "@kiwicom/orbit-components/lib/Modal";
import { Portal } from "@kiwicom/orbit-components";

export const PedidoDocModal: FC<{ idPedido: number; onClose: () => void }> = ({ idPedido, onClose }) => {
    return (
        <Portal>
            <Modal fixedFooter={false} isMobileFullPage={true} onClose={onClose} size={"large"}>
                <ModalHeader title={null} suppressed={false} />
                <ModalSection suppressed={false}>
                    <PedidoDoc idPedido={idPedido} />
                </ModalSection>
            </Modal>
        </Portal>
    );
};
