import styled from "styled-components";
import { ReactComponent as EnRevisionIcon } from "../iconos/en_revision_icon.svg";
import React, { FC } from "react";
import { Flex, Text } from "rebass/styled-components";
import CheckCircleIcon from "@kiwicom/orbit-components/lib/icons/CheckCircle";
import { CodsEstadosPedidos } from "../types";

export const ColEstado: FC<{ codEstado: CodsEstadosPedidos; ml?: number | string }> = ({ codEstado, ml = 0 }) => {
    const Icono = getEstadoIcono(codEstado);
    const color = getEstadoColor(codEstado);
    const nombre = getEstadoNombre(codEstado);
    const descripcion = getEstadoDescripcion(codEstado);

    return (
        <Flex alignItems={"center"} ml={ml}>
            <Icono />
            <Flex flexDirection={"column"} ml={2}>
                <Text sx={{ textTransform: "uppercase" }} color={color}>
                    {nombre}
                </Text>
                <Text sx={{ fontStyle: "italic" }} color={"datagridLightText"} fontSize={1} mt={1}>
                    {descripcion}
                </Text>
            </Flex>
        </Flex>
    );
};

const StyledEnRevisionIcon = styled(EnRevisionIcon)`
    fill: ${props => props.theme.colors.estadosPedidosEnRevision};
    vertical-align: middle;
`;

const StyledAprobadoIcon = styled(CheckCircleIcon)`
    fill: ${props => props.theme.colors.estadosPedidosAprobado};
    vertical-align: middle;
`;

const StyledDespachadoIcon = styled(CheckCircleIcon)`
    fill: ${props => props.theme.colors.estadosPedidosDespachado};
    vertical-align: middle;
`;

const getEstadoIcono = (codEstado: CodsEstadosPedidos) => {
    if (codEstado === CodsEstadosPedidos.EN_REVISION) {
        return () => <StyledEnRevisionIcon width={30} height={30} />;
    }

    if (codEstado === CodsEstadosPedidos.APROBADO) {
        return () => <StyledAprobadoIcon size={"large"} />;
    }

    if (codEstado === CodsEstadosPedidos.CERRADO) {
        return () => <StyledDespachadoIcon size={"large"} />;
    }

    return () => <></>;
};

const getEstadoColor = (codEstado: CodsEstadosPedidos): string => {
    if (codEstado === CodsEstadosPedidos.EN_REVISION) {
        return "estadosPedidosEnRevision";
    }

    if (codEstado === CodsEstadosPedidos.APROBADO) {
        return "estadosPedidosAprobado";
    }

    if (codEstado === CodsEstadosPedidos.CERRADO) {
        return "estadosPedidosDespachado";
    }

    return "";
};

const getEstadoNombre = (codEstado: CodsEstadosPedidos): string => {
    if (codEstado === CodsEstadosPedidos.EN_REVISION) {
        return "EN REVISIÓN";
    }

    if (codEstado === CodsEstadosPedidos.APROBADO) {
        return "APROBADO";
    }

    if (codEstado === CodsEstadosPedidos.CERRADO) {
        return "DESPACHADO";
    }

    return "";
};

const getEstadoDescripcion = (codEstado: CodsEstadosPedidos): string => {
    if (codEstado === CodsEstadosPedidos.EN_REVISION) {
        return "Pendiente de Aprobación";
    }

    if (codEstado === CodsEstadosPedidos.APROBADO) {
        return "Pendiente de Preparación";
    }

    return "";
};
