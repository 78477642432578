import React, { FC, useMemo, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { Alert, Loading } from "@kiwicom/orbit-components";
import { ColEstado } from "../pedidos/ColEstado";
import Table, { TableBody, TableCell, TableHead, TableRow } from "@kiwicom/orbit-components/lib/Table";
import { formatCant } from "../../lib/format";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { Package as PackageIcon } from "react-feather";
import { Paginador } from "../../ui_components/Paginador";
import { FetchStatus, getInitialState, useListFetcher } from "../../lib/useListFetcher";
import { PedidoDocModal } from "../pedidos/PedidoDocModal";
import { obtenerPedidos, PedidoData } from "../api/PedidosListApi";

export const HomePedidosActivos: FC<any> = () => {
    const [fetcherState, { cambiarNroPagina }] = useListFetcher<PedidoData, {}>(
        obtenerPedidos,
        getInitialState({ filtros: { verSoloPedidosActivos: true }, cantPorPagina: 5 })
    );

    const pedidosActivosTableComponent = useMemo(
        () => (
            <PedidosActivosTable
                pedidosActivos={fetcherState.data && fetcherState.data.length > 0 ? fetcherState.data : []}
            />
        ),
        [fetcherState.data]
    );

    return (
        <Flex flexDirection={"column"}>
            {fetcherState.error && (
                <Box my={3}>
                    <Alert type="critical" title={null} icon>
                        {fetcherState.error}
                    </Alert>
                </Box>
            )}

            <Box>
                {fetcherState.status === FetchStatus.NOT_FETCHED && <></>}
                {fetcherState.status === FetchStatus.FETCHING && (
                    <Loading loading={fetcherState.isLoading} type="boxLoader" text="Cargando Pedidos..." />
                )}
                {fetcherState.status === FetchStatus.SUCCESS && pedidosActivosTableComponent}
            </Box>

            <Paginador
                nroPagina={fetcherState.paginador.nroPagina}
                cantPorPagina={fetcherState.paginador.cantPorPagina}
                cantResultados={fetcherState.paginador.cantResultados}
                cambiarPaginaCallback={nroPagina => {
                    cambiarNroPagina(nroPagina);
                }}
                mt={3}
            />
        </Flex>
    );
};

const PedidosActivosTable: FC<{ pedidosActivos: Array<PedidoData> }> = ({ pedidosActivos }) => {
    const [verIdPedido, setVerIdPedido] = useState<number | null>(null);
    const pedidosActivosListTableComponent = useMemo(() => {
        if (pedidosActivos.length === 0) {
            return <></>;
        }

        return (
            <Table compact={true}>
                <TableHead>
                    <TableRow>
                        <TableCell align={"left"}>Nro Pedido</TableCell>
                        <TableCell align={"left"}>Fecha Pedido</TableCell>
                        <TableCell align={"left"}>Lugar Entrega</TableCell>
                        <TableCell align={"left"}>Cantidades Pedido</TableCell>
                        <TableCell align={"left"}>Estado</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pedidosActivos.map(pedido => {
                        const {
                            id,
                            nroPedido,
                            fecha,
                            lugarEntrega,
                            cantidadesPedido,
                            cantPaquetes,
                            totalDespachado
                        } = pedido;
                        const { unidades, kilos, metros } = cantidadesPedido;
                        const tieneDespachos = totalDespachado.kilos > 0;

                        const porcDespachado = Math.round((totalDespachado.kilos * 100) / cantidadesPedido.kilos);
                        const cantPendiente =
                            totalDespachado.kilos > cantidadesPedido.kilos
                                ? 0
                                : cantidadesPedido.kilos - totalDespachado.kilos;

                        const data = [
                            { name: "Despachado (kgs)", value: totalDespachado.kilos },
                            { name: "Pendiente (kgs)", value: cantPendiente }
                        ];
                        const COLORS = ["#0293cf", "#DBDBDB"];

                        return (
                            <TableRow key={pedido.id}>
                                <TableCell align={"left"}>
                                    <Text
                                        onClick={() => {
                                            setVerIdPedido(id);
                                        }}
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: "0.9em",
                                            "&:hover": {
                                                color: "tableLinkColor",
                                                cursor: "pointer",
                                                textDecoration: "underline"
                                            }
                                        }}
                                    >
                                        {nroPedido}
                                    </Text>
                                </TableCell>

                                <TableCell align={"left"}>{fecha}</TableCell>
                                <TableCell align={"left"}>
                                    <Flex flexDirection={"column"}>
                                        <Text>{lugarEntrega.descripcion}</Text>
                                        <Text fontSize={"0.8em"}>{lugarEntrega.direccion}</Text>
                                        <Text fontSize={"0.8em"}>{lugarEntrega.localidad}</Text>
                                    </Flex>
                                </TableCell>
                                <TableCell align={"left"}>
                                    <Flex>
                                        <Flex flexDirection={"column"} ml={1}>
                                            {cantPaquetes > 0 && (
                                                <Flex mb={2}>
                                                    <PackageIcon size={16} />
                                                    <Text fontWeight={600} ml={1}>
                                                        {cantPaquetes}
                                                    </Text>
                                                    <Text ml={1}> {cantPaquetes === 1 ? "paquete" : "paquetes"}</Text>
                                                </Flex>
                                            )}
                                            <Flex>
                                                {unidades > 0 && (
                                                    <Flex>
                                                        <Text fontWeight={600}>{formatCant(unidades)}</Text>
                                                        <Text>uni.</Text>
                                                    </Flex>
                                                )}
                                                {kilos > 0 && (
                                                    <Flex ml={1}>
                                                        <Text fontWeight={600}>{formatCant(kilos)}</Text>
                                                        <Text>kgs.</Text>
                                                    </Flex>
                                                )}
                                                {metros > 0 && (
                                                    <Flex ml={1}>
                                                        <Text fontWeight={600}>{formatCant(metros)}</Text>
                                                        <Text>mts.</Text>
                                                    </Flex>
                                                )}
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </TableCell>
                                <TableCell align={"left"}>
                                    {tieneDespachos ? (
                                        <Flex>
                                            <PieChart width={30} height={30} onMouseEnter={() => {}}>
                                                <Pie
                                                    data={data}
                                                    // cx={"50%"}
                                                    innerRadius={10}
                                                    outerRadius={15}
                                                    fill="#8884d8"
                                                    // paddingAngle={5}
                                                    startAngle={450}
                                                    endAngle={90}
                                                    dataKey="value"
                                                >
                                                    {data.map((entry, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={COLORS[index % COLORS.length]}
                                                        />
                                                    ))}
                                                </Pie>
                                                <Tooltip />
                                            </PieChart>
                                            <Flex flexDirection={"column"} ml={1}>
                                                <Text fontSize={"0.9em"}>Despachado ({porcDespachado}%):</Text>
                                                <Flex>
                                                    {totalDespachado.unidades > 0 && (
                                                        <Flex>
                                                            <Text fontWeight={600}>
                                                                {formatCant(totalDespachado.unidades)}
                                                            </Text>
                                                            <Text>uni.</Text>
                                                        </Flex>
                                                    )}
                                                    {totalDespachado.kilos > 0 && (
                                                        <Flex ml={1}>
                                                            <Text fontWeight={600}>
                                                                {formatCant(totalDespachado.kilos)}
                                                            </Text>
                                                            <Text>kgs.</Text>
                                                        </Flex>
                                                    )}
                                                    {totalDespachado.metros > 0 && (
                                                        <Flex ml={1}>
                                                            <Text fontWeight={600}>
                                                                {formatCant(totalDespachado.metros)}
                                                            </Text>
                                                            <Text>mts.</Text>
                                                        </Flex>
                                                    )}
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    ) : (
                                        <ColEstado codEstado={pedido.codEstado} ml={"auto"} />
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }, [pedidosActivos]);

    return (
        <>
            {verIdPedido && <PedidoDocModal idPedido={verIdPedido} onClose={() => setVerIdPedido(null)} />}
            {pedidosActivosListTableComponent}
        </>
    );
};
