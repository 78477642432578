import { ResultBase, makeHttpRequest, POST } from "./api";

export enum ErrorCodes {}

export interface ResponseData {
    idPedidoGenerado: number;
    nroPedidoGenerado: number;
}
export interface Response extends ResultBase<ResponseData, ErrorCodes> {}

export const confirmarPedido = async (): Promise<Response> =>
    makeHttpRequest<{}, ResponseData, ErrorCodes>(POST, "carrito-confirmar-pedido", {});
