import { ResultBase, makeHttpRequest, POST } from "./api";

export interface Request {
    email: string;
    password: string;
}

export enum ErrorCodes {
    ERROR_VALIDACION = "ERROR_VALIDACION",
    EMAIL_NO_REGISTRADO = "EMAIL_NO_REGISTRADO",
    USUARIO_INACTIVO = "USUARIO_INACTIVO",
    PASSWORD_INCORRECTO = "PASSWORD_INCORRECTO"
}

export interface ResponseData {
    token: string;
}

export interface Response extends ResultBase<ResponseData, ErrorCodes> {}

export const login = async (req: Request): Promise<Response> =>
    makeHttpRequest<Request, ResponseData, ErrorCodes>(POST, "login", req);
