import { useState } from "react";
import { createContainer } from "unstated-next";
import * as LocalStorage from "./LocalStorage";

export interface IUsuario {
    id: number;
    email: string;
    esAdmin: boolean;
}

interface Cliente {
    nombre: string;
}

interface Sesion {
    authToken: string | null;
    fetched: boolean;
    usuario?: IUsuario;
    cliente?: Cliente;
}

const initialState = {
    authToken: LocalStorage.getAuthToken(),
    fetched: false
};

function useSesion() {
    const [sesion, setSesion] = useState<Sesion>(initialState);
    const setAuthToken = (token: string) => {
        setSesion({ ...sesion, authToken: token });
    };

    const setFetchedData = ({ usuario, cliente }: { usuario: IUsuario; cliente: Cliente }) => {
        setSesion({ ...sesion, fetched: true, usuario, cliente });
    };

    const clearSesion = () => {
        LocalStorage.removeAuthToken();
        setSesion({ ...initialState, authToken: null });
    };

    return { sesion, setAuthToken, setSesion, setFetchedData, clearSesion };
}

export const SesionContainer = createContainer(useSesion);
