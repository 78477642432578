import { PedidosListFiltrosType } from "../api/PedidosListApi";
import { IFiltroActivo } from "../../ui_components/ListView/FiltrosActivos";
import { formatFecha } from "../../lib/format";

const eliminarFiltro = (filtros: PedidosListFiltrosType, key: string): PedidosListFiltrosType => {
    const nuevosFiltros = Object.assign({}, filtros);
    delete nuevosFiltros[key];
    return nuevosFiltros;
};

export const getFiltrosActivos = (
    filtros: PedidosListFiltrosType | undefined,
    filtrar: (PedidosListFiltrosType) => void
): Array<IFiltroActivo> => {
    if (!filtros) {
        return [];
    }

    const { fechaDesde, fechaHasta, verSoloPedidosActivos } = filtros;
    let filtrosActivos: Array<IFiltroActivo> = [];

    if (fechaDesde) {
        filtrosActivos.push({
            descripcion: `Fecha Desde: ${formatFecha(fechaDesde)}`,
            eliminarFiltro: () => {
                filtrar(eliminarFiltro(filtros, "fechaDesde"));
            }
        });
    }

    if (fechaHasta) {
        filtrosActivos.push({
            descripcion: `Fecha Hasta: ${formatFecha(fechaHasta)}`,
            eliminarFiltro: () => {
                filtrar(eliminarFiltro(filtros, "fechaHasta"));
            }
        });
    }

    if (verSoloPedidosActivos) {
        filtrosActivos.push({
            descripcion: `Ver Solo Pedidos Activos`,
            eliminarFiltro: () => {
                filtrar(eliminarFiltro(filtros, "verSoloPedidosActivos"));
            }
        });
    }

    return filtrosActivos;
};
