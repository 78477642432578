import { ResultBase, makeHttpRequest, POST } from "./api";
import { Articulo, CodsEstadosPedidos, ICantidades, ListRequestData, ListResponseOutputBase } from "../types";

export enum ErrorCodes {}

export interface PedidosListFiltrosType {
    fechaDesde?: Date;
    fechaHasta?: Date;
    verSoloPedidosActivos?: boolean;
}

export interface PedidoData {
    id: number;
    codEstado: CodsEstadosPedidos;
    fecha: string;
    nroPedido: number;
    lugarEntrega: LugarEntregaData;
    cantPaquetes: number;
    cantArticulos: number;
    cantidadesPedido: ICantidades;
    totalDespachado: ICantidades;
    moneda: string;
    subTotal: number;
    importeIVA: number;
    importePercIVA: number;
    importePercIIBB: number;
    importeTotal: number;
    detalles: Array<PedidoDetalleData>;
    remitos: Array<PedidoRemitoData>;
    facturas: Array<PedidoFacturaData>;
}

export interface LugarEntregaData {
    descripcion: string;
    direccion: string;
    localidad: string;
}

export interface PedidoDetalleData {
    id: number;
    nroLinea: number;
    codEstado: CodsEstadosPedidos;
    articulo: Articulo;
    cantPaquetes: number;
    cantidad: ICantidades;
    cantidadDespachada: ICantidades;
    precioFinal: number;
}

export interface PedidoFacturaData {
    idFactura: number;
    nroFactura: string;
    fechaFactura: string;
    claseDocumento: {
        id: number;
        descripcion: string;
    };
    idRemito: number;
}

export interface PedidoRemitoData {
    idRemito: number;
    nroRemito: string;
    fechaRemito: string;
}

interface ResponseOutput extends ListResponseOutputBase<PedidoData> {}

export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const obtenerPedidos = async (reqData: ListRequestData<PedidosListFiltrosType>): Promise<Result> =>
    makeHttpRequest<ListRequestData<PedidosListFiltrosType>, ResponseOutput, ErrorCodes>(POST, "pedidos-list", reqData);
