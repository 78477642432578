import { format } from "date-fns";
import { es } from "date-fns/locale";

export const formatFecha = (fecha: Date, formato: string = "dd/MM/yyyy"): string =>
    format(fecha, formato, {
        locale: es
    });

export const formatCant = (numero: number | undefined, maximumFractionDigits = 1): string => {
    if (typeof numero === "undefined") {
        numero = 0;
    }

    return numero.toLocaleString("es-ES", {
        maximumFractionDigits
    });
};

export const formatImporte = (numero: number, cantDecimales = 2): string => {
    if (typeof numero === "undefined") {
        numero = 0;
    }

    return numero.toLocaleString("es-ES", {
        minimumFractionDigits: cantDecimales,
        maximumFractionDigits: cantDecimales
    });
};
