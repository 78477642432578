import React, { FC, useState } from "react";
import { Flex, Text } from "rebass/styled-components";
import { Button } from "@kiwicom/orbit-components";
import { Redirect } from "react-router";
import { rutas } from "../rutas";

export const ConfirmarPedidoCarritoVacio: FC = () => {
    const [irANuevoPedido, setIrANuevoPedido] = useState<boolean>(false);
    if (irANuevoPedido) {
        return <Redirect to={rutas.nuevoPedido} push={true} />;
    }

    return (
        <Flex
            sx={{
                borderRadius: "0.25em",
                bg: "white",
                mx: "auto",
                my: "1em",
                p: 4,
                flexDirection: "column",
                width: "35em",
                textAlign: "center"
            }}
        >
            <Text color={"titulo"} fontSize={"1.75em"} fontWeight={600} textAlign={"center"} mb={2}>
                Tu carrito está vacío
            </Text>
            <Text color={"datagridLightText"} mb={4}>
                No tiene ningún paquete agregado al carrito.
            </Text>
            <Button onClick={() => setIrANuevoPedido(true)} size={"small"} type={"primary"}>
                ¡Empezar a Comprar en eCommerce!
            </Button>
        </Flex>
    );
};
