import { ResultBase, makeHttpRequest, POST } from "./api";
import { IUsuario } from "../Sesion";

export enum ErrorCodes {}

export interface ResponseData {
    usuario: IUsuario;
    cliente: {
        nombre: string;
    };
}

export interface Response extends ResultBase<ResponseData, ErrorCodes> {}

export const obtenerDatosSesion = async (): Promise<Response> =>
    makeHttpRequest<{}, ResponseData, ErrorCodes>(POST, "sesion", {});
