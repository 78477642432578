import { ResultBase, makeHttpRequest, POST } from "./api";

export enum ErrorCodes {}

export enum TipoEmail {
    VENTAS = "VENTAS",
    RECLAMOS = "RECLAMOS",
    ANALISTA = "ANALISTA"
}

export interface RequestData {
    tipoEmail: TipoEmail;
    contenidoEmail: string;
}

export interface ResponseData {
    vendedor: IVendedor;
}

export interface IVendedor {
    nombreApellido: string;
    email: string;
    telefono: string;
}

export interface Response extends ResultBase<ResponseData, ErrorCodes> {}

export const enviarEmail = async (reqData: RequestData) =>
    makeHttpRequest<RequestData, {}, ErrorCodes>(POST, "contacto-enviar-email", reqData);
