import React, { FC, useEffect, useMemo, useState } from "react";
import { IRemitosListData, IRemitosListFiltros, obtenerRemitos } from "../api/RemitosListApi";
import { RemitosListTable } from "./RemitosListTable";
import { Paginador } from "../../ui_components/Paginador";
import { FetchStatus, getInitialState, useListFetcher } from "../../lib/useListFetcher";
import { Box, Flex } from "rebass/styled-components";
import { Alert, Loading, Separator } from "@kiwicom/orbit-components";
import { TopBar } from "../../ui_components/ListView/TopBar";
import { useDebounce } from "use-debounce";
import { IFiltroActivo } from "../../ui_components/ListView/FiltrosActivos";
import { obtenerFiltrosActivos, RemitosListFiltros } from "./RemitosListFiltros";
import { getAuthToken } from "../LocalStorage";
import { descargasURLBase } from "../api/api";

interface RemitosListProps {
    filtros?: IRemitosListFiltros;
    cantPorPagina?: number;
}

export const RemitosList: FC<RemitosListProps> = ({ filtros: filtrosProps, cantPorPagina }) => {
    const [fetcherState, { buscar, cambiarNroPagina, filtrar }] = useListFetcher<IRemitosListData, IRemitosListFiltros>(
        obtenerRemitos,
        getInitialState({ filtros: filtrosProps, cantPorPagina })
    );
    const [busqueda, setBusqueda] = useState("");
    const [busquedaDebounced] = useDebounce(busqueda, 1500);
    const [verFiltros, setVerFiltros] = useState(false);
    const authToken = getAuthToken();
    const excelURLBase = `${descargasURLBase}/remitos-list-excel?token=${authToken}`;

    useEffect(() => {
        filtrar(filtrosProps);
    }, [filtrar, filtrosProps]);

    const remitosListComponent = useMemo(
        () => <RemitosListTable remitos={fetcherState.data && fetcherState.data.length > 0 ? fetcherState.data : []} />,
        [fetcherState.data]
    );

    const filtrosActivos = useMemo<Array<IFiltroActivo>>(() => obtenerFiltrosActivos(fetcherState.filtros, filtrar), [
        fetcherState.filtros,
        filtrar
    ]);

    useEffect(() => {
        buscar(busquedaDebounced);
    }, [buscar, busquedaDebounced]);

    const filtrarCallback = (filtros: IRemitosListFiltros) => {
        filtrar(filtros);
        setVerFiltros(false);
    };

    return (
        <Flex flexDirection={"column"}>
            {verFiltros && (
                <RemitosListFiltros
                    cerrarCallback={() => setVerFiltros(false)}
                    filtros={fetcherState.filtros}
                    filtrarCallback={filtrarCallback}
                />
            )}
            {fetcherState.error && (
                <Box my={3}>
                    <Alert type="critical" title={null} icon>
                        {fetcherState.error}
                    </Alert>
                </Box>
            )}
            <Box css={{ flexShrink: 0 }}>
                <TopBar
                    busqueda={busqueda}
                    exportarExcelProps={{
                        urlBase: excelURLBase,
                        cantMaxRegistros: 500,
                        nombreObjeto: "remitos",
                        fetcherState: {
                            busqueda: fetcherState.busqueda,
                            filtros: fetcherState.filtros,
                            paginador: fetcherState.paginador
                        }
                    }}
                    filtrosActivos={filtrosActivos}
                    mostrarFiltros={() => setVerFiltros(true)}
                    setBusqueda={busqueda => setBusqueda(busqueda)}
                    verCrear={false}
                />
                <Box my={3}>
                    <Separator />
                </Box>
            </Box>
            <Box>
                {fetcherState.status === FetchStatus.NOT_FETCHED && <></>}
                {fetcherState.status === FetchStatus.FETCHING && (
                    <Loading loading={fetcherState.isLoading} type="boxLoader" text="Cargando Remitos..." />
                )}
                {fetcherState.status === FetchStatus.SUCCESS && remitosListComponent}
            </Box>
            <Paginador
                nroPagina={fetcherState.paginador.nroPagina}
                cantPorPagina={fetcherState.paginador.cantPorPagina}
                cantResultados={fetcherState.paginador.cantResultados}
                cambiarPaginaCallback={nroPagina => {
                    cambiarNroPagina(nroPagina);
                }}
                mt={3}
            />
        </Flex>
    );
};
