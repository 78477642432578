import React, { FC, useEffect, useState } from "react";
import { Box } from "rebass/styled-components";
import { CarritoSidebar } from "./CarritoSidebar";
import { CarritoContainer } from "./CarritoContainer";
import {
    CarritoEditarCantidadesArticulo,
    CarritoEditarCantidadesArticuloData
} from "./CarritoEditarCantidadesArticulo";
import { NuevoPedidoArticulos } from "./NuevoPedidoArticulos";

export const NuevoPedidoPage: FC = () => {
    const { pedidoCarrito, fetch: fetchCarrito } = CarritoContainer.useContainer();
    const [editarCantidades, setEditarCantidades] = useState<CarritoEditarCantidadesArticuloData | null>(null);

    useEffect(() => {
        fetchCarrito();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {editarCantidades && (
                <CarritoEditarCantidadesArticulo
                    cerrarCallback={() => {
                        setEditarCantidades(null);
                    }}
                    successCallback={() => {
                        setEditarCantidades(null);
                        fetchCarrito();
                    }}
                    {...editarCantidades}
                />
            )}
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "auto 47em auto",
                    gridColumnGap: "0px",
                    justifyItems: "right"
                }}
                my={3}
            >
                <Box sx={{ gridColumnStart: "2" }} width={1}>
                    <NuevoPedidoArticulos onAgregarArticulo={setEditarCantidades} pedidoCarrito={pedidoCarrito} />
                </Box>

                {pedidoCarrito && (
                    <Box sx={{ gridColumnStart: "3" }} width={"23em"} ml={0} mr={2}>
                        <CarritoSidebar onEditarArticulo={setEditarCantidades} pedido={pedidoCarrito} />
                    </Box>
                )}
            </Box>
        </>
    );
};
