import React, { FC } from "react";
import { Flex, Text } from "rebass/styled-components";
import { Button, ButtonGroup } from "@kiwicom/orbit-components";

interface Props {
    nroPagina: number;
    cantPorPagina: number;
    cantResultados: number;
    cambiarPaginaCallback: (nroPagina: number) => void;
    mt?: number;
}

type Item = {
    active: boolean;
    click: () => void;
    disabled: boolean;
    key: number;
    text: string;
};

export const Paginador: FC<Props> = ({ nroPagina, cantPorPagina, cantResultados, cambiarPaginaCallback, mt }) => {
    if (cantResultados === 0) {
        return <></>;
    }

    const cantPaginas = Math.ceil(cantResultados / cantPorPagina);
    const mostrandoDesde = cantPorPagina * (nroPagina - 1) + 1;
    let hasta = mostrandoDesde + cantPorPagina - 1;
    if (hasta > cantResultados) {
        hasta = cantResultados;
    }
    const mostrandoHasta = hasta;

    const obtenerItems = () => {
        let items: Array<Item> = [];

        if (nroPagina === 0) {
            return [];
        }

        const agregarItem = text => {
            const disabled = text === "...";
            const active = text.toString() === nroPagina.toString();

            items.push({
                active,
                click: () => {
                    if (!disabled) {
                        cambiarPaginaCallback(parseInt(text));
                    }
                },
                disabled,
                key: items.length,
                text
            });
        };

        if (nroPagina > 1) {
            items.push({
                active: false,
                click: () => {
                    cambiarPaginaCallback(nroPagina - 1);
                },
                disabled: nroPagina === 1,
                key: items.length,
                text: "<"
            });
        }

        agregarItem(1);

        if (nroPagina > 2) {
            agregarItem("...");
            if (nroPagina === cantPaginas && cantPaginas > 3) {
                agregarItem(nroPagina - 2);
            }

            agregarItem(nroPagina - 1);
        }

        if (nroPagina !== 1 && nroPagina !== cantPaginas) {
            agregarItem(nroPagina);
        }

        if (nroPagina < cantPaginas - 1) {
            agregarItem(nroPagina + 1);
            if (nroPagina === 1 && cantPaginas > 3) {
                agregarItem(nroPagina + 2);
            }
            agregarItem("...");
        }

        if (cantPaginas > 1) {
            agregarItem(cantPaginas);
        }

        if (nroPagina < cantPaginas) {
            items.push({
                active: false,
                click: () => {
                    cambiarPaginaCallback(nroPagina + 1);
                },
                disabled: nroPagina === cantPaginas,
                key: items.length,
                text: ">"
            });
        }

        return items;
    };

    const items = obtenerItems();

    return (
        <Flex alignItems={"center"} flexDirection={"column"} mt={mt} mx={"auto"}>
            <Text sx={{ fontStyle: "italic" }} color={"colorFormLabelFilled"}>
                Mostrando {mostrandoDesde} a {mostrandoHasta} de {cantResultados} resultados.
            </Text>
            <Flex flexDirection={"row"} mt={3}>
                <ButtonGroup connected={false}>
                    {items.map((item: Item) => {
                        return (
                            <Button
                                bordered={item.active}
                                circled={true}
                                disabled={item.disabled}
                                key={item.key}
                                onClick={item.click}
                                size={"small"}
                                type={"secondary"}
                            >
                                {item.text}
                            </Button>
                        );
                    })}
                </ButtonGroup>
            </Flex>
        </Flex>
    );
};
