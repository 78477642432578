import React, { FC } from "react";
import { Box } from "rebass/styled-components";

export const SeparadorVertical: FC<{ mx?: number | undefined | string }> = ({ mx }) => {
    return (
        <Box
            sx={{
                borderLeftWidth: "1px",
                borderLeftColor: "#8A8A8A",
                borderLeftStyle: "solid"
            }}
            mx={mx}
            width={"1px"}
        />
    );
};
