import { ICantidades, ListRequestData, ListResponseOutputBase } from "../types";
import { makeHttpRequest, POST, ResultBase } from "./api";

enum ErrorCodes {}

export enum NivelesStock {
    PLANIFICADO = "PLANIFICADO",
    SIN_STOCK = "SIN_STOCK",
    X = "X",
    XX = "XX",
    XXX = "XXX"
}

export enum OrdenarPor {
    MAS_PEDIDOS = "MAS_PEDIDOS",
    STOCK = "STOCK",
    MEDIDA = "MEDIDA"
}

export interface IArticulosListFiltrosType {
    idGrupo?: number;
    idSubGrupo?: number;
    idMedida?: number;
    verOfertas?: boolean;
}

export interface IArticulosListData {
    idListaPreciosDetalle: number;
    codArticulo: string;
    descripcion: string;
    forma: string;
    medida: string;
    diametro: string;
    extremo: string;
    observaciones: string | null;
    cantidadesEstandard: ICantidades;
    moneda: string;
    nivelStock: NivelesStock;
    precioListaPaquete: number;
    porcDescuento1: number;
    porcDescuento2: number;
    porcDescuento3: number;
    porcDescuento4: number;
    precioPaqueteSinDescuento4: number;
    precioFinalPaquete: number;
}

interface ResponseOutput extends ListResponseOutputBase<IArticulosListData> {}

export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const obtenerArticulos = async (reqData: ListRequestData<IArticulosListFiltrosType>): Promise<Result> =>
    makeHttpRequest<ListRequestData<IArticulosListFiltrosType>, ResponseOutput, ErrorCodes>(
        POST,
        "nuevo-pedido-articulos-list",
        reqData
    );
