import React, { FC, useEffect, useState } from "react";
import { Documento, IDocumento } from "../Documento";
import { Box, Flex, Text } from "rebass/styled-components";
import Alert from "@kiwicom/orbit-components/lib/Alert";
import Loading from "@kiwicom/orbit-components/lib/Loading";
import Table, { TableHead, TableBody, TableRow, TableCell } from "@kiwicom/orbit-components/lib/Table";
import getTokens from "@kiwicom/orbit-components/lib/getTokens";
import { ThemeProvider } from "styled-components";
import { IAplicacion, obtenerAplicacion } from "../api/AplicacionDocApi";
import { formatImporte } from "../../lib/format";

const aplicacionToDocProps = (aplicacion: IAplicacion): IDocumento => {
    const { id, fecha, cliente } = aplicacion;

    return {
        tipoDoc: "Aplicación de Documentos",
        nroDoc: id,
        fechaDoc: fecha,
        cliente
    };
};

export const AplicacionDoc: FC<{ idAplicacion: number }> = ({ idAplicacion }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [msgError, setMsgError] = useState<string | null>(null);
    const [aplicacion, setAplicacion] = useState<IAplicacion | null>(null);

    useEffect(() => {
        const fetchAplicacion = async () => {
            const resp = await obtenerAplicacion({ idAplicacion });
            if (resp.ok && resp.output) {
                const aplicacion = resp.output.aplicacion;
                setAplicacion(aplicacion);
                setLoading(false);
                return;
            }

            setMsgError("No se puedieron cargar los datos de la aplicación");
            setLoading(false);
        };

        setLoading(true);
        fetchAplicacion();
    }, [idAplicacion]);

    if (loading) {
        return <Loading loading={true} type="boxLoader" text="Cargando..." />;
    }

    if (msgError) {
        return (
            <Box mb={4} mx={"auto"} my={3} width={"40em"}>
                <Alert type="critical" title={null} icon={true}>
                    {msgError}
                </Alert>
            </Box>
        );
    }

    if (aplicacion) {
        return <Documento doc={aplicacionToDocProps(aplicacion)} Body={<DocBody aplicacion={aplicacion} />} />;
    }

    return <></>;
};

const orbitTheme = getTokens();
orbitTheme.colorTextTable = "#252A31";
const DocBody: FC<{ aplicacion: IAplicacion }> = ({ aplicacion: { detalles, moneda } }) => {
    return (
        <ThemeProvider theme={{ orbit: orbitTheme }}>
            <Table compact={true}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Documento Aplicado</TableCell>
                        <TableCell align="right">Importe</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {detalles.map((detalle, index) => {
                        const { factura, recibo, ajuste, importeAplicacion } = detalle;

                        let tipoDoc = "";
                        let descDoc = "";
                        if (factura) {
                            tipoDoc = factura.claseDocumento;
                            descDoc = factura.nroFactura;
                        } else if (recibo) {
                            tipoDoc = "Recibo";
                            descDoc = `Nro. ${recibo.nroRecibo.toString()}`;
                        } else if (ajuste) {
                            tipoDoc = "Ajuste";
                            descDoc = `Nro. ${ajuste.id.toString()}`;
                        }

                        const signo = importeAplicacion < 0 ? "-" : "";
                        const importeAbsoluto = Math.abs(importeAplicacion);
                        const importeTexto = `${signo} ${moneda} ${formatImporte(importeAbsoluto)}`;

                        return (
                            <TableRow key={index}>
                                <TableCell align="left">
                                    <Flex>
                                        <Text>{tipoDoc}</Text>
                                        <Text ml={1}>{descDoc}</Text>
                                    </Flex>
                                </TableCell>
                                <TableCell align="right">{importeTexto}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </ThemeProvider>
    );
};
