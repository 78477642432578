import React, { FC, useState } from "react";
import { Button, InputField } from "@kiwicom/orbit-components";
import Modal, { ModalHeader, ModalSection, ModalFooter } from "@kiwicom/orbit-components/lib/Modal";
import CalendarIcon from "@kiwicom/orbit-components/lib/icons/Calendar";
import CloseIcon from "@kiwicom/orbit-components/lib/icons/Close";
import { Box, Flex } from "rebass/styled-components";
import DatePicker from "react-datepicker";
import { Filter } from "react-feather";
import { ICertificadosListFiltros } from "../api/CertificadosListApi";
import { IFiltroActivo } from "../../ui_components/ListView/FiltrosActivos";
import { formatFecha } from "../../lib/format";

interface Props {
    filtros?: ICertificadosListFiltros;
    cerrarCallback: () => void;
    filtrarCallback: (filtros: ICertificadosListFiltros) => void;
}

export const CertificadosListFiltros: FC<Props> = ({ cerrarCallback, filtros, filtrarCallback }) => {
    const [fechaDesde, setFechaDesde] = useState<Date | undefined>(filtros && filtros.fechaDesde);
    const [fechaHasta, setFechaHasta] = useState<Date | undefined>(filtros && filtros.fechaHasta);

    const getFiltros = (): ICertificadosListFiltros => {
        return {
            fechaDesde,
            fechaHasta
        };
    };

    const mt = 3;
    const dateFormat = "dd/MM/yyyy";

    return (
        <Modal fixedFooter={false} isMobileFullPage={false} size={"small"}>
            <ModalHeader title={"Filtros de Certificados"} suppressed={true} />
            <ModalSection suppressed={false}>
                <Flex flexDirection={"column"} mx={"auto"} width={1 / 2}>
                    <Box>
                        <DatePicker
                            customInput={
                                <InputField
                                    inlineLabel={false}
                                    label={"Fecha Desde:"}
                                    prefix={<CalendarIcon />}
                                    size={"normal"}
                                />
                            }
                            dateFormat={dateFormat}
                            endDate={fechaHasta}
                            onChange={fecha => setFechaDesde(fecha)}
                            selected={fechaDesde}
                            selectsStart={true}
                        />
                    </Box>

                    <Box mt={mt}>
                        <DatePicker
                            customInput={
                                <InputField
                                    inlineLabel={false}
                                    label={"Fecha Hasta:"}
                                    prefix={<CalendarIcon />}
                                    size={"normal"}
                                />
                            }
                            dateFormat={dateFormat}
                            startDate={fechaDesde}
                            onChange={fecha => setFechaHasta(fecha)}
                            selected={fechaHasta}
                            selectsEnd={true}
                        />
                    </Box>
                </Flex>
            </ModalSection>
            <ModalFooter fixed={true} flex={["0 0 auto", "1 1 100%"]} suppressed={true}>
                <Button iconLeft={<CloseIcon />} onClick={cerrarCallback} type="secondary">
                    Cerrar
                </Button>
                <Button block iconLeft={<Filter />} onClick={() => filtrarCallback(getFiltros())} type={"primary"}>
                    Filtrar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const eliminarFiltro = (filtros: ICertificadosListFiltros, key: string): ICertificadosListFiltros => {
    const nuevosFiltros = Object.assign({}, filtros);
    delete nuevosFiltros[key];
    return nuevosFiltros;
};

export const obtenerFiltrosActivos = (
    filtros: ICertificadosListFiltros | undefined,
    filtrar: (ICertificadosListFiltros) => void
): Array<IFiltroActivo> => {
    if (!filtros) {
        return [];
    }

    const { fechaDesde, fechaHasta } = filtros;
    let filtrosActivos: Array<IFiltroActivo> = [];

    if (fechaDesde) {
        filtrosActivos.push({
            descripcion: `Fecha Desde: ${formatFecha(fechaDesde)}`,
            eliminarFiltro: () => {
                filtrar(eliminarFiltro(filtros, "fechaDesde"));
            }
        });
    }

    if (fechaHasta) {
        filtrosActivos.push({
            descripcion: `Fecha Hasta: ${formatFecha(fechaHasta)}`,
            eliminarFiltro: () => {
                filtrar(eliminarFiltro(filtros, "fechaHasta"));
            }
        });
    }

    return filtrosActivos;
};
