import { ResultBase, makeHttpRequest, POST } from "./api";

export interface Request {
    codRestablecerPassword: string;
    password: string;
}

export enum ErrorCodes {
    COD_INCORRECTO = "COD_INCORRECTO",
    COD_EXPIRADO = "COD_EXPIRADO",
    PASSWORD_DEBIL = "PASSWORD_DEBIL"
}

export interface ResponseData {
    linkIngreso: string;
}
export interface Response extends ResultBase<ResponseData, ErrorCodes> {}

export const cambiarPassword = async (req: Request): Promise<Response> =>
    makeHttpRequest<Request, ResponseData, ErrorCodes>(POST, "cambiar-password", req);
