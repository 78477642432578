import React, { FC, useEffect, useMemo, useState } from "react";
import { Documento, ICategoriaHeaderDatos, IDocumento, ITotal } from "../Documento";
import { Box, Flex, Text } from "rebass/styled-components";
import Alert from "@kiwicom/orbit-components/lib/Alert";
import Loading from "@kiwicom/orbit-components/lib/Loading";
import Table, { TableHead, TableBody, TableRow, TableCell } from "@kiwicom/orbit-components/lib/Table";
import getTokens from "@kiwicom/orbit-components/lib/getTokens";
import { ThemeProvider } from "styled-components";
import { IFactura, obtenerFactura } from "../api/FacturaDocApi";
import { formatCant, formatImporte } from "../../lib/format";
import { descargasURLBase } from "../api/api";
import { getAuthToken } from "../LocalStorage";

const facturaToDocProps = (factura: IFactura): IDocumento => {
    const {
        tipoDoc,
        nroFactura,
        fecha,
        cliente,
        nroPedido,
        tipoPedido,
        anticipoOriginal,
        nroRemito,
        condVenta,
        nroOCCliente,
        vendedor,
        moneda
    } = factura;

    const obtenerDatosVentas = (): Array<ICategoriaHeaderDatos> => {
        const datosVentas: Array<ICategoriaHeaderDatos> = [
            {
                nombre: "Nro. Pedido",
                valor: formatImporte(nroPedido)
            },
            {
                nombre: "Tipo Pedido",
                valor: tipoPedido
            }
        ];

        if (anticipoOriginal) {
            datosVentas.push({
                nombre: "Anticipo Original",
                valor: anticipoOriginal
            });
        }

        if (nroRemito) {
            datosVentas.push({
                nombre: "Nro. Remito",
                valor: nroRemito
            });
        }

        if (condVenta) {
            datosVentas.push({
                nombre: "Cond. Venta",
                valor: condVenta
            });
        }

        if (nroOCCliente) {
            datosVentas.push({
                nombre: "Nro. OC. Cliente",
                valor: nroOCCliente
            });
        }

        if (vendedor) {
            datosVentas.push({
                nombre: "Vendedor",
                valor: vendedor
            });
        }

        return datosVentas;
    };

    const obtenerTotales = (): Array<ITotal> => {
        const { subTotal, percepcionesIIBB, iva, percIVA, total } = factura.totales;

        let totales = [
            {
                descripcion: "Sub Total",
                importe: subTotal
            },
            ...percepcionesIIBB.map(
                ({ jurisdiccion, porcentaje, importe }): ITotal => {
                    return {
                        descripcion: `IIBB ${jurisdiccion} ${porcentaje}%`,
                        importe
                    };
                }
            )
        ];

        if (iva) {
            totales.push({ descripcion: "IVA", importe: iva });
        }

        if (percIVA) {
            totales.push({ descripcion: "Perc. IVA", importe: percIVA });
        }

        totales.push({ descripcion: "Total", importe: total });

        return totales;
    };

    return {
        tipoDoc,
        nroDoc: nroFactura,
        fechaDoc: fecha,
        cliente,
        categoriasHeader: [
            {
                titulo: "Venta",
                datos: obtenerDatosVentas()
            }
        ],
        moneda,
        totales: obtenerTotales()
    };
};

export const FacturaDoc: FC<{ idFactura: number }> = ({ idFactura }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [msgError, setMsgError] = useState<string | null>(null);
    const [factura, setFactura] = useState<IFactura | null>(null);

    useEffect(() => {
        const fetchFactura = async () => {
            const resp = await obtenerFactura({ idFactura });
            if (resp.ok && resp.output) {
                const factura = resp.output.factura;
                setFactura(factura);
                setLoading(false);
                return;
            }

            setMsgError("No se puedieron cargar los datos de la factura");
            setLoading(false);
        };

        setLoading(true);
        fetchFactura();
    }, [idFactura]);

    const bajarPDFUrl = useMemo(() => {
        const authToken = getAuthToken();
        return `${descargasURLBase}/factura-doc-bajar-PDF?token=${authToken}&idFactura=${idFactura}`;
    }, [idFactura]);

    if (loading) {
        return <Loading loading={true} type="boxLoader" text="Cargando..." />;
    }

    if (msgError) {
        return (
            <Box mb={4} mx={"auto"} my={3} width={"40em"}>
                <Alert type="critical" title={null} icon={true}>
                    {msgError}
                </Alert>
            </Box>
        );
    }

    if (factura) {
        return (
            <Documento
                bajarPDFUrl={bajarPDFUrl}
                doc={facturaToDocProps(factura)}
                Body={<DocBody factura={factura} />}
            />
        );
    }

    return <></>;
};

const orbitTheme = getTokens();
orbitTheme.colorTextTable = "#252A31";
const DocBody: FC<{ factura: IFactura }> = ({ factura: { detalles, moneda } }) => {
    let tieneBonif1 = true;
    let tieneBonif2 = true;
    let tieneBonif3 = true;
    let tieneBonif4 = true;
    detalles.forEach(({ bonif1, bonif2, bonif3, bonif4 }) => {
        tieneBonif1 = tieneBonif1 && bonif1 > 0;
        tieneBonif2 = tieneBonif2 && bonif2 > 0;
        tieneBonif3 = tieneBonif3 && bonif3 > 0;
        tieneBonif4 = tieneBonif4 && bonif4 > 0;
    });

    return (
        <ThemeProvider theme={{ orbit: orbitTheme }}>
            <Table compact={true}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Artículo</TableCell>
                        <TableCell align="right">Cantidad</TableCell>
                        <TableCell align="right">Precio Lista</TableCell>
                        {tieneBonif1 && <TableCell align="right">Bonif. 1</TableCell>}
                        {tieneBonif2 && <TableCell align="right">Bonif. 2</TableCell>}
                        {tieneBonif3 && <TableCell align="right">Bonif. 3</TableCell>}
                        {tieneBonif4 && <TableCell align="right">Bonif. 4</TableCell>}
                        <TableCell align="right">Precio Uni.</TableCell>
                        <TableCell align="right">Precio Final</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {detalles.map((detalle, index) => {
                        const {
                            codArticulo,
                            descripcion,
                            umPrecio,
                            cantidad,
                            precioLista,
                            bonif1,
                            bonif2,
                            bonif3,
                            bonif4,
                            precioUnitario,
                            precioFinal
                        } = detalle;
                        return (
                            <TableRow key={index}>
                                <TableCell align="left">
                                    <Flex flexDirection={"column"}>
                                        <Text>{codArticulo}</Text>
                                        <Text color={"datagridLightText"} fontSize={"0.8em"}>
                                            {descripcion}
                                        </Text>
                                    </Flex>
                                </TableCell>
                                <TableCell align="right">
                                    {formatCant(cantidad)} {umPrecio}
                                </TableCell>
                                <TableCell align="right">
                                    {moneda} {formatImporte(precioLista)}
                                </TableCell>
                                {tieneBonif1 && <TableCell align="right">{formatImporte(bonif1)}%</TableCell>}
                                {tieneBonif2 && <TableCell align="right">{formatImporte(bonif2)}%</TableCell>}
                                {tieneBonif3 && <TableCell align="right">{formatImporte(bonif3)}%</TableCell>}
                                {tieneBonif4 && <TableCell align="right">{formatImporte(bonif4)}%</TableCell>}
                                <TableCell align="right">
                                    {moneda} {formatImporte(precioUnitario)}
                                </TableCell>
                                <TableCell align="right">
                                    {moneda} {formatImporte(precioFinal)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </ThemeProvider>
    );
};
