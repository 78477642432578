import React, { FC, useEffect, useState } from "react";
import { Box, Flex, Image, Text } from "rebass/styled-components";
import carritoIlustration from "../../assets/carrito-home.png";
import { Package as PackageIcon } from "react-feather";
import { Button } from "@kiwicom/orbit-components";
import BillingDetails from "@kiwicom/orbit-components/lib/icons/BillingDetails";
import Visibility from "@kiwicom/orbit-components/lib/icons/Visibility";
import Plus from "@kiwicom/orbit-components/lib/icons/Plus";
import { IResumenCarritoData, obtenerResumenCarrito } from "../api/HomeResumenCarritoApi";
import { formatCant, formatImporte } from "../../lib/format";
import { Redirect } from "react-router-dom";
import { rutas } from "../rutas";

export const HomeResumenCarrito: FC = () => {
    const [fetched, setFetched] = useState<boolean>(false);
    const [resumenCarrito, setResumenCarrito] = useState<IResumenCarritoData | null>(null);
    const [irANuevoPedido, setIrANuevoPedido] = useState<boolean>(false);
    const [irAlCarrito, setIrAlCarrito] = useState<boolean>(false);

    useEffect(() => {
        const fetch = async () => {
            const resp = await obtenerResumenCarrito();
            if (resp.ok && resp.output) {
                setResumenCarrito(resp.output.resumenCarrito);
                setFetched(true);
                return;
            }

            console.error("Error al obtener el resumen del carrito");
            console.error(resp);
        };

        fetch();
    }, []);

    if (irANuevoPedido) {
        return <Redirect to={rutas.nuevoPedido} push={true} />;
    }

    if (irAlCarrito) {
        return <Redirect to={rutas.confirmarPedido} push={true} />;
    }

    if (!fetched) {
        return <></>;
    }

    const width = resumenCarrito ? "27em" : "20em";
    return (
        <Flex sx={{ bg: "#EFF2F5", borderRadius: "0.25em", p: 3 }} fontSize={"0.95em"} ml={"auto"} width={width}>
            <Image src={carritoIlustration} height={"5em"} />
            {resumenCarrito ? (
                <ResumenCarrito
                    resumenCarrito={resumenCarrito}
                    irANuevoPedido={() => {
                        setIrANuevoPedido(true);
                    }}
                    irAlCarrito={() => {
                        setIrAlCarrito(true);
                    }}
                />
            ) : (
                <CarritoVacio
                    irANuevoPedido={() => {
                        setIrANuevoPedido(true);
                    }}
                />
            )}
        </Flex>
    );
};

const CarritoVacio: FC<{ irANuevoPedido: () => void }> = ({ irANuevoPedido }) => {
    return (
        <Flex flexDirection={"column"} ml={"2"} my={"auto"}>
            <Text fontSize={"1.2em"} mb={1}>
                Carrito Vacío
            </Text>
            <Button onClick={irANuevoPedido} size={"small"} type={"primary"}>
                ¡Empezar a Comprar!
            </Button>
        </Flex>
    );
};

const ResumenCarrito: FC<{
    resumenCarrito: IResumenCarritoData;
    irANuevoPedido: () => void;
    irAlCarrito: () => void;
}> = ({ resumenCarrito, irANuevoPedido, irAlCarrito }) => {
    const { cantPaquetes, cantArticulos, totalKilos, moneda, importeTotal } = resumenCarrito;
    return (
        <Flex flexDirection={"column"} ml={"auto"} fontSize={"0.95em"}>
            <Flex alignItems={"center"}>
                <PackageIcon size={22} />
                <Text ml={1}>
                    {cantPaquetes} Paquetes - {cantArticulos} Artículos - {formatCant(totalKilos)} kgs
                </Text>
            </Flex>
            <Flex alignItems={"center"} mt={1}>
                <BillingDetails />
                <Text ml={1}>
                    {moneda} {formatImporte(importeTotal)}
                </Text>
            </Flex>

            <Flex mt={1}>
                <Box mr={2}>
                    <Button onClick={irAlCarrito} iconLeft={<Visibility />} type={"white"} size={"small"}>
                        Ver Carrito
                    </Button>
                </Box>
                <Box>
                    <Button onClick={irANuevoPedido} iconLeft={<Plus />} size={"small"}>
                        Agregar Más Artículos
                    </Button>
                </Box>
            </Flex>
        </Flex>
    );
};
