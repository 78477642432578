import React, { FC, useEffect, useMemo, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { FetchStatus, getInitialState, useListFetcher } from "../../lib/useListFetcher";
import { ICCFiltros, ICCSaldo, ICCTotales, obtenerCuentaCorriente } from "../api/CuentaCorrienteApi";
import { Alert, Loading, Separator } from "@kiwicom/orbit-components";
import BillingDetailsIcon from "@kiwicom/orbit-components/lib/icons/BillingDetails";
import { FacturaDocModal } from "../facturas/FacturaDocModal";
import { RemitoDocModal } from "../remitos/RemitoDocModal";
import { CuentaCorrienteList } from "./CuentaCorrienteList";
import { PedidoDocModal } from "../pedidos/PedidoDocModal";
import { formatImporte } from "../../lib/format";
import { AplicacionDocModal } from "../aplicaciones/AplicacionDocModal";
import { TopBar } from "../../ui_components/ListView/TopBar";
import { useDebounce } from "use-debounce";
import { IFiltroActivo } from "../../ui_components/ListView/FiltrosActivos";
import { CuentaCorrienteListFiltros, obtenerFiltrosActivos } from "./CuentaCorrienteListFiltros";
import { Paginador } from "../../ui_components/Paginador";
import { ReciboDocModal } from "../recibos/ReciboDocModal";
import { NoHayDatos } from "../../ui_components/ListView/NoHayDatos";
import { descargasURLBase } from "../api/api";
import { getAuthToken } from "../LocalStorage";

export interface VerDocArgs {
    idFactura?: number;
    idRecibo?: number;
    idPedido?: number;
    idRemito?: number;
    idAplicacion?: number;
}

export const CuentaCorrientePage: FC = () => {
    const authToken = getAuthToken();
    const excelURLBase = `${descargasURLBase}/cuenta-corriente-excel?token=${authToken}`;
    const [fetcherState, { buscar, cambiarNroPagina, filtrar }] = useListFetcher<ICCSaldo, ICCFiltros, ICCTotales>(
        obtenerCuentaCorriente,
        getInitialState({ filtros: { verSoloDocsConSaldo: true }, cantPorPagina: 10 })
    );
    const [busqueda, setBusqueda] = useState("");
    const [busquedaDebounced] = useDebounce(busqueda, 1500);
    const [verFiltros, setVerFiltros] = useState(false);
    const filtrosActivos = useMemo<Array<IFiltroActivo>>(() => obtenerFiltrosActivos(fetcherState.filtros, filtrar), [
        fetcherState.filtros,
        filtrar
    ]);
    const [idFactura, setIdFactura] = useState<number | null>(null);
    const [idRecibo, setIdRecibo] = useState<number | null>(null);
    const [idRemito, setIdRemito] = useState<number | null>(null);
    const [idPedido, setIdPedido] = useState<number | null>(null);
    const [idAplicacion, setIdAplicacion] = useState<number | null>(null);

    useEffect(() => {
        buscar(busquedaDebounced);
    }, [buscar, busquedaDebounced]);

    const filtrarCallback = (filtros: ICCFiltros) => {
        filtrar(filtros);
        setVerFiltros(false);
    };

    const verDoc = (docVer: VerDocArgs) => {
        const { idFactura, idRecibo, idRemito, idPedido, idAplicacion } = docVer;

        if (idFactura) {
            setIdFactura(idFactura);
            return;
        }

        if (idRecibo) {
            setIdRecibo(idRecibo);
            return;
        }

        if (idRemito) {
            setIdRemito(idRemito);
            return;
        }

        if (idPedido) {
            setIdPedido(idPedido);
            return;
        }

        if (idAplicacion) {
            setIdAplicacion(idAplicacion);
            return;
        }
    };

    const ccSaldosDocumentosComponent = useMemo(() => {
        if (!fetcherState.data || fetcherState.data.length === 0) {
            return <NoHayDatos objeto={"documento"} />;
        }

        return <CuentaCorrienteList saldos={fetcherState.data} verDoc={verDoc} />;
    }, [fetcherState.data]);

    const ccTotalesComponent = useMemo(() => {
        if (fetcherState.totales) {
            return <Totales totales={fetcherState.totales} />;
        }

        return <></>;
    }, [fetcherState.totales]);

    return (
        <>
            {idFactura && <FacturaDocModal idFactura={idFactura} onClose={() => setIdFactura(null)} />}
            {idRecibo && <ReciboDocModal idRecibo={idRecibo} onClose={() => setIdRecibo(null)} />}
            {idRemito && <RemitoDocModal idRemito={idRemito} onClose={() => setIdRemito(null)} />}
            {idPedido && <PedidoDocModal idPedido={idPedido} onClose={() => setIdPedido(null)} />}
            {idAplicacion && <AplicacionDocModal idAplicacion={idAplicacion} onClose={() => setIdAplicacion(null)} />}
            {verFiltros && (
                <CuentaCorrienteListFiltros
                    cerrarCallback={() => setVerFiltros(false)}
                    filtros={fetcherState.filtros}
                    filtrarCallback={filtrarCallback}
                />
            )}
            <Flex sx={{ height: "100%", overflowY: "auto" }} flexDirection={"column"}>
                <Box sx={{ overflow: "auto", height: "90%" }}>
                    <Flex
                        sx={{
                            flexDirection: "column",
                            mx: "auto",
                            width: "63em"
                        }}
                    >
                        <Box mt={2}>
                            <Alert icon={true}>
                                <Text fontSize={2}>
                                    Como es de público conocimiento y en consecuencia de la aplicación de medidas tendientes a superar la pandemia de Covid, les informamos que hay operaciones administrativas que se están procesando con alguna demora. Sepan disculpar las molestias ocasionadas por esta situación.
                                </Text>
                            </Alert>
                        </Box>
                        <Box mb={2} mt={3}>
                            <Box css={{ flexShrink: 0 }}>
                                <TopBar
                                    busqueda={busqueda}
                                    exportarExcelProps={{
                                        urlBase: excelURLBase,
                                        cantMaxRegistros: 1000,
                                        nombreObjeto: "documentos",
                                        fetcherState: {
                                            busqueda: fetcherState.busqueda,
                                            filtros: fetcherState.filtros,
                                            paginador: fetcherState.paginador
                                        }
                                    }}
                                    filtrosActivos={filtrosActivos}
                                    mostrarFiltros={() => setVerFiltros(true)}
                                    setBusqueda={busqueda => setBusqueda(busqueda)}
                                    verCrear={false}
                                />
                                <Box my={3}>
                                    <Separator />
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            {fetcherState.status === FetchStatus.NOT_FETCHED && <></>}
                            {fetcherState.status === FetchStatus.FETCHING && (
                                <Loading loading={fetcherState.isLoading} type="boxLoader" text="Cargando..." />
                            )}
                            {fetcherState.status === FetchStatus.SUCCESS && ccSaldosDocumentosComponent}
                        </Box>
                        <Box mb={2}>
                            <Paginador
                                nroPagina={fetcherState.paginador.nroPagina}
                                cantPorPagina={fetcherState.paginador.cantPorPagina}
                                cantResultados={fetcherState.paginador.cantResultados}
                                cambiarPaginaCallback={nroPagina => {
                                    cambiarNroPagina(nroPagina);
                                }}
                                mt={3}
                            />
                        </Box>
                    </Flex>
                </Box>
                <Box sx={{ boxShadow: "0px -25px 85px 0px rgb(202, 195, 195)", height: "10%", zIndex: 10 }}>
                    {ccTotalesComponent}
                </Box>
            </Flex>
        </>
    );
};

const Totales: FC<{ totales: ICCTotales }> = ({ totales }) => {
    return (
        <Box
            sx={{
                height: "100%",
                width: "100%"
            }}
        >
            <Box
                sx={{
                    bg: "#2C89BD",
                    height: "80%",
                    width: "100%"
                }}
            >
                <Flex
                    sx={{
                        alignItems: "center",
                        color: "white",
                        height: "100%",
                        mx: "auto",
                        width: "60em"
                    }}
                >
                    <Box>
                        <BillingDetailsIcon />
                    </Box>
                    <Text fontSize={"1.3em"}>SALDO TOTAL</Text>
                    <Box ml={"auto"} pr={3}>
                        {totales.map((total, index) => {
                            return (
                                <Text fontSize={"1.3em"} fontWeight={600} key={index} textAlign={"right"}>
                                    {total.moneda} {formatImporte(total.importe)}
                                </Text>
                            );
                        })}
                    </Box>
                </Flex>
            </Box>
            <Box sx={{ bg: "#C4C4C4", height: "20%", width: "100%", mt: "auto" }} />
        </Box>
    );
};
