import React, { FC } from "react";
import { Box, Flex } from "rebass/styled-components";
import { Tag } from "@kiwicom/orbit-components";

export interface IFiltroActivo {
    descripcion: string;
    eliminarFiltro: () => void;
}

interface Props {
    mt?: number;
    filtrosActivos: Array<IFiltroActivo>;
}

export const FiltrosActivos: FC<Props> = ({ filtrosActivos, mt }) => {
    return (
        <Flex mt={mt}>
            {filtrosActivos.map(filtro => {
                return (
                    <Box key={filtro.descripcion} mr={1}>
                        <Tag onRemove={filtro.eliminarFiltro} size="small">
                            {filtro.descripcion}
                        </Tag>
                    </Box>
                );
            })}
        </Flex>
    );
};
