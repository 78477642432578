import React, { FC, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { Cantidades } from "../pedidos/Cantidades";
import { Package as PackageIcon, ShoppingCart as ShoppingCartIcon } from "react-feather";
import { Button } from "@kiwicom/orbit-components";
import { formatImporte } from "../../lib/format";
import { IPedido, IPedidoDetalle } from "../api/CarritoApi";
import { CarritoEditarCantidadesArticuloData } from "./CarritoEditarCantidadesArticulo";
import { Redirect } from "react-router-dom";
import { rutas } from "../rutas";
import { pedidoDetalleToEditarCantidadesHelper } from "./helpers";
import { SeparadorVertical } from "../../ui_components/SeparadorVertical";
import { CarritoImpuestosModal } from "./CarritoImpuestos";

interface Props {
    pedido: IPedido;
    onEditarArticulo: (data: CarritoEditarCantidadesArticuloData) => void;
}

export const CarritoSidebar: FC<Props> = ({ pedido, onEditarArticulo }) => {
    const editarArticuloCallback = (detalle: IPedidoDetalle) => {
        onEditarArticulo(pedidoDetalleToEditarCantidadesHelper(detalle, pedido));
    };

    return (
        <Flex
            sx={{
                bg: "#DCE3E9",
                flexDirection: "column",
                height: "90vh",
                ml: "2",
                p: "2",
                position: "sticky",
                top: "75px"
            }}
        >
            <Text size={2} textAlign={"center"}>
                PEDIDO
            </Text>
            <Box
                sx={{
                    height: "65vh",
                    overflowY: "auto",
                    "::-webkit-scrollbar-track": {
                        "border-radius": "6px",
                        "background-color": "#F5F5F5"
                    },
                    "::-webkit-scrollbar": {
                        width: "8px",
                        "background-color": "#F5F5F5"
                    },
                    "::-webkit-scrollbar-thumb": {
                        "border-radius": "8px",
                        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,.3)",
                        "background-color": "#BAC7D5"
                    }
                }}
                mt={3}
            >
                <DetallesPedido
                    moneda={pedido.moneda}
                    detalles={pedido.detalles}
                    onClickDetalle={editarArticuloCallback}
                />
            </Box>
            <Footer pedido={pedido} />
        </Flex>
    );
};

const DetallesPedido: FC<{
    moneda: string;
    detalles: Array<IPedidoDetalle>;
    onClickDetalle: (detalle: IPedidoDetalle) => void;
}> = ({ moneda, detalles, onClickDetalle }) => {
    const cantDetalles = detalles.length;
    return (
        <Flex flexDirection={"column"}>
            {detalles.map((detalle, index) => {
                const { id, articulo, cantidades, cantPaquetes } = detalle;
                return (
                    <Box
                        sx={{
                            bg: "white",
                            borderRadius: "0.25em",
                            mx: 0,
                            mb: index < cantDetalles - 1 ? 2 : 0,
                            p: 2,
                            "&:hover": {
                                bg: "#f5f7f9",
                                cursor: "pointer",
                                boxShadow: "large"
                            }
                        }}
                        key={id}
                        onClick={() => {
                            onClickDetalle(detalle);
                        }}
                    >
                        <Flex flexDirection={"column"}>
                            <Text fontSize={1}>{articulo.codigo}</Text>
                            <Text fontSize={2}>{articulo.descripcion}</Text>
                            <Flex alignItems={"flex-end"} justifyContent={"space-between"} mt={2}>
                                <Flex flexDirection={"column"} fontSize={"0.7em"}>
                                    <Flex mb={1}>
                                        <PackageIcon size={"1.1em"} />
                                        <Text ml={1}>
                                            {cantPaquetes} {cantPaquetes === 1 ? "paquete" : "paquetes"}
                                        </Text>
                                    </Flex>
                                    <Cantidades cant={cantidades} horizontal={true} />
                                </Flex>
                                <ImporteDetalle detalle={detalle} moneda={moneda} />
                            </Flex>
                        </Flex>
                    </Box>
                );
            })}
        </Flex>
    );
};

const ImporteDetalle: FC<{ moneda: string; detalle: IPedidoDetalle }> = ({
    moneda,
    detalle: { cantPaquetes, precioPaqueteSinDescuento4, porcDescuento4, precioFinal }
}) => {
    if (porcDescuento4 > 0) {
        const precioFinalSinDescuento = cantPaquetes * precioPaqueteSinDescuento4;
        return (
            <Flex flexDirection={"column"} mr={1}>
                <Text
                    color={"paletteRedNormal"}
                    fontSize={"0.80em"}
                    textAlign={"center"}
                    style={{ textDecoration: "line-through" }}
                >
                    {moneda} {formatImporte(precioFinalSinDescuento)}
                </Text>
                <Text fontSize={3} textAlign={"right"}>
                    {moneda} {formatImporte(precioFinal)}
                </Text>
            </Flex>
        );
    }

    return (
        <Text fontSize={3} textAlign={"right"}>
            {moneda} {formatImporte(precioFinal)}
        </Text>
    );
};

const Footer: FC<{ pedido: IPedido }> = ({ pedido }) => {
    const { moneda, subTotal, totalImpuestos, total } = pedido;
    const [redirect, setRedirect] = useState<boolean>(false);
    const [verImpuestos, setVerImpuestos] = useState<boolean>(false);

    if (redirect) {
        return <Redirect to={rutas.confirmarPedido} />;
    }
    return (
        <>
            {verImpuestos && (
                <CarritoImpuestosModal
                    cerrarCallback={() => {
                        setVerImpuestos(false);
                    }}
                    pedido={pedido}
                />
            )}
            <Flex flexDirection={"column"} mt={"auto"}>
                <Flex sx={{ bg: "white", borderRadius: "0.25em", justifyContent: "space-between", py: 2, px: 1 }}>
                    <TotalesImporte descripcion={"SUB TOTAL"} moneda={moneda} importe={subTotal} />
                    <SeparadorVertical />
                    <TotalesImporte
                        descripcion={"IMPUESTOS"}
                        moneda={moneda}
                        importe={totalImpuestos}
                        onClick={() => {
                            setVerImpuestos(true);
                        }}
                    />
                    <SeparadorVertical />
                    <TotalesImporte descripcion={"TOTAL"} moneda={moneda} importe={total} />
                </Flex>
                <Box mt={2}>
                    <Button
                        fullWidth={true}
                        circled={false}
                        iconLeft={<ShoppingCartIcon />}
                        onClick={() => {
                            setRedirect(true);
                        }}
                        size="normal"
                        tabIndex="0"
                        title={`Generar Pedido`}
                        type="primary"
                    >
                        Generar Pedido
                    </Button>
                </Box>
            </Flex>
        </>
    );
};

interface TotalesImporteProps {
    descripcion: string;
    moneda: string;
    importe: number;
    onClick?: () => void;
}

const TotalesImporte: FC<TotalesImporteProps> = ({ descripcion, moneda, importe, onClick }) => {
    const cssImporte = !onClick
        ? undefined
        : {
              "&:hover": {
                  cursor: "pointer",
                  textDecoration: "underline"
              }
          };

    return (
        <Flex flexDirection={"column"} ml={0}>
            <Text fontSize={"1em"}>{descripcion}</Text>
            <Text sx={cssImporte} color={"datagridResaltado"} fontSize={"1.1em"} onClick={onClick}>
                {moneda} {formatImporte(importe)}
            </Text>
        </Flex>
    );
};
