import React, { FC } from "react";
import { Box, Flex } from "rebass/styled-components";
import BillingDetailsIcon from "@kiwicom/orbit-components/lib/icons/BillingDetails";
import { ShoppingCart, Truck as TruckIcon } from "react-feather";
import { Panel } from "../../ui_components/Panel";
import { HomePedidosActivos } from "./HomePedidosActivos";
import { VencimientosFacturas } from "./HomeVencimientosFacturas";
import { HomeResumenCarrito } from "./HomeResumenCarrito";
import { HomeDespachos } from "./HomeDespachos";

export const HomePage: FC = () => {
    return (
        <Flex
            sx={{
                flexDirection: "column",
                my: 4,
                mx: "auto",
                flexShrink: 0
            }}
        >
            <Panel titulo={"Pedidos Activos"} tituloIcono={<ShoppingCart />} width={"63em"}>
                <Flex flexDirection={"column"}>
                    <Box mb={2}>
                        <HomeResumenCarrito />
                    </Box>

                    <HomePedidosActivos />
                </Flex>
            </Panel>

            <Panel titulo={"Vencimientos de Facturas"} tituloIcono={<BillingDetailsIcon />} width={"63em"} mt={3}>
                <VencimientosFacturas />
            </Panel>

            <Panel mt={3} titulo={"Despachos"} tituloIcono={<TruckIcon />}>
                <HomeDespachos />
            </Panel>
        </Flex>
    );
};
