import { IDomicilio } from "../types";
import { makeHttpRequest, POST, ResultBase } from "./api";

interface RequestData {
    idAplicacion: number;
}

export enum ErrorCodes {}

interface ICliente {
    nombre: string;
    cuit: string;
    domicilio: IDomicilio;
}

export interface IAplicacion {
    id: number;
    fecha: string;
    cliente: ICliente;
    moneda: string;
    detalles: IAplicacionDetalle[];
}

export interface IAplicacionDetalle {
    factura?: {
        id: number;
        nroFactura: string;
        claseDocumento: string;
    };
    recibo?: {
        id: number;
        nroRecibo: number;
        nroReciboProvisorio: number | null;
    };
    ajuste?: {
        id: number;
    };
    importeAplicacion: number;
}

interface ResponseOutput {
    aplicacion: IAplicacion;
}
export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const obtenerAplicacion = async (reqData): Promise<Result> =>
    makeHttpRequest<RequestData, ResponseOutput, ErrorCodes>(POST, "aplicacion-doc", reqData);
