import React, { FC, useMemo, useState } from "react";
import { PedidoData, PedidoDetalleData } from "../api/PedidosListApi";
import { Box, Flex, Text } from "rebass/styled-components";
import { Articulo, CodsEstadosPedidos } from "../types";
import { formatImporte } from "../../lib/format";
import { ColCantidades } from "./ColCantidades";
import { ColEstado } from "./ColEstado";
import { Documentos } from "./Documentos";
import { Cantidades } from "./Cantidades";
import { Button } from "@kiwicom/orbit-components";
import { Printer } from "react-feather";
import { PedidoDocModal } from "./PedidoDocModal";

interface Props {
    pedido: PedidoData;
}
export const ContenidoPedido: FC<Props> = ({ pedido }) => {
    const [verIdPedido, setVerIdPedido] = useState<number | null>(null);
    const contenidoPedidoComponent = useMemo(
        () => (
            <Flex fontSize={"0.9rem"}>
                <Flex alignItems={"center"} flexDirection={"column"} width={2 / 3}>
                    <Flex alignItems={"center"} width={1}>
                        <Text mx={"auto"}>DETALLES DEL PEDIDO</Text>
                        <Box>
                            <Button
                                iconLeft={<Printer />}
                                onClick={() => {
                                    setVerIdPedido(pedido.id);
                                }}
                                type={"secondary"}
                                size={"small"}
                            >
                                Ver/Imprimir Pedido
                            </Button>
                        </Box>
                    </Flex>

                    <Detalles pedido={pedido} />
                    <Totales pedido={pedido} />
                </Flex>
                <Flex alignItems={"center"} flexDirection={"column"} width={1 / 3}>
                    <Text>DESPACHOS</Text>
                    <Documentos pedido={pedido} />
                </Flex>
            </Flex>
        ),
        [pedido]
    );

    return (
        <>
            {verIdPedido && <PedidoDocModal idPedido={verIdPedido} onClose={() => setVerIdPedido(null)} />}
            {contenidoPedidoComponent}
        </>
    );
};

const Detalles: FC<{ pedido: PedidoData }> = ({ pedido }) => {
    return (
        <Flex flexDirection={"column"} width={1}>
            {pedido.detalles.map(detalle => {
                const tieneDespachos = detalle.cantidadDespachada.kilos > 0;
                const estaCerrado = detalle.codEstado === CodsEstadosPedidos.CERRADO;

                return (
                    <Flex sx={{ bg: "pedidoDetalleBg", borderRadius: "4px" }} key={detalle.id} my={2} p={3} width={1}>
                        <ColArticulo articulo={detalle.articulo} />
                        <ColNroLinea detalle={detalle} moneda={pedido.moneda} />

                        <Flex width={0.55}>
                            {tieneDespachos ? (
                                <ColCantidades
                                    cantidadPedido={detalle.cantidad}
                                    cantidadDespachada={detalle.cantidadDespachada}
                                    estaCerrado={estaCerrado}
                                    small={true}
                                />
                            ) : (
                                <>
                                    <Cantidades alignItems={"flex-start"} cant={detalle.cantidad} />
                                    <ColEstado codEstado={detalle.codEstado} ml={"auto"} />
                                </>
                            )}
                        </Flex>
                    </Flex>
                );
            })}
        </Flex>
    );
};

const ColArticulo: FC<{ articulo: Articulo }> = ({ articulo }) => {
    return (
        <Flex flexDirection={"column"} width={0.25}>
            <Text fontWeight={600}>{articulo.descripcion}</Text>

            <Text color={"datagridResaltado"} fontSize={"0.8em"} mr={1}>
                {articulo.codigo}
            </Text>

            {/*<Flex ml={"2em"}>
                <IconoForma idForma={articulo.idForma} />
            </Flex>*/}
        </Flex>
    );
};

const ColNroLinea: FC<{ detalle: PedidoDetalleData; moneda: string }> = ({ detalle, moneda }) => {
    const nroLinea = detalle.nroLinea;
    const importeTotal = formatImporte(detalle.precioFinal);

    return (
        <Flex flexDirection={"column"} mx={2} width={0.2}>
            <Text fontSize={"1em"} fontWeight={600}>
                Linea {nroLinea}
            </Text>
            <Text fontSize={"0.9em"} fontWeight={500}>
                {moneda} {importeTotal}
            </Text>
        </Flex>
    );
};

const Totales: FC<{ pedido: PedidoData }> = ({ pedido }) => {
    const { moneda, subTotal, importeIVA, importePercIVA, importePercIIBB, importeTotal } = pedido;

    return (
        <Flex justifyContent={"space-between"} mt={3} width={1}>
            <TotalesImporte descripcion={"SUB TOTAL"} moneda={moneda} importe={subTotal} separar={false} />
            <TotalesImporte descripcion={"IVA 21%"} moneda={moneda} importe={importeIVA} />
            <TotalesImporte descripcion={"PERC. IVA"} moneda={moneda} importe={importePercIVA} />
            <TotalesImporte descripcion={"PERC. IIBB"} moneda={moneda} importe={importePercIIBB} />
            <TotalesImporte descripcion={"TOTAL"} moneda={moneda} importe={importeTotal} />
        </Flex>
    );
};

interface TotalesImporteProps {
    descripcion: string;
    moneda: string;
    importe: number;
    separar?: boolean;
}

const TotalesImporte: FC<TotalesImporteProps> = ({ descripcion, moneda, importe, separar = true }) => {
    return (
        <Flex
            sx={{
                borderLeftWidth: "1px",
                borderLeftColor: "#8A8A8A",
                borderLeftStyle: separar ? "solid" : "none"
            }}
            flexDirection={"column"}
            ml={2}
            pl={2}
        >
            <Text fontSize={"1em"}>{descripcion}</Text>
            <Text color={"datagridResaltado"} fontSize={"1.2em"}>
                {moneda} {formatImporte(importe)}
            </Text>
        </Flex>
    );
};
