import React, { FC } from "react";
import { Flex } from "rebass/styled-components";
import { RemitosList } from "./RemitosList";

export const RemitosListPage: FC = () => {
    return (
        <Flex
            sx={{
                flexDirection: "column",
                flexGrow: 1,
                minWidth: "55em",
                mx: "auto",
                my: 3
            }}
        >
            <RemitosList />
        </Flex>
    );
};
