import React, { FC } from "react";
import { Text } from "rebass/styled-components";
import { Alert } from "@kiwicom/orbit-components";

interface Props {
    objeto: string;
    femenino?: boolean;
}

export const NoHayDatos: FC<Props> = ({ objeto, femenino }) => {
    const ningunIntl = femenino ? "ninguna" : "ningún";

    return (
        <Alert>
            <Text fontSize={4}>
                No hay {ningunIntl} {objeto} para mostrar.
            </Text>
        </Alert>
    );
};
