import React, { FC, useMemo } from "react";
import { SesionContainer } from "./Sesion";
import { AppLayout, ItemMenu } from "../ui_components/Layouts/AppLayout";
import * as LogOutApi from "./api/LogOutApi";
import * as Icon from "react-feather";
import { getTituloRuta, rutas } from "./rutas";
import { RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps {}

export const AppLayoutWrapper: FC<Props> = props => {
    const { children, match } = props;
    const { sesion, clearSesion } = SesionContainer.useContainer();

    const logout = async () => {
        const result = await LogOutApi.logout();
        if (!result.ok) {
            console.error("Error al deslogearse!");
            console.error(result);
        }

        clearSesion();
    };

    const { usuario, cliente } = sesion;
    const nombreUsuario = usuario && usuario.email ? usuario.email : "";
    const nombreCliente = cliente && cliente.nombre ? cliente.nombre : "";

    const itemsMenu: Array<ItemMenu> = useMemo(() => {
        const items: Array<ItemMenu> = [
            {
                titulo: "Inicio",
                ruta: rutas.home,
                Icono: Icon.Home
            },
            {
                titulo: "Pedidos",
                ruta: rutas.pedidos,
                Icono: Icon.ShoppingCart
            },
            {
                titulo: "Cuenta Corriente",
                ruta: rutas.cuentaCorriente,
                Icono: Icon.BookOpen
            },
            {
                titulo: "Facturas",
                ruta: rutas.facturas,
                Icono: Icon.File
            },
            {
                titulo: "Remitos",
                ruta: rutas.remitos,
                Icono: Icon.File
            },
            {
                titulo: "Certificados",
                ruta: rutas.certificados,
                Icono: Icon.Award
            },
            {
                titulo: "Descargas",
                ruta: rutas.descargas,
                Icono: Icon.DownloadCloud
            },
            {
                titulo: "Contáctenos",
                ruta: rutas.contactenos,
                Icono: Icon.PhoneCall
            }
        ];

        if (usuario && usuario.esAdmin) {
            items.push({
                titulo: "Usuarios",
                ruta: rutas.usuarios,
                Icono: Icon.Users
            });
        }

        return items;
    }, [usuario]);

    const rutaActual = match.path;
    const tituloRuta = getTituloRuta(rutaActual);
    let itemsMenuConActivo = itemsMenu.map(itemMenu => {
        itemMenu.isActive = itemMenu.ruta === rutaActual;
        return itemMenu;
    });

    return (
        <AppLayout
            itemsMenu={itemsMenuConActivo}
            logout={logout}
            nombreCliente={nombreCliente}
            nombreUsuario={nombreUsuario}
            tituloRuta={tituloRuta}
        >
            {children}
        </AppLayout>
    );
};
