import React, { FC, Reducer, useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import { Alert, Button, InputStepper, Separator } from "@kiwicom/orbit-components";
import Modal, { ModalFooter, ModalHeader, ModalSection } from "@kiwicom/orbit-components/lib/Modal";
import EditIcon from "@kiwicom/orbit-components/lib/icons/Edit";
import PlusCircleIcon from "@kiwicom/orbit-components/lib/icons/PlusCircle";
import CheckCircleIcon from "@kiwicom/orbit-components/lib/icons/CheckCircle";
import RemoveIcon from "@kiwicom/orbit-components/lib/icons/Remove";
import CloseIcon from "@kiwicom/orbit-components/lib/icons/Close";
import { Box, Flex, Text } from "rebass/styled-components";
import { editarArticuloCarrito, ErrorCodes } from "../api/CarritoEditarArticuloApi";
import { Cantidades } from "../pedidos/Cantidades";
import { formatImporte } from "../../lib/format";
import { ICantidades } from "../types";
import { quitarArticuloCarrito } from "../api/CarritoQuitarArticuloApi";

interface State {
    guardando: boolean;
    quitando: boolean;
    msgError?: string;
    cantPaquetes: number;
    cantidades: ICantidades;
    importe: number;
    precioFinalPaquete: number;
    cantidadesEstandard: ICantidades;
}

enum ActionsTypes {
    CAMBIAR_CANT_PAQUETES,
    GUARDAR,
    GUARDAR_ERROR,
    GUARDAR_SUCCESS,
    QUITAR,
    QUITAR_ERROR,
    QUITAR_SUCCESS
}
type Action =
    | { type: ActionsTypes.CAMBIAR_CANT_PAQUETES; cantPaquetes: number }
    | { type: ActionsTypes.GUARDAR }
    | { type: ActionsTypes.GUARDAR_ERROR; msgError: string }
    | { type: ActionsTypes.GUARDAR_SUCCESS }
    | { type: ActionsTypes.QUITAR }
    | { type: ActionsTypes.QUITAR_ERROR; msgError: string }
    | { type: ActionsTypes.QUITAR_SUCCESS };

const reducer = (state: State, action: Action): State => {
    const newState = Object.assign({}, state);

    switch (action.type) {
        case ActionsTypes.CAMBIAR_CANT_PAQUETES:
            const { cantPaquetes } = action;
            const { cantidadesEstandard, precioFinalPaquete } = state;

            let nuevasCantidades: ICantidades = {
                unidades: 0,
                kilos: 0,
                metros: 0
            };

            if (cantidadesEstandard.unidades) {
                nuevasCantidades.unidades = Math.round(cantidadesEstandard.unidades * cantPaquetes);
            }

            if (cantidadesEstandard.kilos) {
                nuevasCantidades.kilos = Math.round(cantidadesEstandard.kilos * cantPaquetes);
            }

            if (cantidadesEstandard.metros) {
                nuevasCantidades.metros = Math.round(cantidadesEstandard.metros * cantPaquetes * 10) / 10;
            }

            newState.cantPaquetes = cantPaquetes;
            newState.cantidades = nuevasCantidades;
            newState.importe = precioFinalPaquete * cantPaquetes;
            return newState;

        case ActionsTypes.GUARDAR:
            newState.guardando = true;
            return newState;

        case ActionsTypes.GUARDAR_ERROR:
            newState.guardando = false;
            newState.msgError = action.msgError;
            return newState;

        case ActionsTypes.GUARDAR_SUCCESS:
            newState.guardando = false;
            newState.msgError = undefined;
            return newState;

        case ActionsTypes.QUITAR:
            newState.quitando = true;
            return newState;

        case ActionsTypes.QUITAR_ERROR:
            newState.quitando = false;
            newState.msgError = action.msgError;
            return newState;

        case ActionsTypes.QUITAR_SUCCESS:
            newState.quitando = false;
            newState.msgError = undefined;
            return newState;

        default:
            throw new Error();
    }
};

const getInitialState = (
    cantPaquetes: number,
    cantidades: ICantidades,
    importe: number,
    cantidadesEstandard: ICantidades,
    precioFinalPaquete: number
): State => {
    return {
        guardando: false,
        quitando: false,
        cantPaquetes,
        cantidades,
        importe,
        cantidadesEstandard,
        precioFinalPaquete
    };
};

export interface CarritoEditarCantidadesArticuloData {
    idPedidoDetalle?: number;
    idListaPreciosDetalle: number;
    codArticulo: string;
    descripcion: string;
    cantPaquetes: number;
    cantidades: ICantidades;
    moneda: string;
    importe: number;
    cantidadesEstandard: ICantidades;
    precioListaPaquete: number;
    porcDescuento1: number;
    porcDescuento2: number;
    porcDescuento3: number;
    porcDescuento4: number;
    precioFinalPaquete: number;
}

interface Props extends CarritoEditarCantidadesArticuloData {
    cerrarCallback: () => void;
    successCallback: () => void;
}

export const CarritoEditarCantidadesArticulo: FC<Props> = ({
    cerrarCallback,
    successCallback,
    idListaPreciosDetalle,
    idPedidoDetalle,
    codArticulo,
    descripcion,
    moneda,
    cantidadesEstandard,
    precioListaPaquete,
    porcDescuento1,
    porcDescuento2,
    porcDescuento3,
    porcDescuento4,
    precioFinalPaquete,
    ...props
}) => {
    const initialState = useMemo(
        () =>
            getInitialState(
                props.cantPaquetes,
                props.cantidades,
                props.importe,
                cantidadesEstandard,
                precioFinalPaquete
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, initialState);

    const { cantPaquetes, cantidades, importe } = state;

    const guardar = useCallback(async () => {
        if (state.guardando) return;

        dispatch({ type: ActionsTypes.GUARDAR });

        const result = await editarArticuloCarrito({ idListaPreciosDetalle, cantPaquetes });
        if (!result.ok) {
            const { errorCode } = result;
            let msgError = "";
            if (errorCode && errorCode === ErrorCodes.CANT_PAQUETES_INCORRECTA) {
                msgError = "La cant. de paquetes ingresada es incorrecta. Debe ser un número entero positivo";
            } else {
                msgError = "Hubo un error al guardar. Por favor reintente más tarde.";
            }

            return dispatch({ type: ActionsTypes.GUARDAR_ERROR, msgError });
        }

        dispatch({ type: ActionsTypes.GUARDAR_SUCCESS });
        successCallback();
    }, [state.guardando, idListaPreciosDetalle, cantPaquetes, successCallback]);

    const quitar = useCallback(async () => {
        if (state.quitando || !idPedidoDetalle) return;

        dispatch({ type: ActionsTypes.QUITAR });

        const result = await quitarArticuloCarrito({ idPedidoDetalle });
        if (!result.ok) {
            return dispatch({
                type: ActionsTypes.QUITAR_ERROR,
                msgError: "Hubo un error al quitar el artículo. Por favor reintente más tarde."
            });
        }

        dispatch({ type: ActionsTypes.QUITAR_SUCCESS });
        successCallback();
    }, [state.quitando, idPedidoDetalle, successCallback]);

    // Auto-Select
    const inputEl = useRef<HTMLInputElement>(null);
    useEffect(() => {
        setTimeout(() => {
            inputEl && inputEl.current && inputEl.current.select();
        }, 200);
    }, [inputEl]);

    return (
        <Modal fixedFooter={false} isMobileFullPage={false} onClose={cerrarCallback} size={"small"}>
            <ModalHeader
                title={
                    idPedidoDetalle ? (
                        <Flex alignItems={"center"}>
                            <EditIcon />
                            <Text ml={1}>Modificar Pedido</Text>
                        </Flex>
                    ) : (
                        <Flex alignItems={"center"}>
                            <PlusCircleIcon />
                            <Text ml={1}>Agregar al Pedido</Text>
                        </Flex>
                    )
                }
                suppressed={true}
            />
            <ModalSection suppressed={false}>
                {state.msgError && (
                    <Box mb={4}>
                        <Alert type="critical" title={null} icon={true}>
                            {state.msgError}
                        </Alert>
                    </Box>
                )}

                <Flex flexDirection={"column"}>
                    <Row
                        label={"Cant. de Paquetes"}
                        Component={
                            <Box width={"9em"}>
                                <InputStepper
                                    defaultValue={cantPaquetes}
                                    disabled={false}
                                    onBlur={e => {
                                        dispatch({
                                            type: ActionsTypes.CAMBIAR_CANT_PAQUETES,
                                            cantPaquetes: parseInt(e.target.value)
                                        });
                                    }}
                                    onChange={cantPaquetes => {
                                        dispatch({ type: ActionsTypes.CAMBIAR_CANT_PAQUETES, cantPaquetes });
                                    }}
                                    minValue={1}
                                    size={"normal"}
                                    ref={inputEl}
                                    required={false}
                                    titleIncrement="Agregar Paquete"
                                    titleDecrement="Quitar Paquete"
                                />
                            </Box>
                        }
                    />

                    <Row
                        label={"Artículo"}
                        Component={
                            <Box>
                                <Text fontSize={2}>{codArticulo}</Text>
                                <Text fontSize={3}>{descripcion}</Text>
                            </Box>
                        }
                        my={3}
                    />

                    <Row
                        label={"Cantidades"}
                        Component={
                            <Box fontSize={"0.9em"}>
                                <Cantidades color={"#252A31"} cant={cantidades} horizontal={true} />
                            </Box>
                        }
                        my={2}
                    />

                    <Row
                        label={"Precio de Lista"}
                        Component={
                            <Text fontSize={"0.9em"}>
                                {moneda} {formatImporte(precioListaPaquete)}
                            </Text>
                        }
                        my={2}
                    />

                    <Row
                        label={"Bonificaciones"}
                        Component={
                            <Flex
                                fontSize={"0.9em"}
                                /*fontSize={"0.9em"} justifyContent={"space-between"}  width={"10em"}*/
                            >
                                {porcDescuento1 > 0 && <Text>{porcDescuento1}%</Text>}
                                {porcDescuento2 > 0 && <Text ml={2}>| {porcDescuento2}%</Text>}
                                {porcDescuento3 > 0 && <Text ml={2}>| {porcDescuento3}%</Text>}
                                {porcDescuento4 > 0 && <Text ml={2}>| {porcDescuento4}%</Text>}
                            </Flex>
                        }
                        my={2}
                    />

                    <Box my={2}>
                        <Separator />
                    </Box>

                    <Row
                        label={"Importe"}
                        Component={
                            <Text color={"#252A31"} fontSize={"1.2em"} fontWeight={600}>
                                {moneda} {formatImporte(importe)}
                            </Text>
                        }
                    />
                </Flex>
            </ModalSection>
            <ModalFooter fixed={true} flex={["0 0 auto", "1 1 100%"]} suppressed={true}>
                <Button
                    fullWidth={true}
                    disabled={state.guardando}
                    iconLeft={<CheckCircleIcon />}
                    onClick={guardar}
                    type={"primary"}
                >
                    {state.guardando ? "Guardando..." : "Guardar"}
                </Button>
                <Flex>
                    {idPedidoDetalle && (
                        <Box mr={2}>
                            <Button
                                fullWidth={true}
                                disabled={state.quitando}
                                iconLeft={<RemoveIcon />}
                                onClick={quitar}
                                size={"normal"}
                                type={"white"}
                            >
                                {state.quitando ? "Quitando..." : "Quitar"}
                            </Button>
                        </Box>
                    )}

                    <Box>
                        <Button iconLeft={<CloseIcon />} onClick={cerrarCallback} size={"normal"} type="secondary">
                            Cerrar
                        </Button>
                    </Box>
                </Flex>
            </ModalFooter>
        </Modal>
    );
};

const Row: FC<{ label: string; Component: any; my?: number }> = ({ label, Component, my = 0 }) => {
    return (
        <Flex alignItems={"center"} my={my}>
            <Text color={"#5F738C"} fontSize={"0.9em"} mr={3} textAlign={"right"} width={"10em"}>
                {label}:
            </Text>
            <Box color={"#353a41"}>{Component}</Box>
        </Flex>
    );
};
