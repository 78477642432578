import React, { FC, useEffect, useState } from "react";
import * as ActivarSesionApi from "../api/ActivarSesionApi";
import { Redirect } from "react-router";
import { SesionContainer } from "../Sesion";
import { ErrorCodes } from "../api/ActivarSesionApi";
import { saveAuthToken } from "../LocalStorage";
import { Box, Flex, Text } from "rebass/styled-components";
import { XCircle as XCircleIcon } from "react-feather";
import { Loading } from "@kiwicom/orbit-components";

export const ActivarSesionPage: React.FC = () => {
    const [msgError, setMsgError] = useState<string | null>(null);
    const [redirectTo, setRedirectTo] = useState<string | null>(null);
    const { setAuthToken } = SesionContainer.useContainer();

    useEffect(() => {
        const activarSesion = async () => {
            let params = new URLSearchParams(location.search); /* eslint no-restricted-globals: 0 */
            const token = params.get("cod");
            if (!token) {
                setMsgError("URL Inválida");
                return null;
            }

            const result = await ActivarSesionApi.activarSesion({ token });

            if (result.ok && result.output && result.output.token) {
                saveAuthToken(token);
                setAuthToken(token);
                return;
            }

            switch (result.errorCode) {
                case ErrorCodes.TOKEN_INCORRECTO:
                    setMsgError("Link de activación incorrecto.");
                    return;

                case ErrorCodes.TOKEN_EXPIRADO:
                    setMsgError("Link de activación expirado. Se redireccionará al login para que vuelva a ingresar.");
                    setTimeout(() => {
                        setRedirectTo("/login");
                    }, 5000);
                    return;

                default:
                    break;
            }

            setMsgError("Hubo un error desconocido");
        };

        activarSesion();
    }, [setAuthToken]);

    if (redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    if (msgError) {
        return <MsgError msg={msgError} />;
    }

    return <Loading loading={true} type="boxLoader" text="Activando sesión..." />;
};

const MsgError: FC<{ msg: string }> = ({ msg }) => {
    return (
        <Flex alignItems={"center"} flexDirection={"column"} p={3} width={"25em"}>
            <Box className={"animated heartBeat"} color={"iconColorError"}>
                <XCircleIcon size={100} />
            </Box>

            <Box className={"animated zoomIn"} style={{ textAlign: "center" }}>
                <Text color={"colorTextError"} fontSize={"2em"} mt={2} mb={2}>
                    {msg}
                </Text>
            </Box>
        </Flex>
    );
};
