import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./Router";
import "animate.css";
import { SesionContainer } from "./Sesion";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { normalize } from "styled-normalize";
import { theme } from "./theme";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "./datePicker.scss";
import { CarritoContainer } from "./nuevos-pedidos/CarritoContainer";
registerLocale("es", es);
setDefaultLocale("es");

// const basename = process.env.NODE_ENV === "production" ? "/ecommerce_test" : "/";
const basename = "/";

const GlobalStyle = createGlobalStyle`
  ${normalize}

  // You can continue writing global styles
  body {
    padding: 0;
    font-family: 'Roboto Condensed', sans-serif;   
  }
`;

const App: React.FC = () => {
    return (
        <>
            <GlobalStyle />
            <BrowserRouter basename={basename}>
                <SesionContainer.Provider>
                    <CarritoContainer.Provider>
                        <ThemeProvider theme={theme}>
                            <Router />
                        </ThemeProvider>
                    </CarritoContainer.Provider>
                </SesionContainer.Provider>
            </BrowserRouter>
        </>
    );
};

export default App;
