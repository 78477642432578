import React, { FC, ReactNode, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import ChevronUpIcon from "@kiwicom/orbit-components/lib/icons/ChevronUp";
import ChevronDownIcon from "@kiwicom/orbit-components/lib/icons/ChevronDown";

interface Props {
    titulo: string;
    tituloIcono: ReactNode | undefined;
    mt?: number | string | undefined;
    width?: number | string | undefined;
}

export const Panel: FC<Props> = ({ children, titulo, tituloIcono, mt, width }) => {
    const [isExpanded, setExpanded] = useState<boolean>(true);

    return (
        <Flex
            sx={{
                borderRadius: "0.15em 0.15em 0.15em 0.15em",
                color: "#6c6c6c",
                bg: "white",
                boxShadow: "medium",
                flexDirection: "column",
                flexGrow: 1,
                mb: "auto",
                mt: mt,
                width
            }}
        >
            <Flex
                sx={{
                    borderBottom: "1px solid #8a8a8a33",
                    fontSize: "1.2em",
                    p: 3,
                    "&:hover": {
                        cursor: "pointer"
                    }
                }}
                onClick={() => setExpanded(!isExpanded)}
            >
                {tituloIcono}
                <Text ml={1}>{titulo}</Text>
                <Box ml={"auto"}>{isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}</Box>
            </Flex>
            {isExpanded && <Box p={3}>{children}</Box>}
        </Flex>
    );
};
