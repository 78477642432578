import React, { FC, useEffect, useRef, useState } from "react";
import { Box, Flex } from "rebass/styled-components";
import { Button, Portal, Separator, InputField } from "@kiwicom/orbit-components";
import Modal, { ModalHeader, ModalSection } from "@kiwicom/orbit-components/lib/Modal";
import CheckCircle from "@kiwicom/orbit-components/lib/icons/CheckCircle";
import { guardarOrdenCompra } from "../api/CarritoGuardarOrdenCompraApi";

interface Props {
    ordenCompra: string;
    onGuardar: () => void;
    onClose: () => void;
}

export const ConfirmarPedidoEditarOrdenCompraModal: FC<Props> = ({
    ordenCompra: ordenCompraProps,
    onGuardar,
    onClose
}) => {
    const [ordenCompra, setOrdenCompra] = useState<string>(ordenCompraProps || "");
    const [guardando, setGuardando] = useState<boolean>(false);
    const inputEl = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setTimeout(() => {
            inputEl && inputEl.current && inputEl.current.select();
        }, 200);
    }, []);

    const guardar = async () => {
        setGuardando(true);
        const resp = await guardarOrdenCompra({ ordenCompra });
        if (!resp.ok) {
            console.error("Hubo un error al guardar la oc");
        }

        setGuardando(false);
        onGuardar();
    };

    const cancelar = () => {
        onClose();
    };

    return (
        <Portal>
            <Modal fixedFooter={false} isMobileFullPage={true} onClose={onClose} size={"small"}>
                <ModalHeader title={"Orden de Compra"} suppressed={true} />
                <ModalSection suppressed={false}>
                    <Flex flexDirection={"column"}>
                        <InputField
                            disabled={guardando}
                            label={null}
                            onChange={e => {
                                const ordenCompra = e.target.value;
                                setOrdenCompra(ordenCompra);
                            }}
                            placeholder={"Orden de Compra Interna..."}
                            ref={inputEl}
                            value={ordenCompra}
                            maxLength={20}
                        />
                        <Box my={3}>
                            <Separator />
                        </Box>

                        <Flex>
                            <Button onClick={guardar} disabled={guardando} iconLeft={<CheckCircle />} type={"primary"}>
                                {guardando ? "Guardando..." : "Guardar"}
                            </Button>
                            <Box ml={2}>
                                <Button disabled={guardando} onClick={cancelar} type={"secondary"}>
                                    Cancelar
                                </Button>
                            </Box>
                        </Flex>
                    </Flex>
                </ModalSection>
            </Modal>
        </Portal>
    );
};
