import { ResultBase, makeHttpRequest, POST } from "./api";

export enum ErrorCodes {}
// CLIENTE_SIN_ADMINISTRADOR = "CLIENTE_SIN_ADMINISTRADOR"

export interface RequestData {
    idUsuario: number;
}

export interface ResponseData {}
export interface Response extends ResultBase<ResponseData, ErrorCodes> {}

export const inactivarUsuario = async (reqData: RequestData): Promise<Response> =>
    makeHttpRequest<{}, ResponseData, ErrorCodes>(POST, "usuarios-inactivar-usuario", reqData);
