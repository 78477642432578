import React, { useReducer } from "react";
import { ErrorCodes, cambiarPassword, Response } from "../api/CambiarPasswordApi";
import { Box, Flex, Image, Text } from "rebass/styled-components";
import { Alert, Button, InputField } from "@kiwicom/orbit-components";
import LockIcon from "@kiwicom/orbit-components/lib/icons/Lock";
import SendIcon from "@kiwicom/orbit-components/lib/icons/Send";
import logoEcommerce from "../../assets/logo-ecommerce.png";

interface State {
    success: boolean;
    linkIngreso?: string;
    msgError: string | null;
    form: {
        submitting: boolean;
        password: {
            error: string | null;
            value: string;
        };
        password2: {
            error: string | null;
            value: string;
        };
    };
}

enum ActionTypes {
    SET_PASSWORD = "SET_PASSWORD",
    SET_PASSWORD_2 = "SET_PASSWORD_2",
    SET_SUBMITTING = "SET_SUBMITTING",
    CLEAR_ERRORS = "CLEAR_ERRORS",
    SET_ERRORS = "SET_ERRORS",
    SET_MSG_ERROR = "SET_MSG_ERROR",
    SUBMIT_RESPONSE = "SUBMIT_RESPONSE"
}

interface Errors {
    password?: string | null;
    password2?: string | null;
}

type Action =
    | { type: ActionTypes.SET_PASSWORD; password: string }
    | { type: ActionTypes.SET_PASSWORD_2; password: string }
    | { type: ActionTypes.CLEAR_ERRORS }
    | { type: ActionTypes.SET_MSG_ERROR; msg: string }
    | { type: ActionTypes.SET_ERRORS; errors: Errors }
    | { type: ActionTypes.SUBMIT_RESPONSE; result: Response }
    | { type: ActionTypes.SET_SUBMITTING; submitting: boolean };

const reducer = (state: State, action: Action): State => {
    const newState = Object.assign({}, state);

    switch (action.type) {
        case ActionTypes.SET_PASSWORD:
            newState.form.password.value = action.password;
            newState.form.password.error = null;
            return newState;

        case ActionTypes.SET_PASSWORD_2:
            newState.form.password2.value = action.password;
            newState.form.password2.error = null;
            return newState;

        case ActionTypes.SET_MSG_ERROR:
            newState.msgError = action.msg;
            return newState;

        case ActionTypes.SET_ERRORS:
            const { errors } = action;
            newState.form.password.error = errors.password ? errors.password : null;
            newState.form.password2.error = errors.password2 ? errors.password2 : null;
            return newState;

        case ActionTypes.CLEAR_ERRORS:
            newState.msgError = null;
            newState.form.password.error = null;
            newState.form.password2.error = null;
            return newState;

        case ActionTypes.SET_SUBMITTING:
            newState.form.submitting = action.submitting;
            return newState;

        case ActionTypes.SUBMIT_RESPONSE:
            const { result } = action;
            newState.form.submitting = false;
            if (result.ok && result.output) {
                newState.success = true;
                newState.linkIngreso = result.output.linkIngreso;
                return newState;
            }

            if (result.errorCode) {
                switch (result.errorCode) {
                    case ErrorCodes.COD_INCORRECTO:
                        newState.msgError = "El link de activación es incorrecto";
                        return newState;

                    case ErrorCodes.COD_EXPIRADO:
                        newState.msgError =
                            "El link de activación está expirado. Por favor, vuelva a solicitar la creación de su usuario.";
                        return newState;

                    case ErrorCodes.PASSWORD_DEBIL:
                        const msgPassDebil = seguridadPasswordText;
                        newState.msgError = msgPassDebil;
                        newState.form.password.error = msgPassDebil;
                        newState.form.password.value = "";
                        newState.form.password2.value = "";
                        return newState;
                    default:
                        break;
                }
            }

            newState.msgError = "Hubo un error desconocido";
            return newState;

        default:
            throw new Error();
    }
};

const validate = ({
    /*codCliente,
    cuit,*/
    password,
    password2
}: {
    /*codCliente: string;
    cuit: string;*/
    password: string;
    password2: string;
}): [boolean, Errors] => {
    const errors = {} as Errors;
    let hasErrors = false;

    if (!password) {
        errors.password = "La contraseña es obligatoria";
        errors.password2 = "La contraseña es obligatoria";
        hasErrors = true;
    }

    if (password !== password2) {
        errors.password2 = "Las contraseñas ingresadas no coinciden";
        hasErrors = true;
    }

    return [hasErrors, errors];
};

const initialState: State = {
    success: false,
    msgError: null,
    form: {
        submitting: false,
        password: {
            error: null,
            value: ""
        },
        password2: {
            error: null,
            value: ""
        }
    }
};

const seguridadPasswordText = "Debe contener números y letras con un mínimo 6 caracteres.";

export const CambiarPasswordPage: React.FC = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let params = new URLSearchParams(location.search); /* eslint no-restricted-globals: 0 */
        const codRestablecerPassword = params.get("cod");
        console.log("codRestablecerPassword: " + codRestablecerPassword);

        if (!codRestablecerPassword) {
            dispatch({ type: ActionTypes.SET_MSG_ERROR, msg: "URL Inválida" });
            return null;
        }

        dispatch({ type: ActionTypes.CLEAR_ERRORS });

        const [hasErrors, errors] = validate({
            password: state.form.password.value,
            password2: state.form.password2.value
        });

        if (hasErrors) {
            dispatch({ type: ActionTypes.SET_ERRORS, errors });
            return;
        }

        dispatch({ type: ActionTypes.SET_SUBMITTING, submitting: true });
        const result = await cambiarPassword({
            codRestablecerPassword,
            password: password.value
        });
        dispatch({ type: ActionTypes.SUBMIT_RESPONSE, result });
    };

    const {
        success,
        linkIngreso,
        msgError,
        form: { submitting, password, password2 }
    } = state;

    if (success && linkIngreso) {
        window.location.replace(linkIngreso);
        return <></>;
    }

    return (
        <Flex flexDirection={"column"} width={"25em"}>
            <Box mx={"auto"} width={"19em"}>
                <Image src={logoEcommerce} />
            </Box>
            <Box mb={4} mt={4}>
                <Text color={"textColorGray"} fontSize={"2em"} textAlign={"center"}>
                    Cambiar Contraseña
                </Text>
            </Box>
            <form onSubmit={handleSubmit}>
                {msgError && (
                    <Box mb={3}>
                        <Alert type="critical" title={null} icon={true}>
                            {msgError}
                        </Alert>
                    </Box>
                )}

                <Flex flexDirection={"column"}>
                    <Box mb={password.error ? 4 : 4}>
                        <InputField
                            disabled={submitting}
                            error={password.error}
                            help={seguridadPasswordText}
                            inlineLabel={true}
                            label="Contraseña"
                            name="password"
                            onChange={e =>
                                dispatch({
                                    type: ActionTypes.SET_PASSWORD,
                                    password: e.target.value
                                })
                            }
                            prefix={<LockIcon />}
                            placeholder={null}
                            required={true}
                            size="normal"
                            suffix={null}
                            type="password"
                            value={password.value}
                        />
                    </Box>

                    <Box mb={password2.error ? 4 : 2}>
                        <InputField
                            disabled={submitting}
                            error={password2.error}
                            help={null}
                            inlineLabel={true}
                            label="Repetir Contraseña"
                            name="password"
                            onChange={e =>
                                dispatch({
                                    type: ActionTypes.SET_PASSWORD_2,
                                    password: e.target.value
                                })
                            }
                            prefix={<LockIcon />}
                            placeholder={null}
                            required={true}
                            size="normal"
                            suffix={null}
                            type="password"
                            value={password2.value}
                        />
                    </Box>

                    <Box mt={2}>
                        <Button
                            fullWidth={true}
                            disabled={submitting}
                            iconLeft={<SendIcon />}
                            // onClick={guardar}
                            submit={true}
                            type={"primary"}
                        >
                            Cambiar Contraseña
                        </Button>
                    </Box>
                </Flex>
            </form>
        </Flex>
    );
};
