import React, { FC, Reducer, useEffect, useReducer } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { Button, Loading } from "@kiwicom/orbit-components";
import Breadcrumbs, { BreadcrumbsItem } from "@kiwicom/orbit-components/lib/Breadcrumbs";
import { obtenerFiltros, TipoFetch, TiposFiltros } from "../api/NuevoPedidoArticulosFiltrosApi";
import { chunkArray } from "../../lib/chunkArray";

interface IFiltro {
    id: number;
    descripcion: string;
    um?: string;
}

interface IState {
    loading: boolean;
    tipoFiltroActual: TipoFiltro;
    fetch: TipoFetch;
    grupo: {
        filtros: Array<IFiltro>;
        selected?: IFiltro;
    };
    subGrupo: {
        filtros: Array<IFiltro>;
        selected?: IFiltro;
    };
    medida: {
        filtros: Array<IFiltro>;
        selected?: IFiltro;
    };
    filtrar?: {
        idGrupo?: number;
        idSubGrupo?: number;
    };
}

enum Actions {
    FETCH_START,
    FILTRO_CLICK,
    CAMBIAR_FILTRO_ACTUAL,
    FETCH_GRUPOS_SUCCESS,
    FETCH_SUB_GRUPOS_SUCCESS,
    FETCH_MEDIDAS_SUCCESS,
    FETCH_ERROR
}

export type TipoFiltro = TiposFiltros.GRUPO | TiposFiltros.SUB_GRUPO | TiposFiltros.MEDIDA;

type Action =
    | { type: Actions.FETCH_START }
    | { type: Actions.FETCH_ERROR }
    | { type: Actions.FILTRO_CLICK; tipoFiltro: TipoFiltro; filtro: IFiltro }
    | { type: Actions.CAMBIAR_FILTRO_ACTUAL; tipoFiltro: TipoFiltro }
    | { type: Actions.FETCH_GRUPOS_SUCCESS; grupos: Array<IFiltro> }
    | { type: Actions.FETCH_SUB_GRUPOS_SUCCESS; subGrupos: Array<IFiltro> }
    | { type: Actions.FETCH_MEDIDAS_SUCCESS; medidas: Array<IFiltro> };

const reducer = (state: IState, action: Action): IState => {
    const newState = Object.assign({}, state);

    switch (action.type) {
        case Actions.FETCH_START:
            newState.loading = true;
            return newState;

        case Actions.FETCH_ERROR:
            newState.loading = false;
            return newState;

        case Actions.FILTRO_CLICK:
            const { tipoFiltro, filtro } = action;
            switch (tipoFiltro) {
                case TiposFiltros.GRUPO:
                    newState.grupo.selected = filtro;
                    newState.loading = true;
                    newState.fetch = { tipo: TiposFiltros.SUB_GRUPO, idGrupo: filtro.id };
                    break;

                case TiposFiltros.SUB_GRUPO:
                    newState.subGrupo.selected = filtro;
                    newState.loading = true;
                    newState.fetch = { tipo: TiposFiltros.MEDIDA, idSubGrupo: filtro.id };
                    break;

                case TiposFiltros.MEDIDA:
                    newState.medida.selected = filtro;
                    break;

                default:
                    break;
            }

            return newState;

        case Actions.CAMBIAR_FILTRO_ACTUAL:
            newState.tipoFiltroActual = action.tipoFiltro;
            return newState;

        case Actions.FETCH_GRUPOS_SUCCESS:
            newState.loading = false;
            newState.grupo = {
                filtros: action.grupos,
                selected: undefined
            };
            newState.tipoFiltroActual = TiposFiltros.GRUPO;
            return newState;

        case Actions.FETCH_SUB_GRUPOS_SUCCESS:
            newState.loading = false;
            newState.subGrupo = {
                filtros: action.subGrupos,
                selected: undefined
            };
            newState.tipoFiltroActual = TiposFiltros.SUB_GRUPO;
            return newState;

        case Actions.FETCH_MEDIDAS_SUCCESS:
            newState.loading = false;
            newState.medida = {
                filtros: action.medidas,
                selected: undefined
            };
            newState.tipoFiltroActual = TiposFiltros.MEDIDA;
            return newState;

        default:
            throw new Error();
    }
};

const initialState: IState = {
    loading: false,
    tipoFiltroActual: TiposFiltros.GRUPO,
    fetch: { tipo: TiposFiltros.GRUPO },

    grupo: {
        filtros: []
    },
    subGrupo: {
        filtros: []
    },
    medida: {
        filtros: []
    }
};

interface FiltrarCallbackParams {
    idGrupo?: number;
    idSubGrupo?: number;
    idMedida?: number;
}
export type FiltrarCallback = (filtros: FiltrarCallbackParams) => void;

interface Props {
    filtrarCallback: FiltrarCallback;
}

export const NuevoPedidoArticulosFiltros: FC<Props> = ({ filtrarCallback }) => {
    const [state, dispatch] = useReducer<Reducer<IState, Action>>(reducer, initialState);

    useEffect(() => {
        const fetch = async () => {
            const result = await obtenerFiltros(state.fetch);
            if (!result.ok || !result.output) {
                console.error("Error!");
                return dispatch({ type: Actions.FETCH_ERROR });
            }

            const { data } = result.output;
            switch (state.fetch.tipo) {
                case TiposFiltros.GRUPO:
                    dispatch({ type: Actions.FETCH_GRUPOS_SUCCESS, grupos: data });
                    break;
                case TiposFiltros.SUB_GRUPO:
                    dispatch({ type: Actions.FETCH_SUB_GRUPOS_SUCCESS, subGrupos: data });
                    break;
                case TiposFiltros.MEDIDA:
                    dispatch({ type: Actions.FETCH_MEDIDAS_SUCCESS, medidas: data });
                    break;
                default:
                    break;
            }
        };

        dispatch({ type: Actions.FETCH_START });
        fetch();
    }, [state.fetch]);

    if (state.loading) {
        return <Loading loading={true} type="boxLoader" text="Cargando..." />;
    }

    const { tipoFiltroActual, grupo, subGrupo, medida } = state;

    const getBreadcrumbsItems = (): Array<BreadcrumbItem> => {
        let breadcrumbsItems: Array<BreadcrumbItem> = [];
        const itemTodos = {
            descripcion: "TODOS",
            onClick: () => {
                dispatch({ type: Actions.CAMBIAR_FILTRO_ACTUAL, tipoFiltro: TiposFiltros.GRUPO });
                filtrarCallback({});
            }
        };

        let itemGrupo: BreadcrumbItem | null = null;
        if (grupo.selected) {
            const filtro = grupo.selected;
            const { descripcion } = filtro;
            itemGrupo = {
                descripcion,
                onClick: () => {
                    dispatch({ type: Actions.FILTRO_CLICK, tipoFiltro: TiposFiltros.GRUPO, filtro });
                    dispatch({ type: Actions.CAMBIAR_FILTRO_ACTUAL, tipoFiltro: TiposFiltros.SUB_GRUPO });
                    filtrarCallback({ idGrupo: filtro.id });
                }
            };
        }

        let itemSubGrupo: BreadcrumbItem | null = null;
        if (subGrupo.selected) {
            const filtro = subGrupo.selected;
            const { descripcion } = filtro;
            itemSubGrupo = {
                descripcion,
                onClick: () => {
                    dispatch({ type: Actions.FILTRO_CLICK, tipoFiltro: TiposFiltros.SUB_GRUPO, filtro });
                    dispatch({ type: Actions.CAMBIAR_FILTRO_ACTUAL, tipoFiltro: TiposFiltros.MEDIDA });
                    filtrarCallback({ idSubGrupo: filtro.id });
                }
            };
        }

        switch (tipoFiltroActual) {
            case TiposFiltros.GRUPO:
                break;

            case TiposFiltros.SUB_GRUPO:
                breadcrumbsItems.push(itemTodos);
                if (itemGrupo) {
                    breadcrumbsItems.push(itemGrupo);
                }
                /*breadcrumbsItems.push({
                    descripcion: "SUB GRUPO",
                    verDoc: () => {}
                });*/
                break;

            case TiposFiltros.MEDIDA:
                breadcrumbsItems.push(itemTodos);
                if (itemGrupo) {
                    breadcrumbsItems.push(itemGrupo);
                }
                if (itemSubGrupo) {
                    breadcrumbsItems.push(itemSubGrupo);
                }
                breadcrumbsItems.push({
                    descripcion: "MEDIDA",
                    onClick: () => {}
                });
                break;

            default:
                break;
        }

        return breadcrumbsItems;
    };

    const getFiltrosActuales = (): Array<IFiltro> => {
        if (tipoFiltroActual === TiposFiltros.GRUPO) {
            return grupo.filtros;
        }

        if (tipoFiltroActual === TiposFiltros.SUB_GRUPO) {
            return subGrupo.filtros;
        }

        if (tipoFiltroActual === TiposFiltros.MEDIDA) {
            return medida.filtros;
        }

        return [];
    };

    const filtroClick = (filtro: IFiltro) => {
        dispatch({ type: Actions.FILTRO_CLICK, tipoFiltro: tipoFiltroActual, filtro });

        let filtrarCallbackParms: FiltrarCallbackParams = {};

        switch (tipoFiltroActual) {
            case TiposFiltros.GRUPO:
                filtrarCallbackParms = {
                    idGrupo: filtro.id
                };
                break;
            case TiposFiltros.SUB_GRUPO:
                filtrarCallbackParms = {
                    idSubGrupo: filtro.id
                };
                break;
            case TiposFiltros.MEDIDA:
                filtrarCallbackParms = {
                    idSubGrupo: subGrupo.selected && subGrupo.selected.id,
                    idMedida: filtro.id
                };
                break;
        }

        filtrarCallback(filtrarCallbackParms);
    };

    return (
        <Flex flexDirection={"column"}>
            <BreadcrumbsFiltros items={getBreadcrumbsItems()} />

            <BotonesFiltros filtroClick={filtroClick} filtros={getFiltrosActuales()} />
        </Flex>
    );
};

const BotonesFiltros: FC<{ filtroClick: (filtro: IFiltro) => void; filtros: Array<IFiltro> }> = ({
    filtroClick,
    filtros
}) => {
    const filas = chunkArray<IFiltro>(filtros, 4); // Agrupar filtros en filas de 4
    return (
        <Flex flexDirection={"column"}>
            {filas.map((filtrosFila, indexFila) => {
                const width = 1 / filtrosFila.length;
                const mt = indexFila > 0 ? 2 : 0;
                return (
                    <Flex key={indexFila} mt={mt}>
                        {filtrosFila.map((filtro, index) => {
                            const ml = index > 0 ? 1 : 0;
                            return (
                                <Box key={filtro.id} ml={ml} width={width}>
                                    <Button
                                        onClick={() => filtroClick(filtro)}
                                        circled={false}
                                        fullWidth={true}
                                        bordered={false}
                                        type="secondary"
                                        size="large"
                                        tabIndex="0"
                                        title={`Filtro ${filtro.descripcion}`}
                                    >
                                        <Flex alignItems={"baseline"}>
                                            <Text>{filtro.descripcion}</Text>
                                            {filtro.um && (
                                                <Text color={"#6c6c6c"} fontSize={"0.7em"} ml={"2px"}>
                                                    {filtro.um}
                                                </Text>
                                            )}
                                        </Flex>
                                    </Button>
                                </Box>
                            );
                        })}
                    </Flex>
                );
            })}
        </Flex>
    );
};

/*const BotonFiltro: FC<{ verDoc: () => void; nombre: string }> = ({ verDoc, nombre }) => {
    return (
        <Button
            verDoc={verDoc}
            circled={false}
            fullWidth={true}
            bordered={false}
            type="secondary"
            size="large"
            tabIndex="0"
            title={`Filtro ${nombre}`}
        >
            {nombre}
        </Button>
    );
};*/

interface BreadcrumbItem {
    descripcion: string;
    onClick: () => void;
}

const BreadcrumbsFiltros: FC<{ items: Array<BreadcrumbItem> }> = ({ items }) => {
    if (items.length === 0) {
        return <></>;
    }

    return (
        <Box mb={2}>
            <Breadcrumbs>
                {items.map((item, index) => (
                    <BreadcrumbsItem key={index} href={"#"} onClick={item.onClick}>
                        {item.descripcion}
                    </BreadcrumbsItem>
                ))}
            </Breadcrumbs>
        </Box>
    );
};
