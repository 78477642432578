import { ResultBase, makeHttpRequest, POST } from "./api";
import { ICantidades, ILugarEntrega, ListRequestData, ListResponseOutputBase } from "../types";

export enum ErrorCodes {}

export interface IRemitosListFiltros {
    fechaDesde?: Date;
    fechaHasta?: Date;
}

export interface IRemitosListData {
    id: number;
    nroRemito: string;
    estado: string;
    fechaRemito: string;
    lugarEntrega: ILugarEntrega;
    cantPaquetes: number;
    totalDespachado: ICantidades;
}

interface ResponseOutput extends ListResponseOutputBase<IRemitosListData> {}

export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const obtenerRemitos = async (reqData: ListRequestData<IRemitosListFiltros>): Promise<Result> =>
    makeHttpRequest<ListRequestData<IRemitosListFiltros>, ResponseOutput, ErrorCodes>(POST, "remitos-list", reqData);
