import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { BarChart, Bar, CartesianGrid, Cell, Tooltip, XAxis, YAxis } from "recharts";
import { IDespachadoMes, obtenerDespachosGraficoData } from "../api/HomeDespachosGraficoApi";
import { Loading, Separator } from "@kiwicom/orbit-components";
import { IRemitosListFiltros } from "../api/RemitosListApi";
import { RemitosList } from "../remitos/RemitosList";
import { parse, format } from "date-fns";
import { es } from "date-fns/locale";

interface IGraficoData {
    descripcionMes: string;
    fechaDesde: string;
    fechaHasta: string;
    toneladas: number;
}

export const HomeDespachos: FC = () => {
    const [filtrosRemitos, setFiltrosRemitos] = useState<IRemitosListFiltros | null>(null);
    const onBarClick = useCallback((dataMes: IDespachadoMes) => {
        const filtrosRemitos: IRemitosListFiltros = {
            fechaDesde: parse(dataMes.fechaDesde, "dd/MM/yyyy", new Date()),
            fechaHasta: parse(dataMes.fechaHasta, "dd/MM/yyyy", new Date())
        };

        setFiltrosRemitos(filtrosRemitos);
    }, []);

    const remitosListComponent = useMemo(() => {
        return <RemitosList filtros={filtrosRemitos ? filtrosRemitos : undefined} cantPorPagina={5} />;
    }, [filtrosRemitos]);

    return (
        <Flex flexDirection={"column"} mt={3} width={1}>
            <GraficoDespachos onBarClick={onBarClick} />
            <Flex flexDirection={"column"}>
                <Box my={3}>
                    <Separator />
                </Box>

                <Text
                    sx={{
                        color: "titulo",
                        fontSize: "1.2em",
                        fontWeight: 600,
                        textAlign: "left",
                        mb: 1
                    }}
                >
                    Remitos
                </Text>

                <Box className={"animated fadeIn"} mt={2}>
                    {remitosListComponent}
                </Box>
            </Flex>
        </Flex>
    );
};
const GraficoDespachos: FC<{ onBarClick: (dataMes: IDespachadoMes) => void }> = ({ onBarClick }) => {
    const [cargando, setCargando] = useState<boolean>(true);
    const [dataGrafico, setDataGrafico] = useState<IGraficoData[] | null>(null);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    useEffect(() => {
        const fetchDataGrafico = async () => {
            const resp = await obtenerDespachosGraficoData();
            if (resp.ok && resp.output) {
                setDataGrafico(
                    resp.output.dataGrafico.map(data => {
                        const fechaDesde = parse(data.fechaDesde, "dd/MM/yyyy", new Date());
                        const descripcionMes = format(fechaDesde, "MMM yy", {
                            locale: es
                        });
                        return { ...data, descripcionMes };
                    })
                );
                setCargando(false);
                return;
            }

            console.error("Hubo un error al obtener los datos del gráfico");
            console.error(resp);
        };

        fetchDataGrafico();
    }, []);

    const handleBarClick = (dataMes: IDespachadoMes, index: number) => {
        setActiveIndex(index);
        onBarClick(dataMes);
    };

    if (cargando) {
        return <Loading loading={true} type="boxLoader" text="Cargando Datos del Gráfico..." />;
    }

    if (!dataGrafico || dataGrafico.length === 0) {
        return <></>;
    }

    return (
        <BarChart
            data={dataGrafico}
            width={900}
            height={250} /*width={600} height={300}  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}*/
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="descripcionMes" />
            <YAxis />
            <Tooltip />
            {/*<Legend />*/}
            <Bar
                barSize={18}
                dataKey="toneladas"
                fill="#8884d8"
                onClick={handleBarClick}
                name={"Cantidad Despachada en Toneladas"}
            >
                {dataGrafico.map((entry, index) => (
                    <Cell cursor="pointer" fill={index === activeIndex ? "#82ca9d" : "#8884d8"} key={`cell-${index}`} />
                ))}
            </Bar>
            {/*<Bar dataKey="pv" fill="#82ca9d" />*/}
        </BarChart>
    );
};
