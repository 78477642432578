import React, { FC, useMemo, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { Button, ButtonLink, InputField, ListChoice, Popover } from "@kiwicom/orbit-components";
import SearchIcon from "@kiwicom/orbit-components/lib/icons/Search";
import PlusCircleIcon from "@kiwicom/orbit-components/lib/icons/PlusCircle";
import DownloadIcon from "@kiwicom/orbit-components/lib/icons/Download";
import { Filter } from "react-feather";
import { FiltrosActivos, IFiltroActivo } from "./FiltrosActivos";
import { Link } from "react-router-dom";

interface Props {
    busqueda: string;
    crearLinkTo?: string;
    exportarExcelProps?: ExportarExcelProps;
    filtrosActivos?: Array<IFiltroActivo>;
    onCrearClick?: () => void;
    mostrarFiltros?: () => void;
    setBusqueda: (b: string) => void;
    verCrear?: boolean;
    textoCrear?: string;
}

interface ExportarExcelProps {
    cantMaxRegistros?: number;
    fetcherState: {
        busqueda?: string;
        filtros?: object;
        paginador: {
            nroPagina: number;
            cantPorPagina: number;
            cantResultados: number;
        };
    };
    nombreObjeto?: string;
    urlBase: string;
}

export const TopBar: FC<Props> = ({
    busqueda,
    crearLinkTo,
    exportarExcelProps,
    filtrosActivos,
    onCrearClick,
    mostrarFiltros,
    setBusqueda,

    textoCrear = "Nuevo",
    verCrear = false
}) => {
    return (
        <Flex flexDirection={"column"}>
            <Flex>
                <Box>
                    <InputField
                        autoComplete="off"
                        minLength={null}
                        maxLength={null}
                        onChange={e => setBusqueda(e.target.value)}
                        placeholder="Buscar..."
                        prefix={<SearchIcon />}
                        suffix={mostrarFiltros ? <FiltrosButton onClick={mostrarFiltros} /> : undefined}
                        size="normal"
                        value={busqueda}
                    />
                </Box>

                <Box my={"auto"} ml={"auto"}>
                    {verCrear && (
                        <CrearButton textoCrear={textoCrear} crearLinkTo={crearLinkTo} onCrearClick={onCrearClick} />
                    )}
                </Box>
                {exportarExcelProps && (
                    <Box ml={verCrear ? 2 : null}>
                        <ExportarExcel {...exportarExcelProps} />
                    </Box>
                )}
            </Flex>
            {filtrosActivos && <FiltrosActivos mt={2} filtrosActivos={filtrosActivos} />}
        </Flex>
    );
};

const ExportarExcel: FC<ExportarExcelProps> = ({
    urlBase,
    cantMaxRegistros = 1000,
    nombreObjeto = "registros",
    fetcherState
}) => {
    const [popoverOpened, setOpened] = useState(false);
    const {
        busqueda,
        filtros,
        paginador: { cantResultados, cantPorPagina, nroPagina }
    } = fetcherState;
    const cantPaginas = Math.round(cantResultados / cantPorPagina);
    const excelURL = useMemo(() => {
        let excelURL = urlBase;
        if (busqueda) {
            excelURL += `&busqueda=${busqueda}`;
        }
        if (filtros) {
            excelURL += `&filtros=${JSON.stringify(filtros)}`;
        }

        return excelURL;
    }, [urlBase, filtros, busqueda]);

    if (cantResultados === 0) {
        return <></>;
    }

    if (cantPaginas <= 1) {
        return <ExportarExcelButton href={excelURL} />;
    }

    const paginadorPrimeras10Paginas = JSON.stringify({
        nroPagina: 1,
        cantPorPagina: 100
    });

    const paginadorTodos = JSON.stringify({
        nroPagina: 1,
        cantPorPagina: cantMaxRegistros
    });

    return (
        <Popover
            content={
                <Flex flexDirection={"column"}>
                    <a
                        download={true}
                        href={`${excelURL}&paginador=${JSON.stringify({ nroPagina, cantPorPagina })}`}
                        style={{ textDecoration: "none" }}
                    >
                        <ListChoice
                            iconLeft={<DownloadIcon />}
                            onClick={() => {
                                setOpened(false);
                            }}
                            title={"Exportar página actual"}
                        />
                    </a>

                    {cantPaginas > 10 && (
                        <a
                            download={true}
                            href={`${excelURL}&paginador=${paginadorPrimeras10Paginas}`}
                            style={{ textDecoration: "none" }}
                        >
                            <ListChoice
                                iconLeft={<DownloadIcon />}
                                onClick={() => {
                                    setOpened(false);
                                }}
                                title={`Exportar primeras 10 páginas`}
                            />
                        </a>
                    )}

                    <a
                        download={true}
                        href={`${excelURL}&paginador=${paginadorTodos}`}
                        style={{ textDecoration: "none" }}
                    >
                        <ListChoice
                            iconLeft={<DownloadIcon />}
                            onClick={() => {
                                setOpened(false);
                            }}
                            title={"Exportar todas las páginas"}
                            description={`Máximo ${cantMaxRegistros} ${nombreObjeto}.`}
                        />
                    </a>
                </Flex>
            }
            opened={popoverOpened}
        >
            <Button
                download={true}
                iconLeft={<DownloadIcon />}
                onClick={() => {
                    setOpened(!popoverOpened);
                }}
                iconRight={null}
                size="normal"
                type="secondary"
            >
                Exportar a Excel
            </Button>
        </Popover>
    );
};

const ExportarExcelButton: FC<{ href?: string; onClick?: () => void }> = ({ href, onClick }) => {
    return (
        <Button
            download={true}
            href={href}
            iconLeft={<DownloadIcon />}
            iconRight={null}
            onClick={onClick}
            size="normal"
            type="secondary"
        >
            Exportar a Excel
        </Button>
    );
};

const FiltrosButton: FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
        <ButtonLink iconLeft={<Filter />} onClick={onClick} size={"small"} transparent={true} type={"secondary"}>
            <Text color={"colorIconSecondary"}>Filtros</Text>
        </ButtonLink>
    );
};

const CrearButton: FC<{ textoCrear: string; crearLinkTo?: string; onCrearClick?: () => void }> = ({
    textoCrear,
    crearLinkTo,
    onCrearClick
}) => {
    if (crearLinkTo) {
        return (
            <Link to={crearLinkTo} style={{ textDecoration: "none" }}>
                <Button iconLeft={<PlusCircleIcon />} iconRight={null} size="normal" type="primary">
                    {textoCrear}
                </Button>
            </Link>
        );
    }

    return (
        <Button onClick={onCrearClick} iconLeft={<PlusCircleIcon />} iconRight={null} size="normal" type="primary">
            {textoCrear}
        </Button>
    );
};
