import React, { FC, useState } from "react";
import { CheckCircle as CheckCircleIcon } from "react-feather";
import { Box, Flex, Text } from "rebass/styled-components";
import { rutas } from "../rutas";
import { Redirect } from "react-router";

export const BienvenidoPage: FC = () => {
    const [redirectTo, setRedirectTo] = useState<string | null>(null);

    if (!redirectTo) {
        setTimeout(() => {
            setRedirectTo(rutas.home);
        }, 2500);
    }

    if (redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    return (
        <Flex alignItems={"center"} flexDirection={"column"} p={3} width={"25em"}>
            <Box className={"animated heartBeat"} color={"iconColorSuccess"}>
                <CheckCircleIcon size={100} />
            </Box>

            <Box className={"animated zoomIn"} style={{ textAlign: "center" }}>
                <Text color={"textColorGray"} fontSize={"2em"} mt={2} mb={2}>
                    ¡Bienvenido a Tubos Argentinos eCommerce!
                </Text>
            </Box>
        </Flex>
    );
};
