import React, { FC, useEffect, useReducer } from "react";
import { Flex } from "rebass/styled-components";

interface State {
    count: number;
}
const reducer = (state: State, increment): State => {
    const newState = Object.assign({}, state);
    newState.count += increment;
    return newState;
};

export const PruebaPage: FC = () => {
    /* const [count, setCount] = useState(0);
    console.log("count: ", count);
    useEffect(() => {
        setCount(count + 1);
    }, []);*/

    const [state, increment] = useReducer(reducer, { count: 0 });
    const { count } = state;
    console.log("count: ", count);
    useEffect(() => {
        increment(1);
    }, []);

    return (
        <Flex
            sx={{
                alignItems: "center",
                // width: "40em",
                mx: "auto",
                my: "1em",
                p: 2
            }}
        >
            Prueba {count}
        </Flex>
    );
};
