import React, { FC, useState } from "react";
import { Box, Flex, Image, Text } from "rebass/styled-components";
import { Button } from "@kiwicom/orbit-components";
import Modal, { ModalHeader, ModalSection } from "@kiwicom/orbit-components/lib/Modal";
import Download from "@kiwicom/orbit-components/lib/icons/Download";
import NewWindow from "@kiwicom/orbit-components/lib/icons/NewWindow";
import { descargasURLBase } from "../api/api";
import { getAuthToken } from "../LocalStorage";

export const DescargasPage: FC = () => {
    const authToken = getAuthToken();
    const urlTeleventasEstructurales = `${descargasURLBase}/televentas-pdf?token=${authToken}&tipoTeleventas=ESTRUCTURALES`;
    const urlConduccion = `${descargasURLBase}/televentas-pdf?token=${authToken}&tipoTeleventas=CONDUCCION`;
    const urlLegajoImpositivo = `${descargasURLBase}/descargas?codigoDescarga=LEGAJO_IMPOSITIVO&token=${authToken}`;
    const urlPoliticaCalidad = `${descargasURLBase}/descargas?codigoDescarga=POLITICA_CALIDAD&token=${authToken}`;
    const [verChequesElecModal, setVerChequesElecModal] = useState(false);

    return (
        <Flex
            sx={{
                flexGrow: 1,
                mx: "auto",
                my: "1em",
                p: 2
            }}
        >
            {verChequesElecModal && (
                <Modal
                    isMobileFullPage={true}
                    onClose={() => {
                        setVerChequesElecModal(false);
                    }}
                    size={"large"}
                >
                    <ModalHeader title={"Cheques Electrónicos"} suppressed={true} />
                    <ModalSection suppressed={false}>
                        <Image src={"Cheques_Electronicos.png"} />
                    </ModalSection>
                </Modal>
            )}
            <Flex flexGrow={1} flexDirection={"column"}>
                <Text color={"titulo"} fontSize={"1.8em"} fontWeight={600} textAlign={"left"} mb={3}>
                    Televentas
                </Text>

                <Box sx={{ display: "inline-grid", width: "20em" }}>
                    <Button
                        download={true}
                        fullWidth={true}
                        href={urlTeleventasEstructurales}
                        iconLeft={<Download />}
                        type={"primary"}
                        size={"large"}
                    >
                        Televentas Estructurales
                    </Button>
                </Box>

                <Box sx={{ display: "inline-grid", width: "20em" }} mt={2}>
                    <Button
                        download={true}
                        fullWidth={true}
                        href={urlConduccion}
                        iconLeft={<Download />}
                        type={"primary"}
                        size={"large"}
                    >
                        Televentas Conducción
                    </Button>
                </Box>

                <Text color={"titulo"} fontSize={"1.8em"} fontWeight={600} textAlign={"left"} mb={3} mt={4}>
                    Política de Calidad
                </Text>

                <Box sx={{ display: "inline-grid", width: "20em" }}>
                    <Button
                        download={true}
                        fullWidth={true}
                        href={urlPoliticaCalidad}
                        iconLeft={<Download />}
                        type={"primary"}
                        size={"large"}
                    >
                        Política de Calidad
                    </Button>
                </Box>
            </Flex>

            <Flex flexDirection={"column"} ml={4}>
                <Text color={"titulo"} fontSize={"1.8em"} fontWeight={600} textAlign={"left"} mb={3}>
                    Información Impositiva
                </Text>

                <Box sx={{ display: "inline-grid", width: "20em" }}>
                    <Button
                        download={true}
                        href={urlLegajoImpositivo}
                        fullWidth={true}
                        iconLeft={<Download />}
                        type={"primary"}
                        size={"large"}
                    >
                        Información Impositiva
                    </Button>
                </Box>

                <Box sx={{ display: "inline-grid", width: "20em" }} mt={2}>
                    <Button
                        download={false}
                        onClick={() => {
                            setVerChequesElecModal(true);
                        }}
                        fullWidth={true}
                        iconLeft={<NewWindow />}
                        type={"primary"}
                        size={"large"}
                    >
                        Cheques Electrónicos
                    </Button>
                </Box>
            </Flex>
        </Flex>
    );
};
