import { useState } from "react";
import { createContainer } from "unstated-next";
import { IPedido, obtenerPedidoCarrito } from "../api/CarritoApi";

function useCarrito() {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [pedidoCarrito, setPedido] = useState<IPedido | null>(null);
    const fetch = async () => {
        setLoading(true);
        const result = await obtenerPedidoCarrito();
        if (!result.ok) {
            setError("Hubo un error al obtener los datos del carrito");
        } else {
            if (result.output && result.output.pedido) {
                setPedido(result.output.pedido);
            } else {
                setPedido(null);
            }
        }

        setLoading(false);
    };

    return { pedidoCarrito, loading, error, fetch };
}

export const CarritoContainer = createContainer(useCarrito);
