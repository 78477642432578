import { ResultBase, makeHttpRequest, POST } from "./api";

export enum ErrorCodes {}

export interface ResponseData {
    vendedor: IVendedor;
    analista: IAnalista;
    emailReclamos: string;
}

export interface IVendedor {
    nombreApellido: string;
    email: string;
    telefono: string;
}

export interface IAnalista {
    nombreApellido: string;
    email: string;
    telefono: string;
}

export interface Response extends ResultBase<ResponseData, ErrorCodes> {}

export const obtenerInformacionContacto = async (): Promise<Response> =>
    makeHttpRequest<{}, ResponseData, ErrorCodes>(POST, "contacto", {});
