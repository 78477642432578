import React, { FC, useEffect, useMemo, useState } from "react";
import { ICertificadosListData, ICertificadosListFiltros, obtenerCertificados } from "../api/CertificadosListApi";
import { CertificadosListTable } from "./CertificadosListTable";
import { Paginador } from "../../ui_components/Paginador";
import { FetchStatus, getInitialState, useListFetcher } from "../../lib/useListFetcher";
import { Box, Flex } from "rebass/styled-components";
import { Alert, Loading, Separator } from "@kiwicom/orbit-components";
import { TopBar } from "../../ui_components/ListView/TopBar";
import { useDebounce } from "use-debounce";
import { IFiltroActivo } from "../../ui_components/ListView/FiltrosActivos";
import { CertificadosListFiltros, obtenerFiltrosActivos } from "./CertificadosListFiltros";

interface CertificadosListProps {
    filtros?: ICertificadosListFiltros;
    cantPorPagina?: number;
}

export const CertificadosList: FC<CertificadosListProps> = ({ filtros: filtrosProps, cantPorPagina }) => {
    const [fetcherState, { buscar, cambiarNroPagina, filtrar }] = useListFetcher<
        ICertificadosListData,
        ICertificadosListFiltros
    >(obtenerCertificados, getInitialState({ filtros: filtrosProps, cantPorPagina }));
    const [busqueda, setBusqueda] = useState("");
    const [busquedaDebounced] = useDebounce(busqueda, 1500);
    const [verFiltros, setVerFiltros] = useState(false);

    useEffect(() => {
        filtrar(filtrosProps);
    }, [filtrar, filtrosProps]);

    const certificadosListComponent = useMemo(
        () => (
            <CertificadosListTable
                certificados={fetcherState.data && fetcherState.data.length > 0 ? fetcherState.data : []}
            />
        ),
        [fetcherState.data]
    );

    const filtrosActivos = useMemo<Array<IFiltroActivo>>(() => obtenerFiltrosActivos(fetcherState.filtros, filtrar), [
        fetcherState.filtros,
        filtrar
    ]);

    useEffect(() => {
        buscar(busquedaDebounced);
    }, [buscar, busquedaDebounced]);

    const filtrarCallback = (filtros: ICertificadosListFiltros) => {
        filtrar(filtros);
        setVerFiltros(false);
    };

    return (
        <Flex flexDirection={"column"}>
            {verFiltros && (
                <CertificadosListFiltros
                    cerrarCallback={() => setVerFiltros(false)}
                    filtros={fetcherState.filtros}
                    filtrarCallback={filtrarCallback}
                />
            )}
            {fetcherState.error && (
                <Box my={3}>
                    <Alert type="critical" title={null} icon>
                        {fetcherState.error}
                    </Alert>
                </Box>
            )}
            <Box css={{ flexShrink: 0 }}>
                <TopBar
                    busqueda={busqueda}
                    filtrosActivos={filtrosActivos}
                    mostrarFiltros={() => setVerFiltros(true)}
                    setBusqueda={busqueda => setBusqueda(busqueda)}
                    verCrear={false}
                />
                <Box my={3}>
                    <Separator />
                </Box>
            </Box>
            <Box>
                {fetcherState.status === FetchStatus.NOT_FETCHED && <></>}
                {fetcherState.status === FetchStatus.FETCHING && (
                    <Loading loading={fetcherState.isLoading} type="boxLoader" text="Cargando Certificados..." />
                )}
                {fetcherState.status === FetchStatus.SUCCESS && certificadosListComponent}
            </Box>
            <Paginador
                nroPagina={fetcherState.paginador.nroPagina}
                cantPorPagina={fetcherState.paginador.cantPorPagina}
                cantResultados={fetcherState.paginador.cantResultados}
                cambiarPaginaCallback={nroPagina => {
                    cambiarNroPagina(nroPagina);
                }}
                mt={3}
            />
        </Flex>
    );
};
