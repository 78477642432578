import { ResultBase, makeHttpRequest, POST } from "./api";

export enum ErrorCodes {}

export interface IDespachadoMes {
    fechaDesde: string;
    fechaHasta: string;
    toneladas: number;
}

interface ResponseOutput {
    dataGrafico: Array<IDespachadoMes>;
}

export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const obtenerDespachosGraficoData = async (): Promise<Result> =>
    makeHttpRequest<{}, ResponseOutput, ErrorCodes>(POST, "home-despachos-grafico", {});
