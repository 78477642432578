import styled from "styled-components";
import { Box, Flex, Text } from "rebass/styled-components";
import React, { FC, useEffect, useState } from "react";
import { Power, ShoppingCart, User } from "react-feather";
import { rutas, TituloRuta } from "../../app/rutas";
import { LogoutCallback } from "./AppLayout";
import { Link } from "react-router-dom";
import { CarritoContainer } from "../../app/nuevos-pedidos/CarritoContainer";

interface Props {
    logout: LogoutCallback;
    nombreCliente: string;
    nombreUsuario: string;
    tituloRuta?: TituloRuta;
}
export const AppLayoutNavbar: FC<Props> = ({ logout, nombreCliente, nombreUsuario, tituloRuta }) => {
    const titulo = tituloRuta ? tituloRuta.titulo : "";
    const IconoRuta = tituloRuta ? tituloRuta.Icono : () => <></>;

    return (
        <Flex
            sx={{
                color: "navbarColor",
                bg: "navbarBg",
                boxShadow: "small",
                flexShrink: 0,
                height: "3.7em",
                position: "sticky",
                top: 0,
                zIndex: 601 // 601 > orbit popovers
            }}
        >
            <Flex alignItems={"flex-end"} ml={3} my={"auto"}>
                <IconoRuta />
                <Text sx={{ fontSize: 5, my: "auto", ml: 2 }}>{titulo}</Text>
            </Flex>

            <Flex sx={{ height: "100%", ml: "auto", position: "relative" }}>
                <CarritoItem badgeText={15} />
                <UsuarioItem nombreCliente={nombreCliente} nombreUsuario={nombreUsuario} logout={logout} />
            </Flex>
        </Flex>
    );
};

/*****************************************************************
 * AppLayoutNavbar Dropdown
 */

const StyledDropdownList = styled(Flex)`
    background-color: white;
    border-top: 4px solid ${props => props.theme.colors.navbarHoverColor};
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.125);
    flex-direction: column;
    padding: 1em;
    position: absolute;
    right: 0;
    top: 100%;
    min-width: 10em;
    white-space: nowrap;
`;

const StyledLink = styled(Link)`
    color: #8b8b8b;
    // margin-top: 5px;
`;

const CarritoItem: FC<{ badgeText: number | string }> = ({ badgeText }) => {
    const { pedidoCarrito, fetch: fetchCarrito } = CarritoContainer.useContainer();
    useEffect(() => {
        fetchCarrito();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cantArticulosCarrito = pedidoCarrito ? pedidoCarrito.detalles.length : 0;
    /*const cantPaquetesCarrito = pedidoCarrito
        ? pedidoCarrito.detalles.reduce<number>(
              (cantPaquetes: number, detalle: IPedidoDetalle) => cantPaquetes + detalle.cantPaquetes,
              0
          )
        : 0;*/

    return (
        <StyledLink to={rutas.confirmarPedido}>
            <Flex
                sx={{
                    alignItems: "center",
                    color: "navbarColor",
                    height: "100%",
                    my: "auto",
                    p: "1em",
                    "&:hover": {
                        bg: "navbarHoverBg",
                        color: "navbarHoverColor",
                        cursor: "pointer"
                    }
                }}
            >
                <Box sx={{ position: "relative" }}>
                    <ShoppingCart size={28} />
                    {cantArticulosCarrito > 0 && (
                        <Box
                            sx={{
                                bg: "navbarBadgeBg",
                                color: "navbarBadgeColor",
                                borderRadius: "10px",
                                fontSize: "70%",
                                fontWeight: 600,
                                position: "absolute",
                                padding: "0.25em 0.6em",
                                left: "24px",
                                top: "-6px"
                            }}
                        >
                            {cantArticulosCarrito}
                        </Box>
                    )}
                </Box>
            </Flex>
        </StyledLink>
    );
};

interface DropdownProps {
    nombreCliente: string;
    nombreUsuario: string;
    logout: LogoutCallback;
    mx?: number;
}
const UsuarioItem: FC<DropdownProps> = ({ nombreCliente, nombreUsuario, mx, logout }) => {
    const [isActive, setActive] = useState(false);
    nombreUsuario = truncate(nombreUsuario);
    nombreCliente = truncate(nombreCliente);

    return (
        <Flex
            sx={{
                bg: isActive && "navbarHoverBg",
                height: "100%",
                position: "relative"
            }}
            mx={mx}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
        >
            <Flex
                sx={{
                    alignItems: "center",
                    px: 3,
                    "&:hover": {
                        cursor: "pointer",
                        color: "navbarHoverColor",
                        bg: "navbarHoverBg"
                    }
                }}
            >
                <User size={28} />
                <Flex flexDirection={"column"} ml={2}>
                    <Text>{nombreUsuario}</Text>
                    <Text fontSize={"0.8em"} textAlign={"center"}>
                        {nombreCliente}
                    </Text>
                </Flex>
            </Flex>

            {isActive && (
                <StyledDropdownList fontSize={2}>
                    <DropdownItem onClick={logout}>
                        <Power size={15} />
                        <Text ml={2}>Salir</Text>
                    </DropdownItem>
                </StyledDropdownList>
            )}
        </Flex>
    );
};

const DropdownItem: FC<{ onClick?: () => void }> = ({ children, onClick }) => {
    return (
        <Flex
            onClick={onClick}
            sx={{
                alignItems: "center",
                padding: "0.5em",
                width: "10em",

                "&:hover": {
                    cursor: "pointer",
                    color: "navbarHoverColor",
                    bg: "navbarHoverBg"
                }
            }}
        >
            {children}
        </Flex>
    );
};

const maxLenghUsuario = 30;
const truncate = (s: string): string => {
    if (s.length > maxLenghUsuario) {
        return s.substr(0, maxLenghUsuario) + "...";
    }

    return s;
};
