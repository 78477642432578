import React, { FC } from "react";
import { Truck } from "react-feather";
import { Box, Flex, Text } from "rebass/styled-components";
import { formatCant } from "../../lib/format";
import { ICantidades } from "../types";
import { Cantidades } from "./Cantidades";
import { Cell, Pie, PieChart } from "recharts";
import CheckCircleIcon from "@kiwicom/orbit-components/lib/icons/CheckCircle";
// import { ReactComponent as EnRevisionIcon } from "../iconos/de.svg";

interface Props {
    cantidadPedido: ICantidades;
    cantidadDespachada: ICantidades;
    estaCerrado: boolean;
    small?: boolean;
}
export const ColCantidades: FC<Props> = props => {
    const { cantidadPedido, cantidadDespachada, estaCerrado } = props;

    return (
        <Flex alignItems={"center"} fontSize={"0.9em"}>
            <Cantidades alignItems={"flex-end"} cant={cantidadPedido} width={"8em"} />
            <Box mx={"1em"} width={"7em"}>
                <RuedaTotalDespchado {...props} />
            </Box>
            <Cantidades cant={cantidadDespachada} color={estaCerrado ? "coloTextSecondary" : "datagridResaltado"} />
        </Flex>
    );
};

const RuedaTotalDespchado: FC<Props> = ({ estaCerrado, cantidadDespachada, cantidadPedido }) => {
    const porc = Math.round((cantidadDespachada.kilos * 100) / cantidadPedido.kilos);
    const cantPendiente =
        cantidadDespachada.kilos > cantidadPedido.kilos ? 0 : cantidadPedido.kilos - cantidadDespachada.kilos;

    const data = [
        { name: "Despachado (kgs)", value: cantidadDespachada.kilos },
        { name: "Pendiente (kgs)", value: cantPendiente }
    ];

    const color = estaCerrado ? "#5F738C" : "#0293cf";
    const PIE_COLORS = [color, "#DBDBDB"];
    const pieSize = 60;
    const truckTop = pieSize / 2 - 10;
    const truckLeft = pieSize / 2 - 10;

    return (
        <Flex alignItems={"center"} flexDirection={"column"} mx={2}>
            <Box sx={{ position: "relative" }}>
                <PieChart width={pieSize} height={pieSize}>
                    <Pie
                        data={data}
                        // cx={"50%"}
                        innerRadius={20}
                        outerRadius={28}
                        fill="#8884d8"
                        // paddingAngle={5}
                        startAngle={450}
                        endAngle={90}
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>

                <Box sx={{ position: "absolute", top: `${truckTop}px`, left: `${truckLeft}px` }}>
                    <Truck size={"1.4em"} />
                </Box>
            </Box>

            {estaCerrado ? (
                <Flex alignItems={"center"}>
                    <CheckCircleIcon color={"secondary"} size={"small"} />
                    <Text color={"colorTextSecondary"} fontSize={"0.7em"} ml={"2px"}>
                        CERRADO
                    </Text>
                </Flex>
            ) : (
                <Box>
                    <Flex>
                        <Text color={color} fontSize={"0.7em"}>
                            {formatCant(porc)}%
                        </Text>
                        <Text color={"datagridLightText"} fontSize={"0.7em"} ml={1}>
                            DESPACHADO
                        </Text>
                    </Flex>
                </Box>
            )}
        </Flex>
    );
};
