import getTokens from "@kiwicom/orbit-components/lib/getTokens";

const product = {
    light: "#beecfe",
    lightHover: "#84dafe",
    lightActive: "#5dcefd",
    normal: "#0293cf",
    normalHover: "#02a1e2",
    normalActive: "#0285bc",
    dark: "#0277a8"
};

const orbit = getTokens({
    palette: {
        product
    }
});

const robotoCondensed = "'Roboto', sans-serif;";
const orbitColors = {
    colorIconSecondary: orbit.colorIconSecondary,
    colorFormLabelFilled: orbit.colorFormLabelFilled,
    colorTextError: orbit.colorTextError,
    colorTextSecondary: orbit.colorTextSecondary,
    colorTextSuccess: orbit.colorTextSuccess,
    paletteRedNormal: orbit.paletteRedNormal
};

export const theme = {
    breakpoints: ["40em", "52em", "64em"],
    colors: {
        appLayoutBg: "#F7F7F7",
        cuentaCorrientePedidosColor: "#8A8A8A",
        cuentaCorrienteSaldoPositivoColor: "#28A138",
        cuentaCorrienteSaldoNegativoColor: "#D21C1C",
        confirmarPedidoTextColor: "#4A4A4A",
        datagridResaltado: "#2C89BD",
        datagridLightText: "#6c6c6c",
        documentosAplicaciones: "#5F738C",
        documentosAjustes: "#01508E",
        documentosFacturas: "#2C89BD",
        documentosRecibos: "#00A991",
        documentosRemitos: "#F38906",
        emptyLayoutBg: "#F7F7F7",
        estadosPedidosAprobado: "#00D1B2",
        estadosPedidosEnRevision: "#FFBC57",
        estadosPedidosDespachado: "#7b7b7b",
        iconColorSuccess: orbit.colorTextSuccess,
        iconColorError: orbit.colorTextError,
        pedidoDetalleBg: orbit.paletteCloudLight,
        pedidoDocumentoBg: orbit.paletteCloudLightActive,
        navbarBadgeBg: "#0d9dd6",
        navbarBadgeColor: "white",
        navbarBg: "white",
        navbarColor: "#8B8B8B",
        navbarHoverBg: orbit.paletteCloudLight,
        navbarHoverColor: product.normal,
        ...orbitColors,
        titulo: "#5F738C",
        tableLinkColor: "#1675ec",
        sidebarBg: "#363636",
        sidebarItemColor: orbit.paletteCloudLight,
        sidebarItemHoverBg: product.normal,
        sidebarItemBorder: product.normal,
        textColorGray: "#4F4F4F"
    },
    fonts: {
        robotoCondensed
    },
    fontSizes: [10, 12, 14, 16, 20, 24, 32, 48, 64],
    global: {
        fontFamily: robotoCondensed
    },
    orbit,
    product,
    shadows: {
        small: "0 0 4px rgba(0, 0, 0, .125)",
        medium: "0 0 14px rgba(0, 0, 0, .125)",
        large: "0 0 24px rgba(0, 0, 0, .125)"
    },
    space: [0, 4, 8, 16, 32, 64, 128, 256]
};
