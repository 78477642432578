import { makeHttpRequest, POST, ResultBase } from "./api";

export interface Request {
    idPedidoDetalle: number;
}

export enum ErrorCodes {}

interface ResponseOutput {}

export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const quitarArticuloCarrito = async (reqData: Request): Promise<Result> =>
    makeHttpRequest<Request, ResponseOutput, ErrorCodes>(POST, "carrito-quitar-detalle", reqData);
