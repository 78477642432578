import React, { FC, useEffect, useMemo, useState } from "react";
import { IUsuariosListData, IUsuariosListFiltros, obtenerUsuarios } from "../api/UsuariosListApi";
import { UsuariosListTable } from "./UsuariosListTable";
import { Paginador } from "../../ui_components/Paginador";
import { FetchStatus, getInitialState, useListFetcher } from "../../lib/useListFetcher";
import { Box, Flex } from "rebass/styled-components";
import { Alert, Loading, Separator } from "@kiwicom/orbit-components";
import { TopBar } from "../../ui_components/ListView/TopBar";
import { useDebounce } from "use-debounce";
import { UsuariosCrearUsuarioModal } from "./UsuariosCrearUsuarioModal";

interface UsuariosListProps {
    filtros?: IUsuariosListFiltros;
    cantPorPagina?: number;
}

export const UsuariosList: FC<UsuariosListProps> = ({ filtros: filtrosProps, cantPorPagina }) => {
    const [fetcherState, { buscar, cambiarNroPagina, filtrar, refresh }] = useListFetcher<
        IUsuariosListData,
        IUsuariosListFiltros
    >(obtenerUsuarios, getInitialState({ filtros: filtrosProps, cantPorPagina }));
    const [busqueda, setBusqueda] = useState("");
    const [busquedaDebounced] = useDebounce(busqueda, 1500);
    const [verCrearUsuarioModal, setVerCrearUsuarioModal] = useState<boolean>(false);

    useEffect(() => {
        filtrar(filtrosProps);
    }, [filtrar, filtrosProps]);

    const usuariosListComponent = useMemo(
        () => (
            <UsuariosListTable
                usuarios={fetcherState.data && fetcherState.data.length > 0 ? fetcherState.data : []}
                onActionSuccess={refresh}
            />
        ),
        [fetcherState.data, refresh]
    );

    useEffect(() => {
        buscar(busquedaDebounced);
    }, [buscar, busquedaDebounced]);

    return (
        <Flex flexDirection={"column"}>
            {verCrearUsuarioModal && (
                <UsuariosCrearUsuarioModal
                    onClose={() => {
                        setVerCrearUsuarioModal(false);
                        refresh();
                    }}
                />
            )}
            {fetcherState.error && (
                <Box my={3}>
                    <Alert type="critical" title={null} icon>
                        {fetcherState.error}
                    </Alert>
                </Box>
            )}
            <Box css={{ flexShrink: 0 }}>
                <TopBar
                    busqueda={busqueda}
                    setBusqueda={busqueda => setBusqueda(busqueda)}
                    verCrear={true}
                    textoCrear={"Crear Usuario"}
                    onCrearClick={() => setVerCrearUsuarioModal(true)}
                />
                <Box my={3}>
                    <Separator />
                </Box>
            </Box>
            <Box>
                {fetcherState.status === FetchStatus.NOT_FETCHED && <></>}
                {fetcherState.status === FetchStatus.FETCHING && (
                    <Loading loading={fetcherState.isLoading} type="boxLoader" text="Cargando Usuarios..." />
                )}
                {fetcherState.status === FetchStatus.SUCCESS && usuariosListComponent}
            </Box>
            <Paginador
                nroPagina={fetcherState.paginador.nroPagina}
                cantPorPagina={fetcherState.paginador.cantPorPagina}
                cantResultados={fetcherState.paginador.cantResultados}
                cambiarPaginaCallback={nroPagina => {
                    cambiarNroPagina(nroPagina);
                }}
                mt={3}
            />
        </Flex>
    );
};
