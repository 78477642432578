import { ResultBase, makeHttpRequest, POST } from "./api";

export interface Request {
    token: string;
}

export enum ErrorCodes {
    TOKEN_INCORRECTO = "TOKEN_INCORRECTO",
    TOKEN_EXPIRADO = "TOKEN_EXPIRADO"
}

export interface ResponseData {
    token: string;
}
export interface Response extends ResultBase<ResponseData, ErrorCodes> {}

export const activarSesion = async (req: Request): Promise<Response> =>
    makeHttpRequest<Request, ResponseData, ErrorCodes>(POST, "activarSesion", req);
