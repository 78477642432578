import React, { FC, ReactElement } from "react";
import { ICantidades } from "../types";
import { Flex, Text } from "rebass/styled-components";
import { Tag as TagIcon } from "react-feather";
import { ReactComponent as KgsIcon } from "../iconos/kgs_icon.svg";
import { ReactComponent as MetrosIcon } from "../iconos/metros_icon.svg";
import { formatCant } from "../../lib/format";

interface Props {
    alignItems?: string;
    cant: ICantidades;
    color?: string;
    horizontal?: boolean;
    width?: string;
}

export const Cantidades: FC<Props> = ({ alignItems, cant, color, horizontal = false, width }) => {
    alignItems = alignItems ? alignItems : "baseline";
    const direction = horizontal ? "row" : "column";
    const my = !horizontal ? "0.35em" : 0;
    const mx = horizontal ? "0.55em" : 0;
    return (
        <Flex alignItems={alignItems} justifyContent={"center"} flexDirection={direction} width={width}>
            {cant.unidades > 0 && (
                <Cantidad cantidad={cant.unidades} color={color} Icono={<TagIcon size={"1.1em"} />} um={"uni."} />
            )}
            {cant.kilos && (
                <Cantidad
                    cantidad={cant.kilos}
                    color={color}
                    Icono={<KgsIcon height={"1.1em"} width={"1.1em"} />}
                    my={my}
                    mx={mx}
                    um={"kgs."}
                />
            )}
            {cant.metros > 0 && (
                <Cantidad
                    cantidad={cant.metros}
                    color={color}
                    Icono={<MetrosIcon height={"1.1em"} width={"1.1em"} />}
                    um={"mts."}
                />
            )}
        </Flex>
    );
};

interface CantidadProps {
    cantidad: number;
    color?: string;
    Icono: ReactElement;
    my?: number | string;
    mx?: number | string;
    um: string;
}
const Cantidad: FC<CantidadProps> = ({ cantidad, color, Icono, my, mx, um }) => {
    return (
        <Flex alignItems={"flex-end"} my={my} mx={mx}>
            {Icono}
            <Text color={color} fontSize={"1em"} ml={1}>
                {formatCant(cantidad)} {um}
            </Text>
        </Flex>
    );
};
