import React, { FC, useMemo, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import Table, { TableBody, TableCell, TableHead, TableRow } from "@kiwicom/orbit-components/lib/Table";
import Download from "@kiwicom/orbit-components/lib/icons/Download";
import { RemitoDocModal } from "../remitos/RemitoDocModal";
import { ICertificadosListData } from "../api/CertificadosListApi";
import { descargasURLBase } from "../api/api";
import { getAuthToken } from "../LocalStorage";

interface Props {
    certificados: Array<ICertificadosListData>;
}

export const CertificadosListTable: FC<Props> = ({ certificados }) => {
    const [verIdRemito, setVerIdRemito] = useState<number | null>(null);
    const authToken = getAuthToken();
    const certificadosPDFBase = `${descargasURLBase}/certificados-pdf?token=${authToken}`;

    const certificadosListTableComponent = useMemo(
        () => (
            <Table compact={true}>
                <TableHead>
                    <TableRow>
                        <TableCell align={"left"}>Nro Certificado</TableCell>
                        <TableCell align={"left"}>Fecha</TableCell>
                        <TableCell align={"left"}>Tipo</TableCell>
                        <TableCell align={"left"}>Sub Grupo</TableCell>
                        <TableCell align={"left"}>Remito</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {certificados.map(certificado => {
                        const {
                            id,
                            nroCertificado,
                            fecha,
                            tipoCertificado,
                            subGrupo,
                            nroRemito,
                            idRemito
                        } = certificado;
                        return (
                            <TableRow key={id}>
                                <TableCell align={"left"}>
                                    <Flex flexDirection={"column"}>
                                        <a
                                            href={`${certificadosPDFBase}&idCertificado=${id}`}
                                            download={true}
                                            style={{ color: "inherit", cursor: "auto", textDecoration: "inherit" }}
                                        >
                                            <Flex
                                                sx={{
                                                    color: "#5F738C",
                                                    textDecoration: "none",
                                                    alignItems: "flex-end",
                                                    fontWeight: 600,
                                                    fontSize: "0.9em",
                                                    "&:hover": {
                                                        color: "tableLinkColor",
                                                        cursor: "pointer",
                                                        textDecoration: "underline"
                                                    }
                                                }}
                                            >
                                                <Download size={"small"} />
                                                <Text ml={1}>{nroCertificado}</Text>
                                            </Flex>
                                        </a>
                                    </Flex>
                                </TableCell>
                                <TableCell align={"left"}>{fecha}</TableCell>
                                <TableCell align={"left"}>{tipoCertificado}</TableCell>
                                <TableCell align={"left"}>{subGrupo}</TableCell>
                                <TableCell align={"left"}>
                                    {nroRemito && idRemito && (
                                        <Box
                                            onClick={() => {
                                                setVerIdRemito(idRemito);
                                            }}
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: "0.9em",
                                                "&:hover": {
                                                    color: "tableLinkColor",
                                                    cursor: "pointer",
                                                    textDecoration: "underline"
                                                }
                                            }}
                                        >
                                            {nroRemito}
                                        </Box>
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        ),
        [certificados, certificadosPDFBase]
    );

    return (
        <>
            {verIdRemito && <RemitoDocModal idRemito={verIdRemito} onClose={() => setVerIdRemito(null)} />}
            {certificadosListTableComponent}
        </>
    );
};
