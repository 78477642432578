import React, { FC, useEffect, useState } from "react";
import { Box, Flex } from "rebass/styled-components";
import { Alert, Button, Portal, Textarea, Separator } from "@kiwicom/orbit-components";
import Modal, { ModalHeader, ModalSection } from "@kiwicom/orbit-components/lib/Modal";
import Email from "@kiwicom/orbit-components/lib/icons/Email";
import { enviarEmail, TipoEmail } from "../api/ContactoEnviarEmailApi";

interface Props {
    tipoEmail: TipoEmail;
    titulo: string;
    onClose: () => void;
}

export const ContactoEnviarEmailModal: FC<Props> = ({ tipoEmail, titulo, onClose }) => {
    return (
        <Portal>
            <Modal fixedFooter={false} isMobileFullPage={true} onClose={onClose}>
                <ModalHeader title={titulo} suppressed={true} />
                <ModalSection suppressed={false}>
                    <ContactoEnviarEmailVentas tipoEmail={tipoEmail} onCancelar={onClose} />
                </ModalSection>
            </Modal>
        </Portal>
    );
};

const ContactoEnviarEmailVentas: FC<{
    tipoEmail: TipoEmail;
    onCancelar: () => void;
}> = ({ tipoEmail, onCancelar }) => {
    const [contenidoEmail, setContenidoEmail] = useState<string>("");
    const [enviando, setEnviando] = useState(false);
    const [emailEnviadoOk, setEmailEnviadoOk] = useState(false);
    const [msgError, setMsgError] = useState<string | null>(null);

    const enviarClick = async () => {
        setEnviando(true);
        const resp = await enviarEmail({ tipoEmail, contenidoEmail });
        if (!resp.ok) {
            setMsgError("Hubo un error al enviar el email. Por favor, reintente más tarde.");
            setEnviando(false);
            return;
        }

        setEmailEnviadoOk(true);
    };

    useEffect(() => {
        if (emailEnviadoOk) {
            setTimeout(onCancelar, 2000);
        }
    }, [emailEnviadoOk, onCancelar]);

    if (emailEnviadoOk) {
        return <Alert type={"success"} title={"Email enviado correctamente"} icon={true} />;
    }

    return (
        <>
            {msgError && (
                <Box mb={2}>
                    <Alert type="critical" title={null} icon>
                        {msgError}
                    </Alert>
                </Box>
            )}
            <Flex flexDirection={"column"}>
                <Textarea
                    size="normal"
                    value={contenidoEmail}
                    disabled={enviando}
                    placeholder="Escriba aquí su mensaje"
                    rows={10}
                    maxLength={500}
                    resize="vertical"
                    onChange={e => {
                        setContenidoEmail(e.target.value);
                    }}
                />

                <Box my={3}>
                    <Separator />
                </Box>

                <Flex>
                    <Button
                        onClick={enviarClick}
                        disabled={enviando || !contenidoEmail || !contenidoEmail.trim()}
                        iconLeft={<Email />}
                        type={"primary"}
                    >
                        {enviando ? "Enviando..." : "Enviar"}
                    </Button>
                    <Box ml={2}>
                        <Button disabled={enviando} onClick={onCancelar} type={"secondary"}>
                            Cancelar
                        </Button>
                    </Box>
                </Flex>
            </Flex>
        </>
    );
};
