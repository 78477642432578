import React from "react";
import { Box, Flex } from "rebass/styled-components";

export const EmptyLayout: React.FC = ({ children }) => {
    return (
        <Flex
            sx={{
                alignItems: "center",
                bg: "emptyLayoutBg",
                flexDirection: "column",
                flexGrow: 1,
                height: "100vh"
            }}
        >
            <Box
                sx={{
                    bg: "white",
                    borderRadius: "0.4em",
                    mx: "auto",
                    my: "auto",
                    p: "2em",
                    boxShadow: "medium"
                }}
            >
                {children}
            </Box>
        </Flex>
    );
};
