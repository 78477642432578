import React, { FC, ReactInstance, ReactNode, useRef } from "react";
import { Box, Flex, Image, Text } from "rebass/styled-components";
import logoTASA from "../assets/logo-tasa-documentos.png";
import { Button, Separator } from "@kiwicom/orbit-components";
import Download from "@kiwicom/orbit-components/lib/icons/Download";
import { IDomicilio } from "./types";
import { formatImporte } from "../lib/format";
import ReactToPrint from "react-to-print";
import { Printer } from "react-feather";

interface ICliente {
    nombre: string;
    cuit: string;
    domicilio: IDomicilio;
}

export interface ICategoriaHeader {
    titulo: string;
    datos: Array<ICategoriaHeaderDatos>;
}

export interface ICategoriaHeaderDatos {
    nombre: string;
    valor: string | number;
}

export interface IDocumento {
    tipoDoc: string;
    nroDoc: string | number;
    fechaDoc: string;
    cliente: ICliente;
    categoriasHeader?: Array<ICategoriaHeader>;
    moneda?: string;
    totales?: Array<ITotal>;
}

export interface ITotal {
    descripcion: string;
    importe: number;
}

class EmptyComponent extends React.Component {
    render() {
        return null;
    }
}

export const Documento: FC<{ doc: IDocumento; Body: ReactNode; bajarPDFUrl?: string }> = ({
    doc,
    Body,
    bajarPDFUrl
}) => {
    const docRef = useRef<ReactInstance>();
    return (
        <Flex bg={"white"} flexDirection={"column"} width={1}>
            <Flex flexDirection={"column"} ref={docRef} p={3}>
                <HeaderLogo {...doc} />
                <Box my={3}>
                    <Separator />
                </Box>

                <HeaderDatos {...doc} />

                <Box my={3}>
                    <Separator />
                </Box>

                {Body}

                <Flex mt={4} width={1}>
                    <DatosTASA />
                    {doc.totales && doc.moneda && <Totales moneda={doc.moneda} totales={doc.totales} />}
                </Flex>

                <Box mx={"auto"} mt={2}>
                    <Text fontSize={"0.7em"}>Documento No Oficial</Text>
                </Box>

                <Box mt={4} mb={2}>
                    <Separator />
                </Box>
            </Flex>

            <Flex ml={"auto"}>
                {bajarPDFUrl && (
                    <Box mr={2}>
                        <Button href={bajarPDFUrl} download={true} iconLeft={<Download />} type={"primary"}>
                            Descargar PDF
                        </Button>
                    </Box>
                )}

                <ReactToPrint
                    trigger={() => (
                        <Button iconLeft={<Printer />} type={"secondary"}>
                            Imprimir
                        </Button>
                    )}
                    content={() => {
                        if (docRef.current) {
                            return docRef.current;
                        }

                        return new EmptyComponent({});
                    }}
                />
            </Flex>
        </Flex>
    );
};

const HeaderLogo: FC<IDocumento> = ({ tipoDoc, nroDoc, fechaDoc }) => {
    return (
        <Flex width={1}>
            <Box>
                <Image src={logoTASA} />
            </Box>
            <Flex flexDirection={"column"} ml={"auto"} style={{ textAlign: "right" }}>
                <Text fontSize={"1.6em"}>{tipoDoc}</Text>
                <Text fontSize={"1.6em"}>{nroDoc}</Text>
                <Text fontSize={"1.2em"}>{fechaDoc}</Text>
            </Flex>
        </Flex>
    );
};

const HeaderDatos: FC<IDocumento> = ({ categoriasHeader, cliente }) => {
    const mr = categoriasHeader && categoriasHeader.length === 1 ? "auto" : undefined;
    return (
        <Flex width={1}>
            <DatosClientes {...cliente} />
            {categoriasHeader &&
                categoriasHeader.map(({ titulo, datos }, index) => {
                    return (
                        <Flex flexDirection={"column"} key={index} ml={"auto"} mr={mr}>
                            <TituloCategoriaHeader titulo={`${titulo}:`} />
                            {datos.map(({ nombre, valor }, index) => {
                                return (
                                    <Flex key={index}>
                                        <DataoHeaderNombre nombre={nombre} />
                                        <DataoHeaderValor valor={valor} />
                                    </Flex>
                                );
                            })}
                        </Flex>
                    );
                })}
        </Flex>
    );
};

const DatosClientes: FC<ICliente> = ({
    nombre,
    cuit,
    domicilio: { direccion, localidad, codigoPostal, provincia, pais }
}) => {
    return (
        <Flex flexDirection={"column"}>
            <TituloCategoriaHeader titulo={"Cliente:"} />
            <DataoHeaderValor valor={nombre} />
            <DataoHeaderValor valor={cuit} />
            <DataoHeaderValor valor={direccion} />
            <DataoHeaderValor valor={localidad} />
            {codigoPostal && <DataoHeaderValor valor={`CP: ${codigoPostal}`} />}
            <DataoHeaderValor valor={provincia} />
            {pais && <DataoHeaderValor valor={pais} />}
        </Flex>
    );
};

const TituloCategoriaHeader: FC<{ titulo: string }> = ({ titulo }) => {
    return (
        <Text fontSize={"1.6em"} fontWeight={300} mb={2}>
            {titulo}
        </Text>
    );
};

const DataoHeaderNombre: FC<{ nombre: string }> = ({ nombre }) => (
    <Text color={"#404040"} fontSize={"0.85em"} fontWeight={600} mr={1}>
        {nombre}:
    </Text>
);

const DataoHeaderValor: FC<{ valor: string | number }> = ({ valor }) => (
    <Text color={"#404040"} fontSize={"0.85em"}>
        {valor}
    </Text>
);

const Totales: FC<{ moneda: string; totales: Array<ITotal> }> = ({ moneda, totales }) => {
    return (
        <Flex flexDirection={"column"} ml={"auto"}>
            <table>
                <tbody>
                    {totales.map(({ descripcion, importe }, index) => {
                        return (
                            <tr key={index}>
                                <td style={{ fontWeight: 600, textAlign: "right" }}>{descripcion}</td>
                                <td style={{ paddingLeft: "0.7em", textAlign: "right" }}>
                                    {moneda}
                                    {importe ? formatImporte(importe) : 0}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Flex>
    );
};

const DatosTASA: FC = () => {
    return (
        <Flex
            sx={{
                bg: "#E5EAEF",
                borderRadius: "0.20em",
                flexDirection: "column",
                fontSize: "0.9em",
                height: "9em",
                p: 3
            }}
        >
            <Text fontWeight={600}>Tubos Argentinos S.A.</Text>
            <Text>Marcos Sastre 698 (Ruta 197 y Panamericana)</Text>
            <Text>El Talar de Pacheco, Prov. de Buenos Aires, Argentina</Text>
            <Text>Tel: (54-11) 4006-9600</Text>
            <Text>http://www.tubosarg.com.ar</Text>
            <Text>ventas@tubosarg.com.ar</Text>
        </Flex>
    );
};
