import React, { FC, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { Button } from "@kiwicom/orbit-components";
import { PedidoDocModal } from "../pedidos/PedidoDocModal";
import { CheckCircle as CheckCircleIcon, Printer, ShoppingCart as ShoppingCartIcon } from "react-feather";
import { formatCant } from "../../lib/format";
import { rutas } from "../rutas";
import { Redirect } from "react-router-dom";

export const ConfirmarPedidoSuccess: FC<{ nroPedidoGenerado: number; idPedidoGenerado: number }> = ({
    nroPedidoGenerado,
    idPedidoGenerado
}) => {
    const [verIdPedido, setVerIdPedido] = useState<number | null>(null);
    const [redireccionarAMisPedidos, setRedireccionarAMisPedidos] = useState<boolean>(false);

    if (redireccionarAMisPedidos) {
        return <Redirect to={rutas.pedidos} />;
    }

    return (
        <>
            {verIdPedido && <PedidoDocModal idPedido={verIdPedido} onClose={() => setVerIdPedido(null)} />}
            <Flex
                sx={{
                    alignItems: "center",
                    bg: "#EBF4EC",
                    borderRadius: "0.8em",
                    flexDirection: "column",
                    width: "40em",
                    mx: "auto",
                    my: "5em",
                    p: 5
                }}
                className={"animated bounceInDown"}
            >
                <Box className={"animated bounceInDown"} color={"iconColorSuccess"}>
                    <CheckCircleIcon size={100} />
                </Box>

                <Text className={"animated bounceInDown"} color={"iconColorSuccess"} fontSize={5} mt={2} mb={5}>
                    Se generó el pedido N° <strong>{formatCant(nroPedidoGenerado)}</strong>.
                </Text>

                <Button
                    bordered={false}
                    circled={true}
                    iconLeft={<ShoppingCartIcon />}
                    onClick={() => setRedireccionarAMisPedidos(true)}
                    size={"large"}
                    type={"success"}
                >
                    Ir a Mis Pedidos
                </Button>

                <Box mt={2}>
                    <Button
                        block={false}
                        circled={true}
                        iconLeft={<Printer />}
                        onClick={() => {
                            setVerIdPedido(idPedidoGenerado);
                        }}
                        size={"small"}
                        title={`Ver/Imprimir Pedido`}
                        type={"white"}
                    >
                        Ver/Imprimir Pedido
                    </Button>
                </Box>
            </Flex>
        </>
    );
};
