import { ResultBase, makeHttpRequest, POST } from "./api";
import { ListRequestData, ListResponseOutputBase } from "../types";

export enum ErrorCodes {}

export interface IUsuariosListFiltros {}

export interface IUsuariosListData {
    id: number;
    email: string;
    administrador: boolean;
    activo: boolean;
}

interface ResponseOutput extends ListResponseOutputBase<IUsuariosListData> {}

export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const obtenerUsuarios = async (reqData: ListRequestData<IUsuariosListFiltros>): Promise<Result> =>
    makeHttpRequest<ListRequestData<IUsuariosListFiltros>, ResponseOutput, ErrorCodes>(POST, "usuarios-list", reqData);
