export function chunkArray<T>(array: Array<T>, chunkSize: number): Array<Array<T>> {
    const arrayLength = array.length;
    const tempArray: Array<any> = [];

    for (let index = 0; index < arrayLength; index += chunkSize) {
        tempArray.push(array.slice(index, index + chunkSize));
    }

    return tempArray;
}
