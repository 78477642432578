import React, { FC } from "react";
import Card, { CardSection, CardSectionContent, CardSectionHeader } from "@kiwicom/orbit-components/lib/Card";
import CalendarIcon from "@kiwicom/orbit-components/lib/icons/Calendar";
import { Box, Flex, Text } from "rebass/styled-components";
import { LugarEntregaData, PedidoData } from "../api/PedidosListApi";
import { NoHayDatos } from "../../ui_components/ListView/NoHayDatos";
import { ContenidoPedido } from "./ContenidoPedido";
import { CodsEstadosPedidos } from "../types";
import { ColCantidades } from "./ColCantidades";
import { ColEstado } from "./ColEstado";
import { parse } from "date-fns";
import { formatCant, formatFecha, formatImporte } from "../../lib/format";
import { capitalizeFirstLetter } from "../../lib/capitalizeFirstLetter";

export const PedidosList: FC<{ pedidos: Array<PedidoData> }> = ({ pedidos }) => {
    if (pedidos.length === 0) {
        return <NoHayDatos objeto={"pedido"} />;
    }

    const pedidosAgrupadosPorPeriodo = agruparPedidos(pedidos);

    return (
        <Flex flexDirection={"column"}>
            {pedidosAgrupadosPorPeriodo.map((agrupacionPedidos, key) => {
                return (
                    <Flex flexDirection={"column"} key={key}>
                        <Box mb={2}>
                            <Text color={"#626262"} fontWeight={600}>
                                {agrupacionPedidos.periodo}
                            </Text>
                        </Box>

                        <Box mb={3}>
                            <Card>
                                {agrupacionPedidos.pedidos.map(pedido => {
                                    return <Pedido key={pedido.id} pedido={pedido} />;
                                })}
                            </Card>
                        </Box>
                    </Flex>
                );
            })}
        </Flex>
    );
};

const Pedido: FC<{ pedido: PedidoData; expanded?: boolean }> = ({ pedido, ...cardProps }) => {
    return (
        <CardSection expandable {...cardProps}>
            <CardSectionHeader>
                <PedidoHeader pedido={pedido} />
            </CardSectionHeader>
            <CardSectionContent>{cardProps.expanded && <ContenidoPedido pedido={pedido} />}</CardSectionContent>
        </CardSection>
    );
};

const PedidoHeader: FC<{ pedido: PedidoData }> = ({ pedido }) => {
    const tieneDespachos = pedido.totalDespachado.kilos > 0;
    const estaCerrado = pedido.codEstado === CodsEstadosPedidos.CERRADO;

    return (
        <Flex>
            <ColFecha fecha={pedido.fecha} />
            <ColNroPedido pedido={pedido} />
            <ColLugarEntrega lugarEntrega={pedido.lugarEntrega} />
            <Flex width={"23em"}>
                {tieneDespachos ? (
                    <ColCantidades
                        cantidadPedido={pedido.cantidadesPedido}
                        cantidadDespachada={pedido.totalDespachado}
                        estaCerrado={estaCerrado}
                    />
                ) : (
                    <ColEstado codEstado={pedido.codEstado} ml={"7em"} />
                )}
            </Flex>
        </Flex>
    );
};

const ColFecha: FC<{ fecha: string }> = props => {
    const fecha = parse(props.fecha, "dd/MM/yyyy", new Date());

    return (
        <Flex alignItems={"center"} flexDirection={"column"} width={"5em"}>
            <Box color={"datagridResaltado"}>
                <CalendarIcon />
            </Box>
            <Text fontSize={1} my={1}>
                {formatFecha(fecha, "dd MMMM")}
            </Text>
            <Text color={"datagridLightText"} fontSize={1}>
                {formatFecha(fecha, "yyyy")}
            </Text>
        </Flex>
    );
};

const ColNroPedido: FC<{ pedido: PedidoData }> = ({ pedido }) => {
    const { nroPedido } = pedido;
    const cantPaquetes = formatCant(pedido.cantPaquetes);
    const cantArticulos = formatCant(pedido.cantArticulos);
    const importeTotal = formatImporte(pedido.importeTotal);

    return (
        <Flex alignItems={"start"} flexDirection={"column"} ml={2} width={"12em"}>
            <Text fontSize={4} fontWeight={600}>
                N° {nroPedido}
            </Text>

            <Text fontSize={1} my={1}>
                {cantPaquetes} PAQUETES, {cantArticulos} ARTÍCULOS
            </Text>

            <Text fontSize={2} fontWeight={500}>
                {pedido.moneda} {importeTotal}
            </Text>
        </Flex>
    );
};

const ColLugarEntrega: FC<{ lugarEntrega: LugarEntregaData }> = ({
    lugarEntrega: { descripcion, direccion, localidad }
}) => {
    return (
        <Flex alignItems={"start"} flexDirection={"column"} mx={1} width={"15em"}>
            <Text sx={{ textTransform: "uppercase" }}>{descripcion}</Text>

            <Text sx={{ textTransform: "capitalize" }} color={"datagridLightText"} fontSize={1} my={1}>
                {direccion.toLowerCase()}
            </Text>
            <Text sx={{ textTransform: "capitalize" }} color={"datagridLightText"} fontSize={1}>
                {localidad.toLowerCase()}
            </Text>
        </Flex>
    );
};

interface IAgrupacionPedidos {
    periodo: string;
    pedidos: Array<PedidoData>;
}

const agruparPedidos = (pedidos: Array<PedidoData>): Array<IAgrupacionPedidos> => {
    const pedidosPorPeriodo = [];

    pedidos.forEach(pedido => {
        const fecha = parse(pedido.fecha, "dd/MM/yyyy", new Date());
        const periodo = capitalizeFirstLetter(formatFecha(fecha, "MMMM yyyy"));

        if (!pedidosPorPeriodo[periodo]) {
            pedidosPorPeriodo[periodo] = [];
        }
        pedidosPorPeriodo[periodo].push(pedido);
    });

    const pedidosAgrupadosPorPeriodo: Array<IAgrupacionPedidos> = [];
    Object.keys(pedidosPorPeriodo).forEach(periodo => {
        const pedidosAgrupados = pedidosPorPeriodo[periodo];
        pedidosAgrupadosPorPeriodo.push({
            periodo,
            pedidos: pedidosAgrupados
        });
    });
    return pedidosAgrupadosPorPeriodo;
};
