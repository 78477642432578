import React, { FC, useEffect, useState } from "react";
import { Documento, ICategoriaHeaderDatos, IDocumento } from "../Documento";
import { Box, Flex, Text } from "rebass/styled-components";
import Alert from "@kiwicom/orbit-components/lib/Alert";
import Loading from "@kiwicom/orbit-components/lib/Loading";
import Table, { TableHead, TableBody, TableRow, TableCell } from "@kiwicom/orbit-components/lib/Table";
import getTokens from "@kiwicom/orbit-components/lib/getTokens";
import { ThemeProvider } from "styled-components";
import { IRemito, obtenerRemito } from "../api/RemitoDocApi";
import { formatCant } from "../../lib/format";

const remitoToDocProps = (remito: IRemito): IDocumento => {
    const {
        estado,
        nroRemito,
        fechaRemito,
        fechaEntrega,
        pedido: { nroPedido, nroPedidoAnticipo, nroOCCliente },
        cliente,
        entrega: { domicilioEntrega, transportista, cuitTransportista, chofer, dniChofer, vehiculo }
    } = remito;

    const obtenerDatosRemito = (): Array<ICategoriaHeaderDatos> => {
        const datosRemito: Array<ICategoriaHeaderDatos> = [
            {
                nombre: "Nro. Remito",
                valor: nroRemito
            },
            {
                nombre: "Fecha Remito",
                valor: fechaRemito
            },
            {
                nombre: "Estado",
                valor: estado.nombre
            }
        ];

        if (fechaEntrega) {
            datosRemito.push({
                nombre: "Fecha de Entrega",
                valor: fechaEntrega
            });
        }

        datosRemito.push({
            nombre: "Nro. Pedido",
            valor: formatCant(nroPedido)
        });

        if (nroPedidoAnticipo) {
            datosRemito.push({
                nombre: "Nro. Pedido Anticipo",
                valor: nroPedidoAnticipo
            });
        }

        if (nroOCCliente) {
            datosRemito.push({
                nombre: "Nro. OC. Cliente",
                valor: nroOCCliente
            });
        }

        return datosRemito;
    };

    const obtenerDatosEntrega = (): Array<ICategoriaHeaderDatos> => {
        const { direccion, localidad, provincia, codigoPostal } = domicilioEntrega;
        const datosEntrega: Array<ICategoriaHeaderDatos> = [
            {
                nombre: "Dirección",
                valor: direccion
            },
            {
                nombre: "Localidad",
                valor: localidad
            },
            {
                nombre: "CP",
                valor: codigoPostal ? codigoPostal : ""
            },
            {
                nombre: "Provincia",
                valor: provincia
            }
        ];

        if (transportista) {
            datosEntrega.push({
                nombre: "Transportista",
                valor: transportista
            });
        }

        if (cuitTransportista) {
            datosEntrega.push({
                nombre: "CUIT Transportista",
                valor: cuitTransportista
            });
        }

        if (chofer) {
            datosEntrega.push({
                nombre: "Chofer",
                valor: chofer
            });
        }

        if (dniChofer) {
            datosEntrega.push({
                nombre: "DNI Chofer",
                valor: dniChofer
            });
        }

        if (vehiculo) {
            datosEntrega.push({
                nombre: "Vehículo",
                valor: vehiculo
            });
        }

        return datosEntrega;
    };

    return {
        tipoDoc: "Remito",
        nroDoc: nroRemito,
        fechaDoc: fechaRemito,
        cliente,
        categoriasHeader: [
            {
                titulo: "Remito",
                datos: obtenerDatosRemito()
            },
            {
                titulo: "Entrega",
                datos: obtenerDatosEntrega()
            }
        ]
    };
};

export const RemitoDoc: FC<{ idRemito: number }> = ({ idRemito }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [msgError, setMsgError] = useState<string | null>(null);
    const [remito, setRemito] = useState<IRemito | null>(null);

    useEffect(() => {
        const fetchRemito = async () => {
            const resp = await obtenerRemito({ idRemito });
            if (resp.ok && resp.output) {
                const remito = resp.output.remito;
                setRemito(remito);
                setLoading(false);
                return;
            }

            setMsgError("No se puedieron cargar los datos del remito");
            setLoading(false);
        };

        setLoading(true);
        fetchRemito();
    }, [idRemito]);

    if (loading) {
        return <Loading loading={true} type="boxLoader" text="Cargando..." />;
    }

    if (msgError) {
        return (
            <Box mb={4} mx={"auto"} my={3} width={"40em"}>
                <Alert type="critical" title={null} icon={true}>
                    {msgError}
                </Alert>
            </Box>
        );
    }

    if (remito) {
        return <Documento doc={remitoToDocProps(remito)} Body={<DocBody remito={remito} />} />;
    }

    return <></>;
};

const orbitTheme = getTokens();
orbitTheme.colorTextTable = "#252A31";
const DocBody: FC<{ remito: IRemito }> = ({ remito: { detalles } }) => {
    return (
        <ThemeProvider theme={{ orbit: orbitTheme }}>
            <Table compact={true}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Estado</TableCell>
                        <TableCell align="left">Artículo</TableCell>
                        <TableCell align="right">Cantidad</TableCell>
                        <TableCell align="right" width={"15em"}>
                            Paquetes
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {detalles.map((detalle, index) => {
                        const {
                            estado,
                            codArticulo,
                            descripcion,
                            umCantidad1,
                            umCantidad2,
                            umCantidad3,
                            totalCantidad1,
                            totalCantidad2,
                            totalCantidad3,
                            paquetes
                        } = detalle;

                        return (
                            <TableRow key={index}>
                                <TableCell align="left">{estado.nombre}</TableCell>
                                <TableCell align="left">
                                    <Flex flexDirection={"column"} width={"18em"}>
                                        <Text>{codArticulo}</Text>
                                        <Text color={"datagridLightText"} fontSize={"0.8em"}>
                                            {descripcion}
                                        </Text>
                                    </Flex>
                                </TableCell>
                                <TableCell align="right">
                                    <Flex flexDirection={"column"}>
                                        <Text>
                                            {totalCantidad1 && umCantidad1 && (
                                                <Text>
                                                    {formatCant(totalCantidad1)} {umCantidad1}
                                                </Text>
                                            )}
                                        </Text>
                                        <Text>
                                            {totalCantidad2 && umCantidad2 && (
                                                <Text>
                                                    {formatCant(totalCantidad2)} {umCantidad2}
                                                </Text>
                                            )}
                                        </Text>
                                        <Text>
                                            {totalCantidad3 && umCantidad3 && (
                                                <Text>
                                                    {formatCant(totalCantidad3)} {umCantidad3}
                                                </Text>
                                            )}
                                        </Text>
                                    </Flex>
                                </TableCell>

                                <TableCell align="right">
                                    <Box sx={{ whiteSpace: "normal" }}>{paquetes.join(", ")}</Box>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </ThemeProvider>
    );
};
