import { ICantidades, ILugarEntrega } from "../types";
import { makeHttpRequest, POST, ResultBase } from "./api";

export enum ErrorCodes {}

export interface IPedido {
    id: number;
    lugarEntrega: ILugarEntrega;
    detalles: Array<IPedidoDetalle>;
    comentario: string | null;
    ocCliente: string | null;
    moneda: string;
    subTotal: number;
    impuestos: Array<IImpuesto>;
    totalImpuestos: number;
    total: number;
}

export interface IImpuesto {
    descripcion: string;
    porcentaje: number;
    importe: number;
}

export interface IPedidoDetalle {
    id: number;
    idListaPreciosDetalle: number;
    articulo: {
        codigo: string;
        descripcion: string;
        forma: string;
        medida: string;
        diametro: string;
        extremo: string;
        cantidadesEstandard: ICantidades;
    };
    precioListaPaquete: number;
    porcDescuento1: number;
    porcDescuento2: number;
    porcDescuento3: number;
    porcDescuento4: number;
    precioPaqueteSinDescuento4: number;
    precioFinalPaquete: number;
    cantPaquetes: number;
    cantidades: ICantidades;
    precioFinal: number;
}

interface ResponseOutput {
    pedido: IPedido | null;
}
export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const obtenerPedidoCarrito = async (): Promise<Result> =>
    makeHttpRequest<{}, ResponseOutput, ErrorCodes>(POST, "carrito", {});
