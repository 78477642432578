import { makeHttpRequest, POST, ResultBase } from "./api";
import { ErrorCodes } from "./CarritoApi";
import { ILugarEntrega } from "../types";

interface ResponseOutput {
    lugaresEntrega: Array<ILugarEntrega>;
}
export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const obtenerLugaresEntrega = async (): Promise<Result> =>
    makeHttpRequest<{}, ResponseOutput, ErrorCodes>(POST, "lugares-entrega", {});
