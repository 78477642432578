import React, { FC } from "react";
import { Flex } from "rebass/styled-components";
import { FacturasList } from "./FacturasList";

export const FacturasListPage: FC = () => {
    return (
        <Flex
            sx={{
                flexDirection: "column",
                flexGrow: 1,
                minWidth: "55em",
                mx: "auto",
                my: 3
            }}
        >
            <FacturasList />
        </Flex>
    );
};
