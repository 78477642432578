import {
    ShoppingCart,
    BookOpen,
    Home,
    Award,
    Users,
    PhoneCall,
    DownloadCloud,
    Icon as ReactFeatherIcon
} from "react-feather";

export const rutas = {
    certificados: "/certificados",
    cuentaCorriente: "/cuenta-corriente",
    descargas: "/descargas",
    home: "/",
    ingresar: "/ingresar",
    bienvenido: "/bienvenido",
    facturas: "/facturas",
    login: "/login",
    nuevoPedido: "/nuevo-pedido",
    confirmarPedido: "/confirmar-pedido",
    pedidos: "/pedidos",
    restablecerPassword: "/restablecer-password",
    cambiarPassword: "/cambiar-password",
    remitos: "/remitos",
    contactenos: "/contactenos",
    activacionUsuario: "/activacion-usuario",
    usuarios: "/usuarios",
    pruebarete: "/pruebarete"
};

export interface TituloRuta {
    ruta: string;
    titulo: string;
    Icono: ReactFeatherIcon;
}

export const titulosRutas: Array<TituloRuta> = [
    {
        ruta: rutas.confirmarPedido,
        titulo: "Confirmar Pedido",
        Icono: ShoppingCart
    },
    {
        ruta: rutas.cuentaCorriente,
        titulo: "Cuenta Corriente",
        Icono: BookOpen
    },
    {
        ruta: rutas.home,
        titulo: "¡Bienvenido al eCommerce de Tubos Argentinos!",
        Icono: Home
    },
    {
        ruta: rutas.nuevoPedido,
        titulo: "Nuevo Pedido",
        Icono: ShoppingCart
    },
    {
        ruta: rutas.pedidos,
        titulo: "Mis Pedidos",
        Icono: ShoppingCart
    },
    {
        ruta: rutas.remitos,
        titulo: "Remitos",
        Icono: BookOpen
    },
    {
        ruta: rutas.facturas,
        titulo: "Facturas",
        Icono: BookOpen
    },
    {
        ruta: rutas.certificados,
        titulo: "Certificados de Calidad",
        Icono: Award
    },
    {
        ruta: rutas.usuarios,
        titulo: "Usuarios",
        Icono: Users
    },
    {
        ruta: rutas.contactenos,
        titulo: "Contáctenos",
        Icono: PhoneCall
    },
    {
        ruta: rutas.descargas,
        titulo: "Descargas",
        Icono: DownloadCloud
    }
];

export const getTituloRuta = (ruta: string): TituloRuta | undefined => {
    const titulos = titulosRutas.filter(tituloRuta => tituloRuta.ruta === ruta);
    if (titulos && titulos.length === 1) {
        return titulos[0];
    }
};
