import React, { FC, useEffect, useMemo, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { Button, ButtonLink, Checkbox, InputField, Loading, Popover, Separator } from "@kiwicom/orbit-components";
import ChevronDown from "@kiwicom/orbit-components/lib/icons/ChevronDown";
import Search from "@kiwicom/orbit-components/lib/icons/Search";
import Download from "@kiwicom/orbit-components/lib/icons/Download";
import SortIcon from "@kiwicom/orbit-components/lib/icons/Sort";
import { FetchStatus, getInitialState, useListFetcher } from "../../lib/useListFetcher";
import {
    IArticulosListData,
    IArticulosListFiltrosType,
    obtenerArticulos,
    OrdenarPor
} from "../api/NuevoPedidoArticulosListApi";
import { NuevoPedidoArticulosList } from "./NuevoPedidoArticulosList";
import { FiltrarCallback, NuevoPedidoArticulosFiltros } from "./NuevoPedidoArticulosFiltros";
import { Paginador } from "../../ui_components/Paginador";
import { useDebounce } from "use-debounce/lib";
import { IPedido, IPedidoDetalle } from "../api/CarritoApi";
import { SeparadorVertical } from "../../ui_components/SeparadorVertical";
import { getAuthToken } from "../LocalStorage";
import { descargasURLBase } from "../api/api";
import { CarritoEditarCantidadesArticuloData } from "./CarritoEditarCantidadesArticulo";
import { ReactComponent as OfertasIcon } from "../../assets/ofertas.svg";

interface Props {
    onAgregarArticulo: (data: CarritoEditarCantidadesArticuloData) => void;
    pedidoCarrito: IPedido | null;
}
export const NuevoPedidoArticulos: FC<Props> = ({ pedidoCarrito, onAgregarArticulo }) => {
    const [fetcherState, { buscar, cambiarNroPagina, filtrar, ordenar }] = useListFetcher<
        IArticulosListData,
        IArticulosListFiltrosType
    >(obtenerArticulos, getInitialState({ ordenarPor: OrdenarPor.MAS_PEDIDOS }));
    const [busqueda, setBusqueda] = useState("");
    const [busquedaDebounced] = useDebounce(busqueda, 1500);
    const [verOfertas, setVerOfertas] = useState(false);

    useEffect(() => {
        buscar(busquedaDebounced);
    }, [buscar, busquedaDebounced]);

    useEffect(() => {
        filtrar({ verOfertas });
    }, [filtrar, verOfertas]);

    const articulosComponent = useMemo(() => {
        const agregarArticuloCallback = (articulo: IArticulosListData, pedidoDetalle: IPedidoDetalle | null) => {
            const { precioFinalPaquete, cantidadesEstandard } = articulo;

            onAgregarArticulo({
                idPedidoDetalle: pedidoDetalle ? pedidoDetalle.id : undefined,
                ...articulo,
                cantPaquetes: pedidoDetalle ? pedidoDetalle.cantPaquetes : 1,
                cantidades: pedidoDetalle ? pedidoDetalle.cantidades : cantidadesEstandard,
                importe: pedidoDetalle ? pedidoDetalle.precioFinal : precioFinalPaquete
            });
        };

        return (
            <NuevoPedidoArticulosList
                agregarArticuloCallback={agregarArticuloCallback}
                articulos={fetcherState.data && fetcherState.data.length > 0 ? fetcherState.data : []}
                pedidoCarrito={pedidoCarrito}
            />
        );
    }, [fetcherState.data, pedidoCarrito, onAgregarArticulo]);

    const filtrarCallback: FiltrarCallback = filtros => {
        filtrar(filtros);
    };

    return (
        <Flex flexDirection={"column"}>
            {verOfertas ? (
                <HeaderOfertas
                    onVerPrimeraClick={() => {
                        setVerOfertas(false);
                    }}
                />
            ) : (
                <HeaderPrimera
                    busqueda={busqueda}
                    onBuscar={setBusqueda}
                    filtrarCallback={filtrarCallback}
                    onOfertasClick={() => setVerOfertas(true)}
                    ordenarPor={fetcherState.ordenarPor as string}
                    onOrdenar={(ordenarPor: string) => ordenar(ordenarPor)}
                />
            )}
            <Box my={3}>
                <Separator />
            </Box>

            <Box>
                {fetcherState.status === FetchStatus.NOT_FETCHED && <></>}
                {fetcherState.status === FetchStatus.FETCHING && (
                    <Loading loading={fetcherState.isLoading} type="boxLoader" text="Cargando Artículos..." />
                )}
                {fetcherState.status === FetchStatus.SUCCESS && articulosComponent}
            </Box>

            <Paginador
                nroPagina={fetcherState.paginador.nroPagina}
                cantPorPagina={fetcherState.paginador.cantPorPagina}
                cantResultados={fetcherState.paginador.cantResultados}
                cambiarPaginaCallback={nroPagina => {
                    cambiarNroPagina(nroPagina);
                }}
                mt={3}
            />
        </Flex>
    );
};

const HeaderPrimera: FC<{
    busqueda: string;
    onBuscar: (busqueda: string) => void;
    filtrarCallback: FiltrarCallback;
    onOfertasClick: () => void;
    ordenarPor: string;
    onOrdenar: (ordenarPor: string) => void;
}> = ({ busqueda, onBuscar, filtrarCallback, onOfertasClick, ordenarPor, onOrdenar }) => {
    return (
        <>
            <Flex width={1}>
                <Box width={1 / 3} />
                <Box width={1 / 3}>
                    <Text color={"titulo"} fontSize={"1.60em"} textAlign={"center"}>
                        Filtro de Artículos
                    </Text>
                </Box>
                <Box width={1 / 3}>
                    <Flex sx={{ float: "right" }}>
                        <Televentas />
                    </Flex>
                </Box>
            </Flex>

            <Box my={2}>
                <Separator />
            </Box>

            <NuevoPedidoArticulosFiltros filtrarCallback={filtrarCallback} />

            <Box my={2}>
                <Separator />
            </Box>

            <Flex alignItems={"center"} mt={0}>
                <Box alignItems={"flex-end"}>
                    <InputField
                        autoComplete="off"
                        minLength={null}
                        maxLength={null}
                        onChange={e => onBuscar(e.target.value)}
                        placeholder="Buscar..."
                        prefix={<Search />}
                        size="normal"
                        value={busqueda}
                    />
                </Box>
                <Box mx={"auto"}>
                    <OfertasButton onOfertasClick={onOfertasClick} />
                </Box>
                <Box my={"auto"}>
                    <OrdenarButton ordenarPor={ordenarPor} onOrdenar={onOrdenar} />
                </Box>
            </Flex>
        </>
    );
};

const OfertasButton: FC<{ onOfertasClick: () => void }> = ({ onOfertasClick }) => {
    return (
        <Flex
            sx={{
                alignItems: "normal",
                color: "#28A138",
                px: 3,
                py: 2,
                my: "auto",
                "&:hover": { borderRadius: "4px", bg: "#DCE3E9", cursor: "pointer" }
            }}
            onClick={onOfertasClick}
        >
            <OfertasIcon fill={"#28A138"} height={"30px"} width={"30px"} />
            <Text fontSize={"1.4em"} ml={1}>
                ¡Ofertas!
            </Text>
        </Flex>
    );
};

const HeaderOfertas: FC<{ onVerPrimeraClick: () => void }> = ({ onVerPrimeraClick }) => {
    return (
        <Flex width={1}>
            <Box width={1 / 3} />
            <Box width={1 / 3}>
                <Text color={"titulo"} fontSize={"1.60em"} textAlign={"center"}>
                    Artículos en Oferta
                </Text>
            </Box>
            <Box width={1 / 3}>
                <Box sx={{ float: "right" }}>
                    <Button bordered={true} circled={true} onClick={onVerPrimeraClick} size={"small"} type={"primary"}>
                        Ver Todos los Artículos
                    </Button>
                </Box>
            </Box>
        </Flex>
        /*
        <Flex alignItems={"center"} flexDirection={"column"} mx={"auto"}>
            <Box>
                <Text color={"titulo"} fontSize={"2em"}>
                    Artículos en Oferta
                </Text>
            </Box>

            <Box ml={2} mt={2}>
                <Button
                    bordered={true}
                    circled={true}
                    /!*iconLeft={<Deals />}*!/
                    onClick={onVerPrimeraClick}
                    size={"small"}
                    type={"primary"}
                >
                    Ver Artículos de Primera
                </Button>
            </Box>
        </Flex>*/
    );
};

const Televentas: FC = () => {
    const authToken = getAuthToken();
    const urlEstructurales = `${descargasURLBase}/televentas-pdf?token=${authToken}&tipoTeleventas=ESTRUCTURALES`;
    const urlConduccion = `${descargasURLBase}/televentas-pdf?token=${authToken}&tipoTeleventas=CONDUCCION`;

    const content = (
        <Flex flexDirection={"column"}>
            <Text color={"titulo"} fontSize={"1.2em"} textAlign={"center"}>
                Tipo de Televentas
            </Text>
            <Flex mt={2}>
                <Box>
                    <Button
                        download={true}
                        href={urlEstructurales}
                        iconLeft={<Download />}
                        type={"primary"}
                        size={"small"}
                    >
                        Estructurales
                    </Button>
                </Box>

                <SeparadorVertical mx={2} />

                <Box>
                    <Button
                        download={true}
                        href={urlConduccion}
                        iconLeft={<Download />}
                        type={"primary"}
                        size={"small"}
                    >
                        Conducción
                    </Button>
                </Box>
            </Flex>
        </Flex>
    );

    return (
        <Popover content={content}>
            <Button
                bordered={true}
                circled={false}
                type="primary"
                iconLeft={<Download />}
                iconRight={<ChevronDown />}
                size={"small"}
            >
                Bajar Televentas
            </Button>
        </Popover>
    );
};

const OrdenarButton: FC<{ ordenarPor: string; onOrdenar: (ordenarPor: string) => void }> = ({
    ordenarPor,
    onOrdenar
}) => {
    const [opened, setOpened] = useState(false);
    const content = (
        <Flex flexDirection={"column"}>
            <Text color={"titulo"} fontSize={"1em"} textAlign={"center"}>
                Ordenar por:
            </Text>
            <Box mt={2}>
                <Checkbox
                    label="Artículos más pedidos"
                    checked={ordenarPor === OrdenarPor.MAS_PEDIDOS}
                    onChange={() => {
                        onOrdenar(OrdenarPor.MAS_PEDIDOS);
                        setOpened(false);
                    }}
                />
            </Box>
            <Box mt={1}>
                <Checkbox
                    label="Stock"
                    checked={ordenarPor === OrdenarPor.STOCK}
                    onChange={() => {
                        onOrdenar(OrdenarPor.STOCK);
                        setOpened(false);
                    }}
                />
            </Box>
            <Box mt={1}>
                <Checkbox
                    label="Medida"
                    checked={ordenarPor === OrdenarPor.MEDIDA}
                    onChange={() => {
                        onOrdenar(OrdenarPor.MEDIDA);
                        setOpened(false);
                    }}
                />
            </Box>
        </Flex>
    );

    return (
        <Popover content={content} opened={opened}>
            <ButtonLink
                bordered={true}
                circled={false}
                iconRight={<SortIcon />}
                onClick={() => setOpened(!opened)}
                size={"normal"}
                type={"secondary"}
            >
                Ordenar
            </ButtonLink>
        </Popover>
    );
};
