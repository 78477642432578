const PREFIX = "tasa-ecommerce";
const AUTH_TOKEN_KEY = `${PREFIX}-auth-token`;

export function saveAuthToken(token: string) {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
}

export function getAuthToken(): string | null {
    return localStorage.getItem(AUTH_TOKEN_KEY);
}

export function removeAuthToken() {
    localStorage.removeItem(AUTH_TOKEN_KEY);
}
