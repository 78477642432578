import React, { useReducer } from "react";
import * as LoginApi from "../api/LoginApi";
import { ErrorCodes } from "../api/LoginApi";
import { Box, Flex, Image, Text } from "rebass/styled-components";
import { Alert, Button, InputField } from "@kiwicom/orbit-components";
import EmailIcon from "@kiwicom/orbit-components/lib/icons/Email";
import LockIcon from "@kiwicom/orbit-components/lib/icons/Lock";
import SendIcon from "@kiwicom/orbit-components/lib/icons/Send";
import logoEcommerce from "../../assets/logo-ecommerce.png";
import { saveAuthToken } from "../LocalStorage";
import { SesionContainer } from "../Sesion";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { rutas } from "../rutas";

interface State {
    // emailEnviado: boolean;
    msgError: string | null;
    form: {
        submitting: boolean;
        email: {
            error: string | null;
            value: string;
        };
        password: {
            error: string | null;
            value: string;
        };
    };
}

enum ActionTypes {
    SET_EMAIL = "SET_EMAIL",
    SET_PASSWORD = "SET_PASSWORD",
    SET_SUBMITTING = "SET_SUBMITTING",
    CLEAR_ERRORS = "CLEAR_ERRORS",
    SET_ERRORS = "SET_ERRORS",
    SET_MSG_ERROR = "SET_MSG_ERROR",
    SUBMIT_ERROR = "SUBMIT_ERROR"
}

interface Errors {
    email?: string | null;
    password?: string | null;
}

type Action =
    | { type: ActionTypes.SET_EMAIL; email: string }
    | { type: ActionTypes.SET_PASSWORD; password: string }
    | { type: ActionTypes.CLEAR_ERRORS }
    | { type: ActionTypes.SET_MSG_ERROR; msg: string }
    | { type: ActionTypes.SET_ERRORS; errors: Errors }
    | { type: ActionTypes.SUBMIT_ERROR; errorCode: LoginApi.ErrorCodes | undefined }
    | { type: ActionTypes.SET_SUBMITTING; submitting: boolean };

const reducer = (state: State, action: Action): State => {
    const newState = JSON.parse(JSON.stringify(state)); // Object.assign({}, state);

    switch (action.type) {
        case ActionTypes.SET_EMAIL:
            newState.form.email.value = action.email;
            newState.form.email.error = null;
            return newState;

        case ActionTypes.SET_PASSWORD:
            newState.form.password.value = action.password;
            newState.form.password.error = null;
            return newState;

        case ActionTypes.SET_MSG_ERROR:
            newState.msgError = action.msg;
            return newState;

        case ActionTypes.SET_ERRORS:
            const { errors } = action;
            newState.form.email.error = errors.email ? errors.email : null;
            newState.form.password.error = errors.password ? errors.password : null;
            return newState;

        case ActionTypes.CLEAR_ERRORS:
            newState.msgError = null;
            newState.form.email.error = null;
            newState.form.password.error = null;
            return newState;

        case ActionTypes.SET_SUBMITTING:
            newState.form.submitting = action.submitting;
            return newState;

        case ActionTypes.SUBMIT_ERROR:
            newState.form.submitting = false;
            switch (action.errorCode) {
                case ErrorCodes.EMAIL_NO_REGISTRADO:
                    const msgEmailNoRegistrado = "El email no se encuentra registrado en el sistema";
                    newState.msgError = msgEmailNoRegistrado;
                    newState.form.email.error = msgEmailNoRegistrado;
                    return newState;

                case ErrorCodes.USUARIO_INACTIVO:
                    newState.msgError =
                        "El usuario está inactivo. Si ya solicitó al administrador que active su cuenta por favor revise si casilla de email";
                    return newState;

                case ErrorCodes.PASSWORD_INCORRECTO:
                    const msgPasswordIncorrecto = "La contraseña ingresada es incorrecta";
                    newState.msgError = msgPasswordIncorrecto;
                    newState.form.password.error = msgPasswordIncorrecto;
                    newState.form.password.value = "";
                    return newState;
                default:
                    break;
            }

            newState.msgError = "Hubo un error desconocido";
            return newState;

        default:
            throw new Error();
    }
};

const validate = ({ email, password }: { email: string; password: string }): [boolean, Errors] => {
    const errors = {} as Errors;
    let hasErrors = false;

    if (!email) {
        errors.email = "El email es obligatorio";
        hasErrors = true;
    }

    if (!password) {
        errors.password = "La contraseña es obligatoria";
        hasErrors = true;
    }

    return [hasErrors, errors];
};

const initialState: State = {
    msgError: null,
    form: {
        submitting: false,
        email: {
            error: null,
            value: ""
        },
        password: {
            error: null,
            value: ""
        }
    }
};

export const LoginPage: React.FC = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { setAuthToken } = SesionContainer.useContainer();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        dispatch({ type: ActionTypes.CLEAR_ERRORS });

        const [hasErrors, errors] = validate({
            email: state.form.email.value,
            password: state.form.password.value
        });

        if (hasErrors) {
            dispatch({ type: ActionTypes.SET_ERRORS, errors });
            return;
        }

        dispatch({ type: ActionTypes.SET_SUBMITTING, submitting: true });
        const result = await LoginApi.login({ email: email.value, password: password.value });
        if (result.ok && result.output) {
            const { token } = result.output;
            saveAuthToken(token);
            setAuthToken(token);
            return;
        }

        dispatch({ type: ActionTypes.SUBMIT_ERROR, errorCode: result.errorCode });
    };

    const handleEmailChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: ActionTypes.SET_EMAIL,
            email: e.target.value
        });
    };

    const handlePasswordChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: ActionTypes.SET_PASSWORD,
            password: e.target.value
        });
    };

    const {
        msgError,
        form: { submitting, email, password }
    } = state;

    return (
        <Flex flexDirection={"column"} width={"25em"}>
            <Box mx={"auto"} width={"19em"}>
                <Image src={logoEcommerce} />
            </Box>
            <Box mb={2} mt={4}>
                <Text color={"textColorGray"} fontSize={"2em"} textAlign={"center"}>
                    ¡Bienvenido!
                </Text>
            </Box>
            <Box mb={4}>
                <Text color={"colorTextSecondary"} fontSize={"1em"}>
                    Por favor, ingrese su email y contraseña para ingresar al sistema
                </Text>
            </Box>
            <form onSubmit={handleSubmit}>
                {msgError && (
                    <Box mb={3}>
                        <Alert type="critical" title={null} icon={true}>
                            {msgError}
                        </Alert>
                    </Box>
                )}

                <Flex flexDirection={"column"}>
                    <Box mb={email.error ? 4 : 2}>
                        <InputField
                            autoComplete={"off"}
                            disabled={submitting}
                            error={email.error}
                            help={null}
                            inlineLabel={true}
                            label="Email"
                            name="email"
                            onChange={handleEmailChanges}
                            prefix={<EmailIcon />}
                            placeholder="mi@email.om"
                            required={true}
                            size="normal"
                            suffix={null}
                            type="email"
                            value={email.value}
                        />
                    </Box>

                    <Box mb={password.error ? 4 : 2}>
                        <InputField
                            disabled={submitting}
                            error={password.error}
                            help={null}
                            inlineLabel={true}
                            label="Contraseña"
                            name="password"
                            onChange={handlePasswordChanges}
                            prefix={<LockIcon />}
                            placeholder={null}
                            required={true}
                            size="normal"
                            suffix={null}
                            type="password"
                            value={password.value}
                        />
                    </Box>

                    <Box mt={2}>
                        <Button
                            fullWidth={true}
                            disabled={submitting}
                            iconLeft={<SendIcon />}
                            // onClick={guardar}
                            submit={true}
                            type={"primary"}
                        >
                            Ingresar a eCommerce
                        </Button>
                    </Box>
                    <Box alignSelf={"center"} mt={3}>
                        <StyledLink to={rutas.restablecerPassword}>¿Olvidó su contraseña?</StyledLink>
                    </Box>
                </Flex>
            </form>
        </Flex>
    );
};

const StyledLink = styled(Link)`
    color: #0293cf;
    margin-top: 5px;
`;

/*const EmailEnviado: React.FC<{ email: string }> = ({ email }) => {
    return (
        <Flex alignItems={"center"} flexDirection={"column"} p={3} width={"25em"}>
            <Box className={"animated heartBeat"} color={"iconColorSuccess"}>
                <CheckCircleIcon size={80} />
            </Box>

            <Box className={"animated zoomIn"} style={{ textAlign: "center" }}>
                <Text color={"textColorGray"} fontSize={"2em"} mt={2} mb={2}>
                    ¡Email de ingreso enviado!
                </Text>

                <Text color={"colorTextSecondary"} fontSize={"1.2em"}>
                    Se le envió un email a <strong>{email}</strong> para que pueda ingresar al sistema.
                </Text>
            </Box>
        </Flex>
    );
};
*/
