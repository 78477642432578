import { IDomicilio, ILugarEntrega } from "../types";
import { makeHttpRequest, POST, ResultBase } from "./api";

interface RequestData {
    idPedido: number;
}

export enum ErrorCodes {}

interface ICliente {
    nombre: string;
    cuit: string;
    domicilio: IDomicilio;
}

export interface IPedido {
    id: number;
    nroPedido: number;
    fecha: string;
    cliente: ICliente;
    condVenta: string | null;
    nroOCCliente: string | null;
    anticipoOriginal: string | null;
    vendedor: string | null;
    moneda: string;
    lugarEntrega?: ILugarEntrega;
    detalles: IPedidoDetalle[];
    totales: ITotales;
}

export interface IPedidoDetalle {
    codArticulo: string;
    descripcion: string;
    umPrecio: string;
    cantidad: number;
    precioLista: number;
    bonif1: number;
    bonif2: number;
    bonif3: number;
    bonif4: number;
    precioUnitario: number;
    precioFinal: number;
}

export interface ITotales {
    subTotal: number;
    percepcionesIIBB: Array<IPercepcionIIBB>;
    percIVA: number;
    iva: number;
    total: number;
}

interface IPercepcionIIBB {
    jurisdiccion: string;
    porcentaje: number;
    importe: number;
}

interface ResponseOutput {
    pedido: IPedido;
}
export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const obtenerPedido = async (reqData): Promise<Result> =>
    makeHttpRequest<RequestData, ResponseOutput, ErrorCodes>(POST, "pedido-doc", reqData);
