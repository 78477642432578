import React, { FC, useMemo } from "react";
import { IArticulosListData, NivelesStock } from "../api/NuevoPedidoArticulosListApi";
import { NoHayDatos } from "../../ui_components/ListView/NoHayDatos";
import Card, { CardSection } from "@kiwicom/orbit-components/lib/Card";
import { Button } from "@kiwicom/orbit-components";
import { Box, Flex, Text } from "rebass/styled-components";
import CheckCircleIcon from "@kiwicom/orbit-components/lib/icons/CheckCircle";
import StarFullIcon from "@kiwicom/orbit-components/lib/icons/StarFull";
import { Cantidades } from "../pedidos/Cantidades";
import { formatCant, formatImporte } from "../../lib/format";
import { IPedido, IPedidoDetalle } from "../api/CarritoApi";
import { capitalizeFirstLetter } from "../../lib/capitalizeFirstLetter";

export type AgregarArticuloCallback = (articulo: IArticulosListData, pedidoDetalle: IPedidoDetalle | null) => void;

export const NuevoPedidoArticulosList: FC<{
    pedidoCarrito: IPedido | null;
    agregarArticuloCallback: AgregarArticuloCallback;
    articulos: Array<IArticulosListData>;
}> = ({ agregarArticuloCallback, articulos, pedidoCarrito }) => {
    const articulosComponent = useMemo(
        () => (
            <Card>
                {articulos.map(articulo => {
                    let pedidoDetalle: IPedidoDetalle | null = null;
                    if (pedidoCarrito && pedidoCarrito.detalles.length > 0) {
                        const detallesFiltered = pedidoCarrito.detalles.filter(
                            detalle => detalle.idListaPreciosDetalle === articulo.idListaPreciosDetalle
                        );
                        pedidoDetalle = detallesFiltered.length > 0 ? detallesFiltered[0] : null;
                    }

                    return (
                        <Articulo
                            agregarArticuloCallback={agregarArticuloCallback}
                            articulo={articulo}
                            key={articulo.idListaPreciosDetalle}
                            pedidoDetalle={pedidoDetalle}
                        />
                    );
                })}
            </Card>
        ),
        [agregarArticuloCallback, articulos, pedidoCarrito]
    );

    if (articulos.length === 0) {
        return <NoHayDatos objeto={"artículo"} />;
    }

    return <>{articulosComponent}</>;
};

const Articulo: FC<{
    agregarArticuloCallback: AgregarArticuloCallback;
    articulo: IArticulosListData;
    pedidoDetalle: IPedidoDetalle | null;
}> = ({ agregarArticuloCallback, articulo, pedidoDetalle }) => {
    return (
        <CardSection expandable={false}>
            <ArticuloHeader
                agregarArticuloCallback={agregarArticuloCallback}
                articulo={articulo}
                pedidoDetalle={pedidoDetalle}
            />
        </CardSection>
    );
};

const ArticuloHeader: FC<{
    agregarArticuloCallback: AgregarArticuloCallback;
    articulo: IArticulosListData;
    pedidoDetalle: IPedidoDetalle | null;
}> = ({ agregarArticuloCallback, articulo, pedidoDetalle }) => {
    return (
        <Flex justifyContent={"space-between"}>
            <ColArticulo articulo={articulo} pedidoDetalle={pedidoDetalle} />
            <ColCantidadesEstandard articulo={articulo} />
            <ColAgregar
                agregarArticuloCallback={agregarArticuloCallback}
                articulo={articulo}
                pedidoDetalle={pedidoDetalle}
            />
        </Flex>
    );
};

const ColArticulo: FC<{ articulo: IArticulosListData; pedidoDetalle: IPedidoDetalle | null }> = ({
    articulo,
    pedidoDetalle
}) => {
    return (
        <Flex alignItems={"center"} width={"18em"}>
            <Box color="#28A138" mr={1}>
                {pedidoDetalle && <CheckCircleIcon />}
            </Box>

            <Flex alignItems={"start"} flexDirection={"column"}>
                <Text /*color={"datagridResaltado"}*/ fontSize={"0.8em"} mb={1}>
                    {articulo.codArticulo}
                </Text>
                <Text fontSize={3} fontWeight={600}>
                    {articulo.descripcion}
                </Text>
                <Flex justifyContent={"space-between"} fontSize={"0.8em"} flexWrap={"wrap"} mt={2} width={"100%"}>
                    <Categoria nombre={"Forma"} valor={articulo.forma} />
                    <Categoria nombre={"Medida"} valor={articulo.medida} />
                    <Categoria nombre={"Diámetro"} valor={articulo.diametro} />
                    <Categoria nombre={"Extremo"} valor={articulo.extremo} />
                </Flex>
                {articulo.observaciones && (
                    <Box fontSize={"0.8em"}>
                        <Categoria nombre={"Observaciones"} valor={articulo.observaciones} />
                    </Box>
                )}
            </Flex>
        </Flex>
    );
};

const Categoria: FC<{ nombre: string; valor: string | null }> = ({ nombre, valor }) => {
    if (!valor) {
        return <></>;
    }

    return (
        <Flex alignItems={"start"} flexDirection={"column"} mr={2} mb={2}>
            <Text>{nombre}:</Text>
            <Text fontWeight={300}>{capitalizeFirstLetter(valor)}</Text>
        </Flex>
    );
};

const ColCantidadesEstandard: FC<{ articulo: IArticulosListData }> = ({ articulo }) => {
    return (
        <Flex alignItems={"start"} fontSize={"0.85em"} flexDirection={"column"}>
            <Text fontSize="0.8em" mb={2}>
                Cantidades por Paquete:
            </Text>
            <Cantidades cant={articulo.cantidadesEstandard} /*color={"datagridResaltado"}*/ />
            <Box sx={{ borderTop: "1px dashed #8e8282" }} mt={1} pt={1} width={"100%"}>
                {articulo.moneda} {formatImporte(articulo.precioPaqueteSinDescuento4)}
            </Box>
        </Flex>
    );
};

const ColAgregar: FC<{
    agregarArticuloCallback: AgregarArticuloCallback;
    articulo: IArticulosListData;
    pedidoDetalle: IPedidoDetalle | null;
}> = ({ agregarArticuloCallback, articulo, pedidoDetalle }) => {
    return (
        <Flex flexDirection={"column"} width={"11em"}>
            <Precio articulo={articulo} />
            <Box my={2}>
                <Button
                    onClick={() => {
                        agregarArticuloCallback(articulo, pedidoDetalle);
                    }}
                    circled={false}
                    fullWidth={true}
                    bordered={false}
                    type="primary"
                    size="small"
                    tabIndex="0"
                    title={`Argregar ${articulo.descripcion} al pedido`}
                >
                    {pedidoDetalle ? "Modificar" : "Agregar"}
                </Button>
            </Box>

            <Stock nivelStock={articulo.nivelStock} />
        </Flex>
    );
};

const Precio: FC<{ articulo: IArticulosListData }> = ({
    articulo: { moneda, precioPaqueteSinDescuento4, porcDescuento4, precioFinalPaquete }
}) => {
    if (porcDescuento4 > 0) {
        return (
            <Flex alignItems={"center"} alignSelf={"center"}>
                <Flex flexDirection={"column"} mr={1}>
                    <Text
                        color={"paletteRedNormal"}
                        fontSize={"0.80em"}
                        textAlign={"center"}
                        style={{ textDecoration: "line-through" }}
                    >
                        {moneda} {formatImporte(precioPaqueteSinDescuento4)}
                    </Text>
                    <Text textAlign={"center"}>
                        {moneda} {formatImporte(precioFinalPaquete)}
                    </Text>
                </Flex>
                <EtiquetaDescuento descuento={porcDescuento4} />
            </Flex>
        );
    }

    return (
        <Text textAlign={"center"}>
            {moneda} {formatImporte(precioFinalPaquete)}
        </Text>
    );
};

const EtiquetaDescuento: FC<{ descuento: number }> = ({ descuento }) => {
    return (
        <Flex fontSize={"0.65em"} ml={"2"}>
            <Box
                sx={{
                    width: 0,
                    height: 0,
                    borderTop: "0.83em solid transparent",
                    borderRight: "0.50em solid #EB5757",
                    borderBottom: "0.83em solid transparent"
                }}
            />
            <Flex alignItems={"center"} bg={"#EB5757"} p={"0.2em"}>
                <Text color={"white"} textAlign={"center"}>
                    -{formatCant(descuento)}%
                </Text>
            </Flex>
        </Flex>
    );
};

const Stock: FC<{ nivelStock: NivelesStock }> = ({ nivelStock }) => {
    if (nivelStock === NivelesStock.SIN_STOCK) {
        return (
            <Text color={"paletteRedNormal"} fontSize={"0.75em"} mr={1} textAlign={"center"}>
                Sin Stock
            </Text>
        );
    }

    if (nivelStock === NivelesStock.PLANIFICADO) {
        return (
            <Text color={"#F38906"} fontSize={"0.75em"} mr={1} textAlign={"center"}>
                Planif. para Producir
            </Text>
        );
    }

    const cantEstrellas = nivelStock.length;
    const nrosEstrellas: Array<number> = [];
    for (let i = 0; i < cantEstrellas; i++) {
        nrosEstrellas.push(i);
    }

    return (
        <Flex alignItems={"flex-end"} alignSelf={"center"}>
            <Text fontSize={"0.75em"} mr={1}>
                Stock:
            </Text>
            {nrosEstrellas.map(i => (
                <StockEstrellita key={i} />
            ))}
        </Flex>
    );
};

const StockEstrellita: FC = () => <StarFullIcon size="small" customColor="#51A100" />;
