import { makeHttpRequest, POST, ResultBase } from "./api";

export interface Request {
    idLugarEntrega: number;
}

export enum ErrorCodes {}

interface ResponseOutput {}

export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const cambiarLugarEntrega = async (reqData: Request): Promise<Result> =>
    makeHttpRequest<Request, ResponseOutput, ErrorCodes>(POST, "carrito-cambiar-lugar-entrega", reqData);
