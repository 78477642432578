import React, { FC, Reducer, useCallback, useEffect, useMemo, useReducer } from "react";
import Modal, { ModalHeader, ModalSection } from "@kiwicom/orbit-components/lib/Modal";
import { Box, Flex, Text } from "rebass/styled-components";
import { Alert, Button, Loading } from "@kiwicom/orbit-components";
import LocationIcon from "@kiwicom/orbit-components/lib/icons/Location";
import CheckIcon from "@kiwicom/orbit-components/lib/icons/Check";
import { obtenerLugaresEntrega } from "../api/LugaresEntregaApi";
import { cambiarLugarEntrega } from "../api/CarritoCambiarLugarEntregaApi";
import { ILugarEntrega } from "../types";

interface State {
    cargando: boolean;
    msgError?: string | null;
    lugaresEntrega: Array<ILugarEntrega>;
}

enum ActionsTypes {
    FETCH_SUCCESS,
    FETCH_ERROR,
    SET_ERROR,
    SET_CARGANDO
}

type Action =
    | { type: ActionsTypes.FETCH_SUCCESS; lugaresEntrega: Array<ILugarEntrega> }
    | { type: ActionsTypes.FETCH_ERROR; msgError: string }
    | { type: ActionsTypes.SET_ERROR; msgError: string }
    | { type: ActionsTypes.SET_CARGANDO; cargando: boolean };

const reducer = (state: State, action: Action): State => {
    const newState = Object.assign({}, state);

    switch (action.type) {
        case ActionsTypes.FETCH_SUCCESS:
            newState.lugaresEntrega = action.lugaresEntrega;
            newState.msgError = null;
            newState.cargando = false;
            return newState;

        case ActionsTypes.FETCH_ERROR:
            newState.msgError = action.msgError;
            newState.cargando = false;
            return newState;

        case ActionsTypes.SET_ERROR:
            newState.msgError = action.msgError;
            return newState;

        case ActionsTypes.SET_CARGANDO:
            newState.cargando = action.cargando;
            return newState;
        default:
            throw new Error();
    }
};

const getInitialState = (): State => {
    return {
        cargando: true,
        lugaresEntrega: []
    };
};

type OnSeleccionar = (lugarEntrega: ILugarEntrega) => void;

interface Props {
    onCerrar: () => void;
    onSeleccionar: OnSeleccionar;
}
export const CambiarLugarEntrega: FC<Props> = ({ onCerrar, onSeleccionar }) => {
    const initialState = useMemo(() => getInitialState(), []);
    const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, initialState);

    useEffect(() => {
        const fetch = async () => {
            const resp = await obtenerLugaresEntrega();
            if (resp.ok && resp.output) {
                const { lugaresEntrega } = resp.output;
                dispatch({ type: ActionsTypes.FETCH_SUCCESS, lugaresEntrega });
                return;
            }

            dispatch({ type: ActionsTypes.FETCH_ERROR, msgError: "Hubo un error al cargar los lugares de entrega" });
        };

        fetch();
    }, []);

    const seleccionarLugarEntrega = useCallback(
        (lugarEntrega: ILugarEntrega) => {
            const makeRequest = async () => {
                const result = await cambiarLugarEntrega({ idLugarEntrega: lugarEntrega.id });
                if (!result.ok) {
                    dispatch({
                        type: ActionsTypes.SET_ERROR,
                        msgError: "Hubo un error al cambiar el lugar de entrega del pedido"
                    });
                    dispatch({ type: ActionsTypes.SET_CARGANDO, cargando: false });
                    return;
                }

                // dispatch( { type: ActionsTypes.SET_CARGANDO, cargando: false})
                onSeleccionar(lugarEntrega);
            };

            dispatch({ type: ActionsTypes.SET_CARGANDO, cargando: true });
            makeRequest();
        },
        [onSeleccionar]
    );

    const { cargando, msgError, lugaresEntrega } = state;

    const content = useMemo(() => {
        if (cargando) {
            return <Loading loading={true} type="boxLoader" text="Cargando..." />;
        }

        return (
            <Flex flexDirection={"column"}>
                <Box>
                    {msgError && (
                        <Box mb={2}>
                            <Alert type="critical" title={null} icon={true}>
                                {msgError}
                            </Alert>
                        </Box>
                    )}
                </Box>
                <Box>
                    {lugaresEntrega.map(lugarEntrega => {
                        return (
                            <Box key={lugarEntrega.id}>
                                <LugarEntrega lugarEntrega={lugarEntrega} onSeleccionar={seleccionarLugarEntrega} />
                            </Box>
                        );
                    })}
                </Box>
            </Flex>
        );
    }, [cargando, msgError, lugaresEntrega, seleccionarLugarEntrega]);

    return (
        <Modal onClose={onCerrar}>
            <ModalHeader title={"Seleccionar Lugar de Entrega"} onClose={onCerrar} suppressed={true} />
            <ModalSection suppressed={false}>{content}</ModalSection>
        </Modal>
    );
};

const LugarEntrega: FC<{ lugarEntrega: ILugarEntrega; onSeleccionar: OnSeleccionar }> = ({
    lugarEntrega,
    onSeleccionar
}) => {
    return (
        <Flex
            sx={{
                alignItems: "center",
                borderRadius: "0.5em",
                color: "confirmarPedidoTextColor",
                bg: "#E5EAEF",
                my: 3,
                p: 3
            }}
        >
            <Box mr={2}>
                <LocationIcon />
            </Box>
            <Flex flexDirection={"column"}>
                <Text fontSize={3} fontWeight={600}>
                    {lugarEntrega.descripcion}
                </Text>
                <Text fontSize={2}>{lugarEntrega.domicilio.direccion}</Text>
                <Text fontSize={2}>{lugarEntrega.domicilio.localidad}</Text>
                <Text fontSize={2}>{lugarEntrega.domicilio.provincia}</Text>
            </Flex>
            <Flex ml={"auto"}>
                <Button
                    onClick={() => {
                        onSeleccionar(lugarEntrega);
                    }}
                    component="button"
                    circled={true}
                    block={false}
                    bordered={true}
                    type="secondary"
                    size="normal"
                    iconLeft={<CheckIcon />}
                    title="Seleccionar"
                >
                    Seleccionar
                </Button>
            </Flex>
        </Flex>
    );
};
