import { ResultBase, makeHttpRequest, POST } from "./api";
import { ListRequestData, ListResponseOutputBase } from "../types";

export enum ErrorCodes {}

export interface IFacturasListFiltros {
    fechaDesde?: Date;
    fechaHasta?: Date;
    verSoloFacturasConSaldo?: boolean;
    fechaVencimientoDesde?: Date;
    fechaVencimientoHasta?: Date;
    idsFacturas?: number[];
}

export interface IFacturasListData {
    id: number;
    tipoDocumento: string;
    nroFactura: string;
    nroRemito: string | null;
    idRemito: number | null;
    fecha: string;
    fechaVencimiento: string;
    monedaFactura: string;
    totalFactura: number;
    monedaSaldo: string;
    saldo: number;
    saldoEnPesos: number;
}

export interface ITotales {
    vencidas: ITotal;
    proxVencer: ITotal;
    sinVencer: ITotal;
    totalSaldoCC: Array<IMonedaImporte>;
}

interface ITotal {
    importePesos: number;
    porMoneda: Array<IMonedaImporte>;
}

export interface IMonedaImporte {
    moneda: string;
    importe: number;
}

interface ResponseOutput extends ListResponseOutputBase<IFacturasListData, ITotales> {}

export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const obtenerFacturas = async (reqData: ListRequestData<IFacturasListFiltros>): Promise<Result> =>
    makeHttpRequest<ListRequestData<IFacturasListFiltros>, ResponseOutput, ErrorCodes>(POST, "facturas-list", reqData);
