import React, { FC, useMemo, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import { IUsuariosListData } from "../api/UsuariosListApi";
import Table, { TableBody, TableCell, TableHead, TableRow } from "@kiwicom/orbit-components/lib/Table";
import { Alert, Button, Portal, Separator } from "@kiwicom/orbit-components";
import Modal, { ModalHeader, ModalSection } from "@kiwicom/orbit-components/lib/Modal";
import CheckIcon from "@kiwicom/orbit-components/lib/icons/Check";
import CloseIcon from "@kiwicom/orbit-components/lib/icons/Close";
import AlertIcon from "@kiwicom/orbit-components/lib/icons/Alert";
import CheckCircleIcon from "@kiwicom/orbit-components/lib/icons/CheckCircle";
import CloseCircleIcon from "@kiwicom/orbit-components/lib/icons/CloseCircle";
import { inactivarUsuario } from "../api/UsuariosInactivarUsuarioApi";
import { activarUsuario } from "../api/UsuariosActivarUsuarioApi";
import { SesionContainer } from "../Sesion";

interface Props {
    usuarios: Array<IUsuariosListData>;
    onActionSuccess: () => void;
}
export const UsuariosListTable: FC<Props> = ({ usuarios, onActionSuccess }) => {
    const { sesion } = SesionContainer.useContainer();
    const [usuarioInactivar, setUsuarioParaInactivar] = useState<IUsuariosListData | null>(null);
    const [usuarioActivar, setUsuarioParaActivar] = useState<IUsuariosListData | null>(null);

    const UsuariosTableComponent = useMemo(
        () => (
            <Table compact={true}>
                <TableHead>
                    <TableRow>
                        <TableCell align={"left"}>E-Mail</TableCell>
                        <TableCell align={"left"}>Administrador</TableCell>
                        <TableCell align={"left"}>Activo</TableCell>
                        <TableCell align={"right"}>Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {usuarios.map(usuario => {
                        const { id, email, administrador, activo } = usuario;
                        const esEsteUsuario = sesion.usuario && sesion.usuario.id === id;

                        return (
                            <TableRow key={id}>
                                <TableCell align={"left"}>
                                    <Text fontWeight={esEsteUsuario ? 600 : 500}>{email}</Text>
                                </TableCell>
                                <TableCell align={"left"}>{administrador ? <CheckIcon /> : <CloseIcon />}</TableCell>
                                <TableCell align={"left"}>{activo ? <CheckIcon /> : <CloseIcon />}</TableCell>
                                <TableCell align={"right"}>
                                    <Flex>
                                        {!esEsteUsuario && activo && (
                                            <Box ml={"auto"}>
                                                <Button
                                                    bordered={true}
                                                    iconLeft={<AlertIcon />}
                                                    onClick={() => setUsuarioParaInactivar(usuario)}
                                                    type={"critical"}
                                                    size={"small"}
                                                >
                                                    Inactivar Usuario
                                                </Button>
                                            </Box>
                                        )}

                                        {!esEsteUsuario && !activo && (
                                            <Box ml={"auto"}>
                                                <Button
                                                    bordered={true}
                                                    iconLeft={<CheckCircleIcon />}
                                                    onClick={() => setUsuarioParaActivar(usuario)}
                                                    type={"success"}
                                                    size={"small"}
                                                >
                                                    Activar Usuario
                                                </Button>
                                            </Box>
                                        )}
                                    </Flex>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        ),
        [usuarios, sesion.usuario]
    );

    return (
        <>
            {usuarioInactivar && (
                <ConfirmarInactivarUsuarioModal
                    usuario={usuarioInactivar}
                    onConfirmarSuccess={() => {
                        setUsuarioParaInactivar(null);
                        onActionSuccess();
                    }}
                    onCancelar={() => setUsuarioParaInactivar(null)}
                />
            )}
            {usuarioActivar && (
                <ConfirmarActivarUsuarioModal
                    usuario={usuarioActivar}
                    onConfirmarSuccess={() => {
                        setUsuarioParaActivar(null);
                        onActionSuccess();
                    }}
                    onCancelar={() => setUsuarioParaActivar(null)}
                />
            )}
            {UsuariosTableComponent}
        </>
    );
};

const ConfirmarInactivarUsuarioModal: FC<{
    usuario: IUsuariosListData;
    onConfirmarSuccess: () => void;
    onCancelar: () => void;
}> = ({ usuario, onCancelar, onConfirmarSuccess }) => {
    const [inactivando, setInactivando] = useState(false);
    const [msgError, setMsgError] = useState<string>("");

    const inactivar = async () => {
        setInactivando(true);
        const resp = await inactivarUsuario({ idUsuario: usuario.id });
        setInactivando(false);
        if (!resp.ok) {
            /*if (resp.errorCode === ErrorCodes.CLIENTE_SIN_ADMINISTRADOR) {
                return setMsgError("No se puede inactivar porque es el único usuario administrador activo que tiene");
            }*/
            return setMsgError("Hubo un error al inactivar el usuario. Por favor, reintente más tarde.");
        }

        onConfirmarSuccess();
    };

    return (
        <Portal>
            <Modal fixedFooter={false} isMobileFullPage={true} onClose={onCancelar} size={"small"}>
                <ModalHeader title={"Confirmación"} suppressed={true} />
                <ModalSection suppressed={false}>
                    <Flex flexDirection={"column"}>
                        {msgError && (
                            <Box mb={3} mx={"auto"}>
                                <Alert type="critical" title={null} icon={true}>
                                    {msgError}
                                </Alert>
                            </Box>
                        )}

                        <Text color={"titulo"} fontSize={"1.5em"} fontWeight={600} textAlign={"center"}>
                            ¿Está seguro que desea inactivar el usuario {usuario.email}?
                        </Text>
                        <Text color={"datagridLightText"} fontSize={"0.9em"} my={3} textAlign={"center"}>
                            Si lo inactiva se van a cerrar las sesiones que el usuario tenga activas y no se podrá
                            loguear.
                        </Text>

                        <Box mb={2} mt={2}>
                            <Separator />
                        </Box>

                        <Flex>
                            <Button
                                disabled={inactivando}
                                iconLeft={<CheckCircleIcon />}
                                onClick={inactivar}
                                type={"critical"}
                            >
                                {inactivando ? "Inactivando..." : "Si, inactivar"}
                            </Button>
                            <Box ml={2}>
                                <Button
                                    iconLeft={<CloseCircleIcon />}
                                    disabled={inactivando}
                                    onClick={onCancelar}
                                    type={"secondary"}
                                >
                                    No, cancelar
                                </Button>
                            </Box>
                        </Flex>
                    </Flex>
                </ModalSection>
            </Modal>
        </Portal>
    );
};

const ConfirmarActivarUsuarioModal: FC<{
    usuario: IUsuariosListData;
    onConfirmarSuccess: () => void;
    onCancelar: () => void;
}> = ({ usuario, onCancelar, onConfirmarSuccess }) => {
    const [activando, setActivando] = useState(false);
    const [msgError, setMsgError] = useState<string>("");

    const inactivar = async () => {
        setActivando(true);
        const resp = await activarUsuario({ idUsuario: usuario.id });
        setActivando(false);
        if (!resp.ok) {
            return setMsgError("Hubo un error al activar el usuario. Por favor, reintente más tarde.");
        }

        onConfirmarSuccess();
    };

    return (
        <Portal>
            <Modal fixedFooter={false} isMobileFullPage={true} onClose={onCancelar} size={"small"}>
                <ModalHeader title={"Confirmación"} suppressed={true} />
                <ModalSection suppressed={false}>
                    <Flex flexDirection={"column"}>
                        {msgError && (
                            <Box mb={3} mx={"auto"}>
                                <Alert type="critical" title={null} icon={true}>
                                    {msgError}
                                </Alert>
                            </Box>
                        )}

                        <Text color={"titulo"} fontSize={"1.5em"} fontWeight={600} textAlign={"center"}>
                            ¿Está seguro que desea volver a activar el usuario {usuario.email}?
                        </Text>
                        <Text color={"datagridLightText"} fontSize={"0.9em"} my={3} textAlign={"center"}>
                            Se le va a enviar un email a {usuario.email} para que pueda ingresar la contraseña y activar
                            la cuenta.
                        </Text>

                        <Box mb={2} mt={2}>
                            <Separator />
                        </Box>

                        <Flex>
                            <Button
                                disabled={activando}
                                iconLeft={<CheckCircleIcon />}
                                onClick={inactivar}
                                type={"success"}
                            >
                                {activando ? "Activando..." : "Si, enviar email de activación"}
                            </Button>
                            <Box ml={2}>
                                <Button
                                    iconLeft={<CloseCircleIcon />}
                                    disabled={activando}
                                    onClick={onCancelar}
                                    type={"secondary"}
                                >
                                    No, cancelar
                                </Button>
                            </Box>
                        </Flex>
                    </Flex>
                </ModalSection>
            </Modal>
        </Portal>
    );
};
