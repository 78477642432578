import { ResultBase, makeHttpRequest, POST } from "./api";

export interface Request {
    codActivacion: string;
    /*codCliente: string;
    cuit: string;*/
    password: string;
}

export enum ErrorCodes {
    COD_ACTIVACION_INCORRECTO = "COD_ACTIVACION_INCORRECTO",
    COD_ACTIVACION_EXPIRADO = "COD_ACTIVACION_EXPIRADO",
    /*COD_CLIENTE_INCORRECTO = "COD_CLIENTE_INCORRECTO",
    CUIT_INCORRECTO = "CUIT_INCORRECTO",*/
    PASSWORD_DEBIL = "PASSWORD_DEBIL"
}

export interface ResponseData {
    linkIngreso: string;
}
export interface Response extends ResultBase<ResponseData, ErrorCodes> {}

export const activarUsuario = async (req: Request): Promise<Response> =>
    makeHttpRequest<Request, ResponseData, ErrorCodes>(POST, "activar-usuario", req);
