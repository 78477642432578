import React, { FC, useState } from "react";
import { Button, Checkbox, InputField } from "@kiwicom/orbit-components";
import Modal, { ModalHeader, ModalSection, ModalFooter } from "@kiwicom/orbit-components/lib/Modal";
import CalendarIcon from "@kiwicom/orbit-components/lib/icons/Calendar";
import CloseIcon from "@kiwicom/orbit-components/lib/icons/Close";
import { Box, Flex } from "rebass/styled-components";
import DatePicker from "react-datepicker";
import { Filter as FilterIcon } from "react-feather";
import { PedidosListFiltrosType } from "../api/PedidosListApi";

interface Props {
    filtros?: PedidosListFiltrosType;
    cerrarCallback: () => void;
    filtrarCallback: (filtros: PedidosListFiltrosType) => void;
}

export const PedidosListFiltros: FC<Props> = ({ cerrarCallback, filtros, filtrarCallback }) => {
    const [fechaDesde, setFechaDesde] = useState<Date | undefined>(filtros && filtros.fechaDesde);
    const [fechaHasta, setFechaHasta] = useState<Date | undefined>(filtros && filtros.fechaHasta);
    const [verSoloPedidosActivos, setVerSoloPedidosActivos] = useState(filtros ? filtros.verSoloPedidosActivos : false);

    const getFiltros = (): PedidosListFiltrosType => {
        return {
            fechaDesde,
            fechaHasta,
            verSoloPedidosActivos
        };
    };

    const mt = 3;
    const dateFormat = "dd/MM/yyyy";

    return (
        <Modal fixedFooter={false} isMobileFullPage={false} size={"small"}>
            <ModalHeader title={"Filtros de Pedidos"} suppressed={true} />
            <ModalSection suppressed={false}>
                <Flex flexDirection={"column"} mx={"auto"} width={1 / 2}>
                    <Box>
                        <DatePicker
                            customInput={
                                <InputField
                                    inlineLabel={false}
                                    label={"Fecha Desde:"}
                                    prefix={<CalendarIcon />}
                                    size={"normal"}
                                />
                            }
                            dateFormat={dateFormat}
                            endDate={fechaHasta}
                            onChange={fecha => setFechaDesde(fecha)}
                            selected={fechaDesde}
                            selectsStart={true}
                        />
                    </Box>

                    <Box mt={mt}>
                        <DatePicker
                            customInput={
                                <InputField
                                    inlineLabel={false}
                                    label={"Fecha Hasta:"}
                                    prefix={<CalendarIcon />}
                                    size={"normal"}
                                />
                            }
                            dateFormat={dateFormat}
                            startDate={fechaDesde}
                            onChange={fecha => setFechaHasta(fecha)}
                            selected={fechaHasta}
                            selectsEnd={true}
                        />
                    </Box>

                    <Box mt={mt}>
                        <Checkbox
                            label="Ver Solo Pedidos Activos"
                            checked={verSoloPedidosActivos}
                            onChange={() => {
                                setVerSoloPedidosActivos(!verSoloPedidosActivos);
                            }}
                        />
                    </Box>
                </Flex>
            </ModalSection>
            <ModalFooter fixed={true} flex={["0 0 auto", "1 1 100%"]} suppressed={true}>
                <Button iconLeft={<CloseIcon />} onClick={cerrarCallback} type="secondary">
                    Cerrar
                </Button>
                <Button block iconLeft={<FilterIcon />} onClick={() => filtrarCallback(getFiltros())} type={"primary"}>
                    Filtrar
                </Button>
            </ModalFooter>
        </Modal>
    );
};
