import React, { FC, useState } from "react";
import { PedidoData, PedidoFacturaData, PedidoRemitoData } from "../api/PedidosListApi";
import { Box, Flex, Text } from "rebass/styled-components";
import { ButtonGroup, ButtonLink } from "@kiwicom/orbit-components";
import { parse } from "date-fns";
import { formatFecha } from "../../lib/format";
import { FacturaDocModal } from "../facturas/FacturaDocModal";
import { RemitoDocModal } from "../remitos/RemitoDocModal";

export const Documentos: FC<{
    pedido: PedidoData;
}> = ({ pedido }) => {
    const [facturasConRemitos, facturasSinRemitos] = obtenerFacturasConRemitos(pedido);

    if (facturasConRemitos.length === 0 && facturasSinRemitos.length === 0) {
        return (
            <Box mt={2}>
                <Text style={{ fontStyle: "italic" }}>Todavía no hubo despachos ni facturas.</Text>
            </Box>
        );
    }
    return (
        <Flex flexDirection={"column"} mt={2} pl={3}>
            {facturasConRemitos.map(facturaConRemito => (
                <FacturaConRemito facturaConRemito={facturaConRemito} key={facturaConRemito.factura.idFactura} />
            ))}
        </Flex>
    );
};

const FacturaConRemito: FC<{ facturaConRemito: IFacturaConRemito }> = ({ facturaConRemito }) => {
    const [idFactura, setIdFactura] = useState<number | null>(null);
    const [idRemito, setIdRemito] = useState<number | null>(null);
    const { factura, remito } = facturaConRemito;
    const fecha = parse(factura.fechaFactura, "dd/MM/yyyy", new Date());
    const descFecha = `${formatFecha(fecha, "dd MMMM")} del ${formatFecha(fecha, "yyyy")}`;

    return (
        <Flex sx={{ bg: "pedidoDocumentoBg", borderRadius: "4px" }} flexDirection={"column"} my={1} p={2}>
            {idFactura && <FacturaDocModal idFactura={idFactura} onClose={() => setIdFactura(null)} />}
            {idRemito && <RemitoDocModal idRemito={idRemito} onClose={() => setIdRemito(null)} />}
            <Text alignSelf={"center"} fontSize={1} fontWeight={600}>
                {descFecha}
            </Text>
            <Flex flexDirection={"row"} mt={1}>
                <ButtonGroup connected={true}>
                    <ButtonLink onClick={() => setIdFactura(factura.idFactura)} type="primary">
                        <Flex flexDirection={"column"}>
                            <Text fontSize={1}>{factura.claseDocumento.descripcion.toUpperCase()}</Text>
                            <Text fontSize={1}>{factura.nroFactura}</Text>
                        </Flex>
                    </ButtonLink>
                    <ButtonLink onClick={() => setIdRemito(factura.idRemito)} type="primary">
                        <Flex flexDirection={"column"}>
                            <Text fontSize={1}>REMITO</Text>
                            <Text fontSize={1}>{remito.nroRemito}</Text>
                        </Flex>
                    </ButtonLink>
                </ButtonGroup>
            </Flex>
        </Flex>
    );
};

interface IFacturaConRemito {
    remito: PedidoRemitoData;
    factura: PedidoFacturaData;
}

const obtenerFacturasConRemitos = (pedido: PedidoData): [Array<IFacturaConRemito>, Array<PedidoFacturaData>] => {
    let facturasConRemitos: Array<IFacturaConRemito> = [];
    let facturasSinRemitos: Array<PedidoFacturaData> = [];
    let remitosPorId: Array<PedidoRemitoData> = [];
    pedido.remitos.forEach(remito => {
        remitosPorId[remito.idRemito] = remito;
    });

    pedido.facturas.forEach(factura => {
        if (!factura.idRemito) {
            facturasSinRemitos.push(factura);
        } else {
            const remito = remitosPorId[factura.idRemito];
            if (!remito) {
                facturasSinRemitos.push(factura);
            } else {
                facturasConRemitos.push({
                    remito,
                    factura
                });
            }
        }
    });

    return [facturasConRemitos, facturasSinRemitos];
};
