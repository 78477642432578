import { ResultBase, makeHttpRequest, POST } from "./api";
import { ListRequestData, ListResponseOutputBase } from "../types";

export enum ErrorCodes {}

export interface ICCFiltros {
    fechaDesde?: Date;
    fechaHasta?: Date;
    verSoloDocsConSaldo?: boolean;
}
//
// export interface ICCResponseOutput {
//     saldosDocs: Array<ICCSaldo>;
//     totales: Array<ICCTotales>;
// }

export interface ICCSaldo {
    id: number;
    doc: CCDoc;
    monedaDoc: string;
    importeDoc: number;
    monedaDeuda: string;
    saldoDeuda: number;
    movimientos: Array<ICCMovimiento>;
}

export type CCDoc =
    | { tipo: CCTiposDocs.FACTURA; factura: ICCFactura }
    | { tipo: CCTiposDocs.RECIBO; recibo: ICCRecibo }
    | { tipo: CCTiposDocs.AJUSTE; ajuste: ICCAjuste };

export interface ICCFactura {
    id: number;
    claseDocumento: string;
    nroFactura: string;
    fechaFactura: string;
    fechaVencimiento: string;
    pedido: ICCPedido;
    remito: ICCRemito | null;
}

export interface ICCPedido {
    id: number;
    nroPedido: number;
    nroPedidoAnticipo: number | null;
}

export interface ICCRemito {
    idRemito: number;
    nroRemito: string;
    fechaRecibo: string;
}

export interface ICCRecibo {
    id: number;
    nroRecibo: number;
    nroReciboProvisorio: number;
    fechaRecibo: string;
    fechaReciboProvisorio: string;
}

export interface ICCAjuste {
    id: number;
    fechaAjuste: string;
    motivoAjuste: string;
}

export enum CCTiposDocs {
    FACTURA = "factura",
    RECIBO = "recibo",
    AJUSTE = "ajuste"
}

export interface ICCMovimiento {
    id: number;
    fecha: string;
    tipoMovimiento: string;
    factura?: {
        id: number;
        nroFactura: string;
        tipoDocumento: string;
    };
    recibo?: {
        id: number;
        nroRecibo: number;
        nroReciboProvisorio: number | null;
    };
    aplicacion?: {
        id: number;
    };
    ajuste?: {
        id: number;
    };
    importe: number;
}

interface ResponseOutput extends ListResponseOutputBase<ICCSaldo, ICCTotales> {}

export type ICCTotales = Array<ICCTotal>;

export interface ICCTotal {
    moneda: string;
    importe: number;
}

export interface Result extends ResultBase<ResponseOutput, ErrorCodes> {}

export const obtenerCuentaCorriente = async (reqData: ListRequestData<ICCFiltros>): Promise<Result> =>
    makeHttpRequest<ListRequestData<ICCFiltros>, ResponseOutput, ErrorCodes>(POST, "cuenta-corriente", reqData);
